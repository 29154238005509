import { Select, MenuItem, TextField, Button } from '@mui/material';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import React, { useState } from 'react';
import { startOfDay, endOfDay, format} from 'date-fns';
import PropTypes from "prop-types";
import SoftBox from "../../components/SoftBox";
import SoftButton from "../../components/SoftButton";


const Filter = ({ type, filterData, label, value, onChange}) => {
    if (type === 'select') {
        return (

            <Select
                id={label}
                sx={{ width: "fit-content !important" }}
                value={value}
                onChange={onChange}
                name={label}
                displayEmpty
            >
                {filterData.map((item) => (
                    <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
                    ))}
            </Select>

        );
    }


    if (type === 'input') {
        return (
            <TextField
                variant="outlined"
                placeholder={label}
                value={value}
                onChange={onChange}
                style={{ margin: '0 10px'}}
            />
        );
    }


    if (type === 'date') {
        const [open, setOpen] = useState(false);
        const [dateRange, setDateRange] = useState([
            {
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection'
            }
        ]);

        const handleOpen = () => {
            setOpen(!open);
        };

        const [formattedRange, setFormattedRange] = useState({
            from: format(new Date(), 'yyyy-MM-dd'),
            to: format(new Date(), 'yyyy-MM-dd'),
        });

        const handleSelect = (ranges) => {
            const startDate = ranges.selection.startDate;
            const endDate = ranges.selection.endDate;

            const formattedStartDate = format(startOfDay(startDate), 'yyyy-MM-dd');
            const formattedEndDate = format(endOfDay(endDate), 'yyyy-MM-dd');


            onChange({ from: formattedStartDate, to: formattedEndDate });
            setFormattedRange({ from: formattedStartDate, to: formattedEndDate });

            setDateRange([ranges.selection]);
        };


        return (
            <div style={{ position: 'relative' }}>
                <Button
                    variant="outlined"
                    onClick={handleOpen}
                    sx={{
                        height: '40px',
                        background: '#FFFFFF',
                        border: '0.0625rem solid #d2d6da',
                        display: 'flex',
                        fontWeight: 400,
                        fontSize: '0.875rem',
                        color: '#495057',
                        padding: '0 16px',
                        textTransform: 'none',
                        minWidth: '200px',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderRadius: '0.5rem',

                        '&:hover': {
                            background: 'none',
                            borderColor: 'rgba(0, 0, 0, 0.23)',
                        },
                        '&:focus': {
                            outline: 'none',
                        },
                        '&.Mui-focusVisible': {
                            boxShadow: 'none',
                        },
                    }}
                >
                    {formattedRange.from} - {formattedRange.to}
                </Button>
                {open && (
                    <SoftBox
                        style={{
                            position: 'absolute',
                            top: '50px',
                            zIndex: 9999,
                            border: 'solid 1px #d1cece'
                        }}
                    >
                        <DateRangePicker
                            ranges={dateRange}
                            onChange={handleSelect}
                            className="custom-date-range-picker"
                            inputRanges={[]}
                            editableDateInputs={true}
                            rangeColors={['#cb0c9f']}
                        />
                        <SoftBox style={{display: 'flex', justifyContent: 'end', margin: '8px', backgroundColor: "#f8f9fa"}}>
                            <SoftButton
                                variant="gradient" color="light"
                                style={{position: 'relative'}}
                                onClick={() => setOpen(false)}
                            >
                                Apply
                            </SoftButton>
                        </SoftBox>
                    </SoftBox>
                )}
            </div>


        );
    }

    return null;
};

// Typechecking props for the Filter
Filter.propTypes = {
    type: PropTypes.string,
    filterData: PropTypes.object,
    label: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func
}
export default Filter;