import React, {useEffect, useState} from "react";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {Box, Chip} from "@mui/material";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SuiButton from "components/SoftButton";
import {MdRssFeed} from "react-icons/md";
import {IoIosAdd} from "react-icons/io";
import DashboardLayout from "reusableComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "reusableComponents/Navbars/DashboardNavbar";
import Footer from "reusableComponents/Footer";
import CardComponent from "reusableComponents/Tables/Card";
import {createArticle, getDigitizations, getArchivesById} from "axios-client";
import data from '../../constants/languages.json';

import {formatDate, formatDataToUrlQueryParams} from "utlis/generalUtils";
import DigitizeArticleDialog from "./DigitizeArticleDialog";
import {digitize, getSubjects} from "../../axios-client";

import SnackbarNotification from "../../reusableComponents/Snackbar/SnackbarNotification";
import {useSoftUIController} from "../../context";
import InputAdornment from "@mui/material/InputAdornment";
import {FREE_AGENT} from "../../utlis/constant";
import FilterList from "./FilterList";
import FilterListIcon from '@mui/icons-material/FilterList';
import Tooltip from "@mui/material/Tooltip";
import { useMediaQuery, Drawer, CircularProgress } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from '@mui/material/styles';


const publications = () => {
    // State declarationss
    const [controller] = useSoftUIController();
    const {role} = controller;
    const rolesAllowed = ['free-agent'];
    const [rows, setRows] = useState([]);
    const [articleCount, setArticleCount] = useState(0);
    const [displayWelcomeCard, setDisplayWelcomeCard] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [currentFilter, setCurrentFilter] = useState("All Fields");
    const [searchText, setSearchText] = useState("");
    const [isDigitizing, setIsDigitizing] = useState(false);
    const [openDigitizerDialog, setOpenDigitizerDialog] = useState(false);
    const [notification, setNotification] = useState({
        show: false,
        snackbarMessage: "",
        severity: "",

    });
    const [filterData, setFilterData] = useState({
        page: 1,
        size: 10,
        Title: "",
        Author: "",
        Date: "",
        Subjects: "",
        subjects: "",
        languages: "",
        all_fields: "",
    });

    const [languages, setLanguages] = useState([]);
    const [subjects, setSubjects] = useState([]);

    const [searchSubjectTerm, setSearchSubjectTerm] = useState("");
    const [selectedSubjects, setSelectedSubjects] = useState([]);

    const [searchLanguageTerm, setSearchLanguageTerm] = useState("");
    const [selectedLanguages, setSelectedLanguages] = useState([]);


    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);

    const [subjectCollapsed, setSubjectCollapsed] = useState(isMobile ? false : false);
    const [languageCollapsed, setLanguageCollapsed] = useState(isMobile ? true : false);
    const [filterCollapsed, setFilterCollapsed] = useState(true);

    const [userCredit, setUserCredit] = useState(0);

    useEffect(() => {
        setLanguages(data);
        getSubjects()
            .then(res => {
                setSubjects(res.data);
            })
            .catch(error => {
                console.error('Error fetching subjects:', error);
            });
    }, []);

    // Effect hook to fetch data when filterData changes
    useEffect(() => {
        refreshList()
    }, [filterData]);

    // Data fetching function
    const refreshList = () => {
        const queryParams = formatDataToUrlQueryParams(filterData);
        fetchData(`?${queryParams}`);
    }
    const fetchData = (params) => {
        setLoading(true);
        setError(null);

        getDigitizations(params)
            .then((res) => {
                const formattedRows = res.data.data
                    ? res.data.data.map((archive) => ({
                        id: archive.id,
                        feedback_rating: archive.feedback_rating? archive.feedback_rating : null,
                        picture_path: archive.article.picture_path,
                        title: archive.article.title,
                        subjects: archive.article.subjects,
                        author: archive.article.author,
                        summary: archive.summary,
                        publishedAt: archive.article.publication_date,
                        status: archive.status,
                        services_data: archive.services_data,
                        output_formats: archive.output_formats,
                        all_services_selected: archive.all_services_selected,
                        createdAt: archive.article.created_at,
                        origin: archive.article.origin,
                        bookmarking_path: archive.article.bookmarking_path,
                        articleId: archive.article.id,
                        cost: archive.article.cost,
                        source_language: archive.article.source_language,
                        target_language: archive.article.target_language,
                        digitizerId:archive.article.user_id,
                        has_audio_option:archive.article.has_audio_option,
                    }))
                    : [];
                setArticleCount(res.data.count);
                setDisplayWelcomeCard(res.data.count == 0)
                setRows(formattedRows);
                setUserCredit(res.data.user_credit_balance);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching archives:", error);
                setError("Failed to load data. Please try again.");
                setLoading(false);
            });
    };

    const getArchivesBySelectedDocument = async (id) => {
        try {
            const res = await getArchivesById(id);
            let data = res.data.data;
            return {
                details: {
                    Digitization_date: data.article.created_at,
                    Number_of_pages: data.article.page_count,
                    Document_ID: data.id,
                },
                title: data.article.title,
                author: data.article.author,
                filename: data.article.filename,
                extension: data.article.extension,
                publishedAt: data.article.publication_date,
                picture_path: data.article.picture_path,
                ocr_text: data.ocr_text,
                translated_text: data.translated_text,
                summary: data.summary,
                bullet_points_summary: data.bullet_points_summary,
                description: data.description,
                bookmarking_path: data.bookmarking_path,
                searchable_pdf_path: data.searchable_pdf_path,
                cost: data.article.cost,
            };
        } catch (error) {
            console.error("Error fetching archives by id:", error);
        }
    };

    // Handle search input change
    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
    };

    // Handle Enter key press in search input
    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            handleSearchSubmit();
        }
    };

    // Handle search submission
    const handleSearchSubmit = () => {
        // Reset all filters except the current one
        const updatedFilterData = {...filterData};

        // Map the current filter to the corresponding key in filterData
        const filterKeyMap = {
            "All Fields": "all_fields",
            Title: "title",
            Author: "author",
            Date: "date",
            Subjects: "subjects",
        };

        const filterKey = filterKeyMap[currentFilter] || "all_fields";

        // Set the current filter value
        updatedFilterData[filterKey] = searchText.trim();

        setFilterData(updatedFilterData);
    };

    // Handle filter selection change
    const handleFilterChange = (event) => {
        setCurrentFilter(event.target.value);
    };

    // Pagination handlers
    const handlePageChange = (newPage) => {
        setFilterData((prev) => ({...prev, page: newPage}));
    };

    const handleRowsPerPageChange = (newSize) => {
        setFilterData((prev) => ({...prev, size: newSize, page: 1}));
    };

    // Functions for digitizing articles
    const handleOpenDialog = () => {
        setOpenDigitizerDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDigitizerDialog(false);
    };

    const handleDigitizeArticle = (data) => {
        setIsDigitizing(true);
        digitize(data)
            .then((response) => {
                setNotification({
                    show: true,
                    snackbarMessage: "Article created successfully!",
                    severity: "success",
                });
                setOpenDigitizerDialog(false);
                setIsDigitizing(false);
                setFilterData({...filterData, page: 1});
            })
            .catch((error) => {
                setIsDigitizing(false);
                setNotification({
                    show: true,
                    snackbarMessage: "Failed to digitize the document. Please try again!",
                    severity: "error",
                });
            });
    };

    const handleCreateArticle = (data) => {
        setIsDigitizing(true);
        createArticle(data)
            .then((response) => {
                setNotification({
                    show: true,
                    snackbarMessage: "Article created successfully!",
                    severity: "success",
                });
                setOpenDigitizerDialog(false);
                setIsDigitizing(false);
                setFilterData({...filterData, page: 1});
            })
            .catch((error) => {
                setIsDigitizing(false);
                setNotification({
                    show: true,
                    snackbarMessage: "Failed to digitize the document. Please try again!",
                    severity: "error",
                });
            });
    };

    const handleCheckboxSubjectChange = (label) => {
        let updatedItems
        if (selectedSubjects.includes(label)) {
            setSelectedSubjects(selectedSubjects.filter((item) => item !== label));
            updatedItems = selectedSubjects.filter((item) => item !== label)
        } else {
            setSelectedSubjects([...selectedSubjects, label]);
            updatedItems = [...selectedSubjects, label]

        }

        setFilterData({...filterData, subjects: updatedItems.join(', ')});

    };

    const handleCheckboxLanguageChange = (label) => {
        let updatedItems
        if (selectedLanguages.includes(label)) {
            updatedItems = selectedLanguages.filter((item) => item !== label)
            setSelectedLanguages(selectedLanguages.filter((item) => item !== label));
        } else {
            setSelectedLanguages([...selectedLanguages, label]);
            updatedItems = [...selectedLanguages, label]
        }

        setFilterData({...filterData, languages: updatedItems.join(', ')});

    };

    const handleSubjectCollapseToggle = () => {
        if (subjectCollapsed) {
            // If subject filter is collapsed, expand it and collapse languages.
            setSubjectCollapsed(false);
            setLanguageCollapsed(true);
        } else {
            // If it's already open, collapse it (optional behavior).
            setSubjectCollapsed(true);
        }
    };

    const handleLanguageCollapseToggle = () => {
        if (languageCollapsed) {
            // If language filter is collapsed, expand it and collapse subjects.
            setLanguageCollapsed(false);
            setSubjectCollapsed(true);
        } else {
            setLanguageCollapsed(true);
        }
    };

    const handleFilterCollapseToggle = () => {
        setFilterCollapsed(!filterCollapsed);
    };

    const handleClearAll = () => {
        setSelectedSubjects([]);
        setSelectedLanguages([]);
        setFilterData({...filterData, subjects: "", languages: ""})
    };

    const handleCloseDrawer = () => {
        setFilterDrawerOpen(false);
    };

    const handleFilterToggle = () => {
        if (isMobile) {
            setFilterDrawerOpen(!filterDrawerOpen);
        } else {
            handleFilterCollapseToggle();
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <SoftBox py={2} style={{minHeight: "630px"}}>
                <SoftBox mb={2}>
                    {/* Search and Filter Section */}
                    {rows.length > 0 &&
                    <SoftBox mb={0.5} sx={{marginRight: '8px', marginLeft: '8px', paddingTop: "30px"}}>
                        <Grid container justifyContent="center">
                            <Grid item xs={12}>
                                <Box display="flex" flexDirection={{xs: 'column', sm: 'row'}}
                                     gap={1}
                                     alignItems={{xs: 'stretch', sm: 'center'}}>
                                    {/* Search Input */}
                                    <Box sx={{flexGrow: 1, width: '100%'}}>
                                        <TextField
                                            variant="outlined"
                                            placeholder="Search..."
                                            value={searchText}
                                            onChange={handleSearchChange}
                                            onKeyDown={handleKeyDown}
                                            fullWidth
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            width: {xs: '100%', sm: 'auto'},
                                            mt: {xs: 1, sm: 0}
                                        }}
                                    >

                                    {/* 'Filter By' Dropdown */}
                                    <Box sx={{flexGrow: {xs: 1, sm: 0}}}>
                                        <Select
                                            value={currentFilter}
                                            onChange={handleFilterChange}
                                            displayEmpty
                                            variant="outlined"
                                            inputProps={{"aria-label": "Filter By"}}
                                            fullWidth={isMobile}
                                            sx={{
                                                minWidth: {sm: 150}
                                            }}
                                        >
                                            <MenuItem value="All Fields">All Fields</MenuItem>
                                            <MenuItem value="Title">Title</MenuItem>
                                            <MenuItem value="Author">Author</MenuItem>
                                            <MenuItem value="Date">Date</MenuItem>
                                            <MenuItem value="Subjects">Subjects</MenuItem>
                                        </Select>
                                    </Box>
                                    {/* Search Button */}
                                    <Box>
                                        <IconButton onClick={handleSearchSubmit} color="primary">
                                            <SearchIcon/>
                                        </IconButton>
                                    </Box>
                                </Box>
                            </Box>
                            </Grid>
                        </Grid>
                    </SoftBox>
                    }

                    {/* Results Header and Action Button */}
                    {rows.length > 0 &&
                    <SoftBox
                        display="flex"
                        flexDirection={{xs: 'column', sm: 'row'}}
                        justifyContent="space-between"
                        alignItems={{xs: 'flex-start', sm: 'center'}}
                        gap={1}
                        p={1}
                        style={{paddingTop: "16px", paddingBottom: "16px"}}
                    >
                        <SoftTypography variant="h6"  sx={{ display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                                fontSize: {xs: '1.1rem', sm: '1.25rem'}
                        }}
                            >
                            <span>{articleCount} results</span>
                            <MdRssFeed style={{marginLeft: '4px'}}/>


                            <Tooltip title="Filter">
                                <FilterListIcon
                                    sx={{
                                        fontSize: {xs: '1.8rem', sm: '2.5rem'},
                                        cursor: 'pointer',
                                        marginLeft: '10px',
                                        display: 'inline-flex'
                                    }}
                                    onClick={handleFilterToggle}
                                />
                            </Tooltip>

                        </SoftTypography>

                        {rolesAllowed.includes(role) && <SuiButton
                            variant="gradient"
                            color="primary"
                            size="small"
                            sx={{
                                width: {xs: '100%', sm: 'auto'},
                                marginTop: {xs: 1, sm: 0}
                            }}
                            onClick={handleOpenDialog}
                        >
                            <IoIosAdd/> Digitize new document
                        </SuiButton>}
                    </SoftBox>
                    }

                    {/* Error Message */}
                    {error && (
                        <SoftBox mb={2}>
                            <SoftTypography color="error">{error}</SoftTypography>
                        </SoftBox>
                    )}

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: {xs: 'column', md: 'row'},
                            gap: 2
                        }}
                    >
                        {/* Desktop Filter Panel - only show on non-mobile when not collapsed */}
                        {!isMobile && (
                            <FilterList
                                filterCollapsed={filterCollapsed}
                                handleFilterCollapseToggle={handleFilterCollapseToggle}
                                selectedSubjects={selectedSubjects}
                                selectedLanguages={selectedLanguages}
                                handleCheckboxSubjectChange={handleCheckboxSubjectChange}
                                handleCheckboxLanguageChange={handleCheckboxLanguageChange}
                                handleClearAll={handleClearAll}
                                searchSubjectTerm={searchSubjectTerm}
                                setSearchSubjectTerm={setSearchSubjectTerm}
                                searchLanguageTerm={searchLanguageTerm}
                                setSearchLanguageTerm={setSearchLanguageTerm}
                                subjects={subjects}
                                languages={languages}
                                subjectCollapsed={subjectCollapsed}
                                handleSubjectCollapseToggle={handleSubjectCollapseToggle}
                                languageCollapsed={languageCollapsed}
                                handleLanguageCollapseToggle={handleLanguageCollapseToggle}
                                isDrawerMode={false}
                            />
                        )}

                        {loading ? (
                            <SoftBox
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                minHeight="200px"
                                width="100%"
                                flexGrow={1}
                            >
                                <SoftTypography>Loading...</SoftTypography>
                            </SoftBox>
                        ) : (
                            <SoftBox
                                sx={{
                                    width:'100%',
                                    flexGrow: 1
                                }}
                            >
                                <Grid container>
                                    <Grid item xs={12}>
                                        <CardComponent
                                            data={rows}
                                            count={articleCount}
                                            displayWelcomeCard={displayWelcomeCard}
                                            size={filterData.size}
                                            page={filterData.page}
                                            handlePageChange={handlePageChange}
                                            handleRowsPerPageChange={handleRowsPerPageChange}
                                            loadingData={loading}
                                            getArchivesBySelectedDocument={getArchivesBySelectedDocument}
                                            setNotification={setNotification}
                                            searchText={searchText}
                                            refreshList={refreshList}
                                            handleOpenDialog={handleOpenDialog}
                                            userCreditBalance={userCredit}
                                        />
                                    </Grid>
                                </Grid>
                            </SoftBox>
                        )}
                    </Box>

                </SoftBox>
            </SoftBox>

            <Drawer
                anchor="right"
                open={isMobile && filterDrawerOpen}
                onClose={handleCloseDrawer}
                PaperProps={{
                    sx: {
                        width: '80%',
                        maxWidth: '350px',
                        borderTopLeftRadius: isMobile ? '16px' : 0,
                        borderBottomLeftRadius: isMobile ? '16px' : 0
                    }
                }}
            >
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    p: 2,
                    borderBottom: '1px solid rgba(0,0,0,0.12)'
                }}>
                    <SoftTypography variant="h6">Filters</SoftTypography>
                    <IconButton onClick={handleCloseDrawer}>
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Box sx={{ overflow: 'auto', height: 'calc(100% - 56px)' , padding:'10px'}}>
                    <FilterList
                        filterCollapsed={false}
                        handleFilterCollapseToggle={handleCloseDrawer}
                        selectedSubjects={selectedSubjects}
                        selectedLanguages={selectedLanguages}
                        handleCheckboxSubjectChange={handleCheckboxSubjectChange}
                        handleCheckboxLanguageChange={handleCheckboxLanguageChange}
                        handleClearAll={handleClearAll}
                        searchSubjectTerm={searchSubjectTerm}
                        setSearchSubjectTerm={setSearchSubjectTerm}
                        searchLanguageTerm={searchLanguageTerm}
                        setSearchLanguageTerm={setSearchLanguageTerm}
                        subjects={subjects}
                        languages={languages}
                        subjectCollapsed={subjectCollapsed}
                        handleSubjectCollapseToggle={handleSubjectCollapseToggle}
                        languageCollapsed={languageCollapsed}
                        handleLanguageCollapseToggle={handleLanguageCollapseToggle}
                        isDrawerMode={true}
                    />
                </Box>
            </Drawer>

            <Footer/>

            {/* Digitize Article Dialog */}
            {openDigitizerDialog && (
                <DigitizeArticleDialog
                    open={openDigitizerDialog}
                    isDigitizing={isDigitizing}
                    onClose={handleCloseDialog}
                    userCreditBalance={userCredit}
                    handleDigitizeArticle={handleCreateArticle}
                />

            )}

            {/* Snackbar Notification */}
            <SnackbarNotification
                notification={notification}
                setNotification={setNotification}
            />
        </DashboardLayout>
    );
};

export default publications;
