import { Link } from "react-router-dom";
import SoftTypography from "components/SoftTypography";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import digitization from "assets/images/curved-images/registration.png";
import { useLocation } from "react-router-dom";

function Confirmation() {
    const location = useLocation();
    const role = location.state?.role; 


    return (
        <BasicLayout
            title="Registration complete!"
            description={
                "Your account has been created. Please check your mailbox to activate your account."
            }
            image={digitization}
        >
            <div style={{ textAlign: 'center', paddingBottom: '1.2rem' }}>
                <SoftTypography variant="body2" color="white" fontWeight="regular">
                    <p><strong>Important: </strong>Please note that the activation link sent to your email will expire in <strong>4 hours</strong>. If the link expires, you will need to register again.</p>
                </SoftTypography>
            </div>


            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <SoftTypography
                    component={Link}
                    to="/authentication/sign-in"
                    variant="button"
                    color="primary"
                    fontWeight="bold"
                    textGradient
                >
                    Back To Login Page
                </SoftTypography>
            </div>
        </BasicLayout>
    );
}

export default Confirmation;





