import DashboardLayout from "reusableComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "reusableComponents/Navbars/DashboardNavbar";
import CommentsSection from "./CommentsSection";
import {useEffect, useState} from "react";
import {formatDataToUrlQueryParams} from "../../utlis/generalUtils";
import {getDigitizationsRatings, getUsersFeedbacks} from "../../axios-client";
import {useSoftUIController} from "../../context";
import RatingStatisticsCard from "./RatingStatisticsCard";

const FeedbackList = () => {

    const [loading, setLoading] = useState(true);
    const [commentsCount, setCommentsCount] = useState(0);
    const [rows, setRows] = useState([]);
    const [controller, dispatch] = useSoftUIController();
    const {role} = controller;
    const [filterData, setFilterData] = useState({
        page: 1,
        size: 6,
    });

    const [ratingData, setRatingData] = useState({
        overall_rating: 0,
        total_ratings: 0,
        ratings: []
    });

    useEffect(() => {
        getDigitizationsRatings()
            .then((response) => {
                setRatingData(response.data);
            })
            .catch((error) => {
                console.error("Error fetching ratings", error);
            });
    }, []);


    useEffect(() => {
        fetchCommentsList();
    }, [filterData]);



    const handlePageChange = (newPage) => {
        setFilterData((prev) => ({ ...prev, page: newPage }));
    };

    const handleRowsPerPageChange = (newSize) => {
        setFilterData((prev) => ({ ...prev, size: newSize, page: 1 }));
    };

    const fetchCommentsList = () => {
        setLoading(true);
        const queryParams = formatDataToUrlQueryParams(filterData);
            getUsersFeedbacks(`?${queryParams}`)
                .then(res => {
                    const formattedRows = res.data.data;
                    setCommentsCount(res.data.count);
                    setRows(formattedRows);
                    setLoading(false);
                })
                .catch(error => {
                });

    };


    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <RatingStatisticsCard
                ratingData={ratingData}
            />
            <CommentsSection
                rows={rows}
                commentsCount={commentsCount}
                loading={loading}
                handlePageChange={handlePageChange}
                handleRowsPerPageChange={handleRowsPerPageChange}
                currentPage={filterData.page}
                pageSize={filterData.size}
            />
        </DashboardLayout>
    );


}
export default  FeedbackList;