import {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import signUpBgImage from "assets/images/curved-images/login.png";
import logo from "assets/images/logo.png";
import {login} from "../../../../axios-client";
import {useSoftUIController} from "../../../../context";
import {setToken, setUserInfo} from "../../../../store/actions/app-actions";
import Alert from "@mui/material/Alert";

function AdminSignIn() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);
    const [controller, dispatch] = useSoftUIController();
    const navigate = useNavigate();

    useEffect(() => {
        setErrorMessage(null);
    }, [email, password]);

    const handleLogin = (event) => {
        event.preventDefault();
        login(email, password, 'admin')
            .then((response) => {
                setUserInfo(dispatch, response?.data);
                setToken(dispatch, response.data?.access_token);
                localStorage.setItem("token", response.data?.access_token);
                navigate('/dashboard');
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.message) {
                    setErrorMessage(error.response.data.message);
                } else {
                    setErrorMessage('An error occurred. Please try again later.');
                }
            });
    };

    return (
        <CoverLayout
            title="Welcome Admin"
            description=""
            image={signUpBgImage}
            logo={logo}
        >
            {errorMessage && <Alert severity="error" onClose={() => setErrorMessage(null)}>{errorMessage}</Alert>}

            <SoftBox component="form" role="form" onSubmit={handleLogin}>
                <SoftBox mb={2}>
                    <SoftBox mb={1} ml={0.5}>
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Email
                        </SoftTypography>
                    </SoftBox>
                    <SoftInput
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </SoftBox>

                <SoftBox mb={2}>
                    <SoftBox mb={1} ml={0.5}>
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Password
                        </SoftTypography>
                    </SoftBox>
                    <SoftInput
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </SoftBox>

                <SoftBox mt={4} mb={1}>
                    <SoftButton variant="gradient" color="primary" fullWidth type="submit">
                        Sign in
                    </SoftButton>
                </SoftBox>
            </SoftBox>
        </CoverLayout>
    );
}

export default AdminSignIn;
