import {Box, Container, LinearProgress, Paper, Typography} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import {styled} from "@mui/material/styles";
import colors from "../../assets/theme/base/colors";
import PropTypes from "prop-types";

const RatingBox = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
}))

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 8,
    borderRadius: 4,
    backgroundColor: "#EEF2FF",
    "& .MuiLinearProgress-bar": {
        borderRadius: 4,
        background: `linear-gradient(45deg, ${colors.gradients.primary.main}, ${colors.gradients.primary.state})`,
        transform: 'none !important',
    },
}));

const RatingStatisticsCard = ({ratingData}) => {
    return (

        <Box sx={{ flexGrow: 1, p: 1 }}>
            <Container maxWidth="lg">
                <Typography variant="h4" sx={{ mb: 4, fontWeight: "bold", textAlign:"center" }}>Our Customer Reviews</Typography>
                <Paper
                    elevation={0}
                    sx={{p: 4, borderRadius: 3, border: 1, borderColor: "divider", maxWidth: "90%", mx: "auto"}}
                >
                    <Box sx={{display: "grid", gap: 4, gridTemplateColumns: { md: "350px 1fr" }}}>
                        {/* Rating Summary */}
                        <Paper
                            elevation={0}
                            sx={{pt: 6, borderRadius: 3, bgcolor: "#F8F9FF", textAlign: "center"}}
                        >
                            <Typography variant="h3" sx={{ fontWeight: "bold", mb: 1 }}>{ratingData.overall_rating}</Typography>
                            <Box sx={{ display: "flex", justifyContent: "center", mb: 1 }}>
                                {[1, 2, 3, 4, 5].map((star) => (
                                    <StarIcon key={star} sx={{ color: "#FFB800" }} />
                                ))}
                            </Box>
                            <Typography variant="body2" color="text.secondary">{ratingData.total_ratings} Ratings</Typography>
                        </Paper>

                        {/* Rating Bars */}
                        <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
                            {ratingData.ratings.map((item) => (
                                <RatingBox key={item.rating}>
                                    <Box sx={{ display: "flex", alignItems: "center", gap: 0.5, width: 32 }}>
                                        <Typography variant="body2" sx={{ fontWeight: 500 }}>{item.rating}</Typography>
                                        <StarIcon sx={{ fontSize: 16, color: "#FFB800" }} />
                                    </Box>
                                    <Box sx={{ flexGrow: 1 }}>
                                        <StyledLinearProgress
                                            variant="determinate"
                                            value={item.percentage}
                                            sx={{"& .MuiLinearProgress-bar": {width: `${item.percentage}% !important`}}}
                                        />
                                    </Box>
                                    <Typography variant="body2" color="text.secondary" sx={{ width: 64 }}>
                                        {item.reviews >= 1000 ? `${(item.reviews / 1000).toFixed(1)}K` : item.reviews}
                                    </Typography>
                                </RatingBox>
                            ))}
                        </Box>
                    </Box>
                </Paper>
            </Container>
        </Box>

    );
}
RatingStatisticsCard.propTypes = {
    ratingData: PropTypes.arrayOf(PropTypes.object),
};


export default  RatingStatisticsCard;