import "../../editorStyleSheet.css"
import PropTypes from "prop-types";
import React, {useCallback, useEffect, useState} from 'react';
import {getScriptDirection} from "../../../../utlis/generalUtils";
import {useSoftUIController} from "../../../../context";
import SaveButton from "../editorToolbarComponents/SaveButton";
import CircularProgress from '@mui/material/CircularProgress';
import AudioPlayer from "../wavesurfer/AudioPlayer";
import ReactMarkdown from 'react-markdown';
import 'react-edit-text/dist/index.css';
import SoftBox from "../../../../components/SoftBox";
import SoftTypography from "../../../../components/SoftTypography";
import Divider from "@mui/material/Divider";


const DigitizationMarkdownComponent = ({
                                           pageNumber, updateConfigWidth,
                                           page, sourceLanguage, targetLanguage, addOrUpdatePages, updatedPages, handleSave, regenerateBookmarking, bookmarkingSelected
                                       }) => {
    const [controller] = useSoftUIController();
    const {role} = controller;
    const [displayExtractedText, setDisplayExtractedText] = useState(true);
    const [markdownContent, setMarkdownContent] = useState('');
    const [translatedContent, setTranslatedContent] = useState('');
    const [loading, setLoading] = useState(false);
    const [audioUrl, setAudioUrl] = useState('');
    const noContentTags = ["No OCR is available.", "No Translation is available.", "NO-TEXT due to harmful content."];

    const checkPageType = (currentPage) => {
        switch (currentPage.type) {
            case "NO_TEXT":
                setMarkdownContent("No TEXT.");
                setTranslatedContent("No TEXT.");
                break;
            case "TEXT":
                setMarkdownContent(currentPage.markdown ?? "No OCR is available.")
                setTranslatedContent(currentPage.translated_text ?? "No Translation is available.");
                break;
            default:
                setMarkdownContent(currentPage.markdown ?? "No OCR is available.")
                setTranslatedContent(currentPage.translated_text ?? "No Translation is available.");
        }
    }

    useEffect(() => {

        if (page) {
            checkPageType(page);
            setAudioUrl(page.audio_path);
        }
    }, [pageNumber, page]);

    const handleSwitchChange = () => {
        setDisplayExtractedText(!displayExtractedText);
    };

    const updateMarkdown = (e) => {
        if (page.type === 'TEXT' || page.type === null) {
            setMarkdownContent(e.target.value);
            addOrUpdatePages(pageNumber, e.target.value, 'markdown');
        }
    }

    const updateTranslatedMarkdown = (e) => {
        if (page.type === 'TEXT' || page.type === null) {
            setMarkdownContent(e.target.value);
            addOrUpdatePages(pageNumber, e.target.value, 'translated_text');
        }
    }

    const handleContentSectionMouseDown = useCallback((e) => {
        e.preventDefault();
        const initialWidth = e.target.parentElement.offsetWidth;
        const initialDivWidth = e.target.offsetParent.offsetWidth;
        const initialX = e.clientX;

        const handleMouseContentSectionMove = (e) => {
            let newWidth = initialWidth - (e.clientX - initialX);

            if (newWidth < initialDivWidth * 0.2) {
                newWidth = (initialDivWidth * 0.2);
            } else if (newWidth > initialDivWidth * 0.75) {
                newWidth = (initialDivWidth * 0.75);
            }

            const contentSection = document.getElementById("digitizationEditorMarkdownSection");
            if (contentSection && contentSection.offsetWidth !== newWidth) {
                contentSection.style.width = newWidth + 'px';
                updateConfigWidth(newWidth);
            }
            const event = new CustomEvent('configResized');
            window.dispatchEvent(event);
        };

        const handleMouseContentSectionUp = () => {
            document.removeEventListener('mousemove', handleMouseContentSectionMove);
            document.removeEventListener('mouseup', handleMouseContentSectionUp);
        };

        document.addEventListener('mousemove', handleMouseContentSectionMove);
        document.addEventListener('mouseup', handleMouseContentSectionUp);
    }, [updateConfigWidth]);


    const diacritizeText = () => {
        setLoading(true);
        diacritizeDraft(markdownContent)
            .then((response) => {
                let newMarkdown = response.data.markdown;
                if (newMarkdown) {
                    setMarkdownContent(newMarkdown)
                    addOrUpdatePages(pageNumber, newMarkdown, 'markdown');
                }
            })
            .catch(e => {
                console.log('error', e)
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleSaveWithLoading = () => {
        setLoading(true);
        handleSave()
            .then(() => {
                // success handled in handleSave
            })
            .catch((err) => {
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div className="digitizationEditorMarkdownSection" id="digitizationEditorMarkdownSection"
             style={{display: 'flex', flexDirection: 'column', height: '100%', maxHeight: '100vh'}}
        >
            <div className="resize-handle" onMouseDown={handleContentSectionMouseDown}/>
            <div style={{
                borderBottom: '1px solid #e0d8d8b0', padding: "5px", height: "7%", display: "flex",
                flexDirection: "row", justifyContent: "space-between", alignItems: "center", minWidth: "max-content"
            }}>

                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "1rem",
                        margin: "0 auto",
                    }}
                >
                    <div style={{fontSize: "smaller"}}>Extracted Text</div>
                    <div>
                        <input
                            type="radio"
                            id="extractedText"
                            name="textToggle"
                            value="extracted"
                            checked={displayExtractedText === true}
                            onChange={() => handleSwitchChange(true)}
                            style={{
                                width: "20px",
                                height: "20px",
                                accentColor: "rgb(32 143 213)",
                            }}
                        />
                    </div>
                    <div style={{fontSize: "smaller", paddingLeft: "20px"}}>Translated Text</div>
                    <div>
                        <input
                            type="radio"
                            id="translatedText"
                            name="textToggle"
                            value="translated"
                            checked={displayExtractedText === false}
                            onChange={() => handleSwitchChange(false)}
                            style={{
                                width: "20px",
                                height: "20px",
                                accentColor: "rgb(32 143 213)",
                            }}
                        />
                    </div>
                </div>

                <React.Fragment>
                    <SaveButton updatedPages={updatedPages} handleSave={handleSaveWithLoading} disabled={loading}/>
                    {/*TODO will be reactivated when cover the payment for them*/}
                    {/*<Tooltip title="Re-generate bookmarking">*/}
                    {/*    <span>*/}
                    {/*        <IconButton*/}
                    {/*            onClick={regenerateBookmarking}*/}
                    {/*            disabled={!bookmarkingSelected}*/}
                    {/*        >*/}
                    {/*            <BsBookmarkCheckFill/>*/}
                    {/*        </IconButton>*/}
                    {/*    </span>*/}
                    {/*</Tooltip>*/}
                    {/*{*/}
                    {/*    sourceLanguage == 'Arabic' && <Tooltip title="Add Diacritics">*/}
                    {/*    <span>*/}
                    {/*        <IconButton*/}
                    {/*            onClick={diacritizeWords}*/}
                    {/*        >*/}
                    {/*            <CgDesignmodo />*/}
                    {/*        </IconButton>*/}
                    {/*    </span>*/}
                    {/*</Tooltip>*/}
                    {/*}*/}

                </React.Fragment>
            </div>

            <div style={{padding: "0px 15px 0px 15px", flexGrow: 1, overflow:  displayExtractedText ? 'auto' : 'hidden'}}>
                {loading && (
                    <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(255,255,255,0.7)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 10
                    }}>
                        <CircularProgress/>
                    </div>
                )}


                <div>
                    <SoftBox>
                        <SoftTypography variant="h6" style={{display: "flex", justifyContent: 'center'}}>
                            Editor
                        </SoftTypography>
                        <Divider style={{margin: "0.5rem 0"}}/>
                        <textarea
                            disabled={loading}
                            onChange={displayExtractedText ? updateMarkdown : updateTranslatedMarkdown}
                            value={displayExtractedText ? markdownContent : translatedContent}
                            className="digitization-note"
                            style={{
                                borderColor: '#e6e6e7',
                                borderRadius: '4px',
                                width: '100%',
                                minHeight: '300px',
                                padding: "15px",
                                direction: displayExtractedText ? getScriptDirection(sourceLanguage) : getScriptDirection(targetLanguage),
                                height: '100%',
                                boxSizing: 'border-box',
                                overflowX: 'auto',
                                whiteSpace: 'pre-wrap',
                                fontWeight: "500",
                                fontFamily: "system-ui",
                                fontSize: "16px"
                            }}
                            placeholder="Text content will appear here."
                        />
                    </SoftBox>
                    <SoftBox>
                        <SoftTypography variant="h6" style={{display: "flex", justifyContent: 'center'}}>
                            Preview
                        </SoftTypography>
                        <Divider style={{margin: "0.5rem 0"}}/>
                        <SoftBox style={{
                            direction: displayExtractedText ? getScriptDirection(sourceLanguage) : getScriptDirection(targetLanguage),
                            backgroundColor: "white",
                            border: 'solid 0.1px lightgray',
                            borderRadius: '5px',
                            padding: "5px"
                        }}>

                            {displayExtractedText ?
                                <ReactMarkdown>
                                    {markdownContent}
                                </ReactMarkdown> : <ReactMarkdown>
                                {translatedContent}
                            </ReactMarkdown>
                            }
                        </SoftBox>
                    </SoftBox>
                </div>


            </div>
            {((sourceLanguage === "Ottoman" && !displayExtractedText && audioUrl) || (sourceLanguage !== "Ottoman" && displayExtractedText && audioUrl)) && !loading && (
                <AudioPlayer audioUrl={audioUrl}/>
            )}
        </div>
    )
}

DigitizationMarkdownComponent.propTypes = {
    containerWidth: PropTypes.string,
    pageNumber: PropTypes.number,
    configWidth: PropTypes.string,
    updateConfigWidth: PropTypes.func,
    page: PropTypes.object,
    sourceLanguage: PropTypes.string,
    targetLanguage: PropTypes.string,
    addOrUpdatePages: PropTypes.func,
    updatedPages: PropTypes.array,
    handleSave: PropTypes.func,
    bookmarkingSelected: PropTypes.bool,
    diacritizeWords: PropTypes.func,
    regenerateBookmarking: PropTypes.func
};

export default DigitizationMarkdownComponent;
