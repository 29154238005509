// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 992px) { /* Adjust the width as needed */
    .hide-on-small {
        display: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/layouts/style.css"],"names":[],"mappings":"AAAA,uCAAuC,+BAA+B;IAClE;QACI,aAAa;IACjB;AACJ","sourcesContent":["@media screen and (max-width: 992px) { /* Adjust the width as needed */\n    .hide-on-small {\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
