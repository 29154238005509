import Tooltip from "@mui/material/Tooltip";
import React from "react";
import IconButton from "@mui/material/IconButton";
import {FiZoomIn, FiZoomOut} from "react-icons/fi";
import PropTypes from "prop-types";

const Scale = ({ scale, setScale }) => {

    const isMinZoom = scale < 0.9;
    const isMaxZoom = scale >= 2.0;

    const zoomOutClass = isMinZoom ? 'disabled' : 'clickable';
    const zoomInClass = isMaxZoom ? 'disabled' : 'clickable';

    const zoomOut = () => {
        if (!isMinZoom) setScale(scale - 0.1);
    };

    const zoomIn = () => {
        if (!isMaxZoom) setScale(scale + 0.1);
    };

    return (
        <div style={{width: '25%', minWidth:"15%"}}>
            <div style={{display:"flex", flexDirection:"row", gap:"0.3rem"}}>
                <Tooltip title="Zoom out">
                    <IconButton aria-label="Zoom out" className={`${zoomOutClass}`} id="basic-button"
                                style={{width: "5vh",
                                    height: "5vh",
                                    borderRadius: "15%",
                                }}
                                onClick={zoomOut}>
                        <FiZoomOut  style={{fontSize: 'smaller'}} />
                    </IconButton>
                </Tooltip>
                <div style={{paddingTop:"0.3rem"}}>
                    <span style={{fontSize: 'smaller'}}>{(scale * 100).toFixed()}%</span>
                </div>
                <Tooltip title="Zoom in">
                    <IconButton aria-label="Zoom in" className={`${zoomInClass}`} id="basic-button"
                                style={{width: "5vh",
                                    height: "5vh",
                                    borderRadius: "15%",
                                }} onClick={zoomIn}>
                        <FiZoomIn style={{fontSize: 'smaller'}}/>
                    </IconButton>
                </Tooltip>
            </div>
        </div>

    );

}

Scale.propTypes = {
    scale: PropTypes.number,
    setScale: PropTypes.func,

};
export default Scale;