import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import IconButton from "@mui/material/IconButton";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { MdEmail, MdLock } from "react-icons/md";
import SoftButton from "../../components/SoftButton";
import { IoClose } from "react-icons/io5";
import Divider from '@mui/material/Divider';
import PropTypes from "prop-types";
import CircularProgress from '@mui/material/CircularProgress';
import {updateCredits} from "../../axios-client";

const EditFreeAgentDialog = ({ open, onClose, fetchData, selectedUserData, setSnackbar}) => {
    const [formData, setFormData] = useState({
        email: '',
        credits: selectedUserData.credits,
    });
    const [errors, setErrors] = useState({});
    const [isFocused, setIsFocused] = useState(false);
    const [isUpdating , setIsUpdating] = useState(false);

    useEffect(() => {
        if (open && selectedUserData) {
            setFormData({
                email: selectedUserData.email || '',
                credits: selectedUserData.credits,
            });
        }
    }, [open, selectedUserData]);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const validateForm = () => {
        let formErrors = {};
        const credits = formData.credits ? formData.credits : 0.0;
        if (!credits) {
                formErrors.credits = "Credits are required";
        }

        return formErrors;
    };

    const handleSubmit = () => {
        const formErrors = validateForm();
        if (Object.keys(formErrors).length === 0) {
            const formDataToSend = new FormData();
            formDataToSend.append('credits', formData.credits);
            setIsUpdating(true);
                updateCredits(selectedUserData.id, formDataToSend).then(
                    response => {
                        setIsUpdating(false);
                        fetchData();
                        setSnackbar({ show: true, snackbarMessage: 'Credits updated successfully!', severity: 'success' });
                        onClose();
                    }
                ).catch(error  => {
                    setSnackbar({ show: true, snackbarMessage: 'Failed to update credits', severity: 'error' });
                })
        } else {
            setErrors(formErrors);
            setSnackbar({ show: true, snackbarMessage: 'Please fill out all required fields', severity: 'error' });
        }
    };

    const handleCancel = () => {
        onClose();
    };

    const handleSnackbarClose = () => {
        setSnackbar({ show: false, snackbarMessage: '', severity: '' });
    };

    const inputContainerStyle = {
        display: 'flex',
        alignItems: 'center',
        border: `1px solid ${isFocused ? '#cdcecf' : '#dcdfe3'}`,
        borderRadius: '8px',
        padding: '5px 10px',
        marginBottom: '10px',
        transition: 'border-color 0.3s ease',
    };

    const inputStyle = {
        width: '100%',
        padding: '10px 0px',
        border: 'none',
        fontSize: '14px',
        boxSizing: 'border-box',
        outline: 'none',
        fontFamily: 'Arial, Roboto',
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle
                id="form-dialog-title"
                sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}
            >
                Edit Free Agent
                <IoClose
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        size: 10
                    }}
                />
            </DialogTitle>
            <Divider/>
            <DialogContent>

                <label htmlFor="email" className="label-style">Free Agent</label>
                <div style={inputContainerStyle}>
                    <MdEmail className="icon-style"/>
                    <input type="text" id="email" name="email" style={inputStyle}
                           value={formData.email}
                           // onChange={handleChange}
                           onFocus={() => setIsFocused(true)}
                           onBlur={() => setIsFocused(false)}
                           disabled={true}/>
                </div>
                {errors.email && <p style={{color: 'red', fontSize: "12px"}}>{errors.email}</p>}
                <label htmlFor="credits" className="label-style">Credits balance</label>
                <div style={{ ...inputContainerStyle, position: 'relative' }}>
                    <MdLock className="icon-style"/>
                    <input type="number"
                           id="credits" name="credits" style={inputStyle}
                           value={formData.credits}
                           onChange={handleChange}
                           onFocus={() => setIsFocused(true)}
                           onBlur={() => setIsFocused(false)}/>
                </div>
                {errors.credits && <p style={{color: 'red', fontSize: "12px"}}>{errors.credits}</p>}


            </DialogContent>

            <DialogActions>
                <SoftButton variant="outlined" color="dark" onClick={handleCancel}>
                    Cancel
                </SoftButton>
                <SoftButton variant="gradient" color="primary" onClick={handleSubmit} type="submit" >
                    {isUpdating ? 'Please Wait...' : ' Update credits And Close'}
                    {isUpdating && <CircularProgress size={20} style={{ marginLeft: 10, color: "white" }} />}
                </SoftButton>
            </DialogActions>

        </Dialog>
    );
};

EditFreeAgentDialog.propTypes = {

    open: PropTypes.bool,
    onClose: PropTypes.func,
    fetchData: PropTypes.func,
    selectedUserData: PropTypes.shape({
        id: PropTypes.string,
        email: PropTypes.string,
        credits: PropTypes.float,
    }),
    setSnackbar: PropTypes.func,
}

export default EditFreeAgentDialog;
