import EditorCanvasComponent from "./editorCanvasComponents/EditorCanvasComponent";
import EditorToolbarComponent from "./editorToolbarComponents/EditorToolbarComponent";
import "../editorStyleSheet.css";
import PropTypes from "prop-types";

const BodyComponents = ({
                            handleFullScreen,
                            isFullScreen,
                            mediaPath,
                            containerWidth,
                            numPages,
                            pages,
                            pageNumber,
                            setForcePageNumber,
                            configWidth,
                            setNotification,
                            articleId,
                            zoom,
                            setZoom
                        }) => {

    let bodyWidth = "40%"
    if (configWidth) {
        bodyWidth = `calc(100% - ${configWidth}px)`;
    }

    return (
        <div style={{width: bodyWidth}}>
            <EditorToolbarComponent
                pageNumber={pageNumber}
                numPages={numPages}
                setForcePageNumber={setForcePageNumber}
                handleFullScreen={handleFullScreen}
                isFullScreen={isFullScreen}
                setNotification={setNotification}
                articleId={articleId}
                zoom={zoom}
                setZoom={setZoom}
            />
            <EditorCanvasComponent
                mediaPath={mediaPath}
                containerWidth={containerWidth}
                page={pageNumber}
                pages={pages}
                setForcePageNumber={setForcePageNumber}
                zoom={zoom}
                setZoom={setZoom}
            />

        </div>

    );


}

BodyComponents.propTypes = {
    mediaPath: PropTypes.string,
    containerWidth: PropTypes.string,
    zoom: PropTypes.number,
    setZoom: PropTypes.func,
    numPages: PropTypes.number,
    pageNumber: PropTypes.number,
    articleId: PropTypes.number,
    setScale: PropTypes.func,
    configWidth: PropTypes.string,
    handleFullScreen: PropTypes.func,
    setNotification: PropTypes.func,
    isFullScreen: PropTypes.bool,
    pages: PropTypes.array,
    setForcePageNumber: PropTypes.func,
};

export default BodyComponents;