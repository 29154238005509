import {Document, Packer, Paragraph, TextRun} from "docx";
import paypalicon from "../mainComponents/payment/assets/images/paypal-icon.png";
import visa from "../mainComponents/payment/assets/images/visa.png";
import master from "../mainComponents/payment/assets/images/mastercard.png";
import '../mainComponents/payment/assets/styles/paymentListStyleSheet.css';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import CurrencyExchangeRoundedIcon from '@mui/icons-material/CurrencyExchangeRounded';
import RotateLeftRoundedIcon from '@mui/icons-material/RotateLeftRounded';

/**
 * This function takes a date string as an argument, creates a new Date object from it,
 * and then uses the toLocaleDateString() method to format the date according to the
 * specified options
 * @param dateString
 * @returns {string}
 */
export function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { month: '2-digit', day: '2-digit', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
}

/**
 * This function takes a float and decimal numbers as arguments, and returns a formated float
 * specified options
 * @param value
 * @param decimalPlaces
 */
export const formatFloat  = (value, decimalPlaces) => {
    return value.toFixed(decimalPlaces);
}

export const getStatusIcon = (status ) => {
    switch (status) {
        case 'COMPLETED':
            return <CheckCircleOutlineRoundedIcon className="status-icon-style" style={{ border: '1px solid rgb(130, 214, 22)',  color: 'rgb(130, 214, 22)'}} />;
        case 'FAILED':
            return <HighlightOffRoundedIcon className="status-icon-style" style={{ border: '1px solid rgb(234, 6, 6)',  color: 'rgb(234, 6, 6)'}} />;
        case 'REFUNDED':
            return <CurrencyExchangeRoundedIcon className="status-icon-style" style={{ border: '1px solid rgb(52, 71, 103)',  color: 'rgb(52, 71, 103)'}} />;
        case 'PENDING':
            return <RotateLeftRoundedIcon className="status-icon-style" style={{ border: '1px solid rgb(52, 71, 103)',  color: 'rgb(52, 71, 103)'}} />;
        case 'paypal':
            return ( <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                <img src={paypalicon} alt="paypal" style={{width:"2.5rem"}} />
            </div>)
        case 'credit-card':
            return (
                <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                    <img src={visa} alt="Visa" style={{ height: '2rem' }} />
                    <img src={master} alt="Mastercard" style={{ height: '2rem' }} />
                </div>
            );
        default:
            return null;
    }
};


/**
 * Returns the current date in the 'YYYY-MM-DD' format based on the local timezone.
 * @returns {string} Formatted date (YYYY-MM-DD)
 */
export const getFormattedTodayDate = () => {
    const today = new Date();
    return `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;
};

export function downloadFile(content, fileName, contentType) {

    const a = document.createElement('a');
    const file = new Blob([content], {type: contentType});
    a.href = URL.createObjectURL(file);
    a.download = fileName;
    a.click();
}

/**
 * Converts time in "HH:MM:SS" format to total minutes.
 * @param {string} duration - Time duration in the format "HH:MM:SS".
 * @returns {number} Total minutes.
 */
export const formatTimeToMinutes = (duration) => {

    if (typeof duration != "string") {
        return 0;
    }
    const [hours, minutes, seconds] = duration.split(':').map(Number);
    return (hours * 60) + minutes + (seconds / 60);
};

export const downloadFileFromUrl = (fileName, url) => {

    fetch(url).then((response) => {
        response.blob().then((blob) => {

            // Creating new object of PDF file
            const fileURL =
                window.URL.createObjectURL(blob);

            // Setting various property values
            let alink = document.createElement("a");
            alink.href = fileURL;
            alink.download = fileName;
            alink.click();
        });
    });
};

export const downloadDocFile = async (fileName, paragraphs) => {

    const childrenParagraphs = paragraphs
        .filter(paragraph => paragraph.trim() !== "")
        .map((paragraph) => {
            const [header, ...rest] = paragraph.split('\n');
            const bodyText = rest.join('\n\n').trim();

            return new Paragraph({
                children: [
                    new TextRun({
                        text:  "\n" + header,
                        color: "0000FF",
                        bold: true,
                    }),
                    new TextRun({
                        text: bodyText,
                        break: 1,
                    }),
                ],
                spacing: {
                    after: 200,
                },
            });
        });

    const doc = new Document({
        sections: [
            {
                properties: {},
                children: childrenParagraphs
            }
        ]
    })

    Packer.toBlob(doc).then((blob) => {
        const a = document.createElement('a');

        a.href = URL.createObjectURL(blob);
        a.download = fileName;
        a.click();
    })
}

// Function to format data to URL query parameters
export const formatDataToUrlQueryParams = (data) => {
    const params = new URLSearchParams();
    Object.entries(data).forEach(([key, value]) => {
        if (value) {
            params.append(key, value);
        }
    });
    return params.toString();
};
