import "../../editorStyleSheet.css"
import { Document, Page } from "react-pdf";
import * as pdfjs from "pdfjs-dist/legacy/build/pdf";
import pdfWorker from "pdfjs-dist/legacy/build/pdf.worker.entry";
pdfjs.GlobalWorkerOptions.workerSrc = pdfWorker;
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Pages from "./Pages";

import PropTypes from "prop-types";

const EditorCanvasComponent = ({mediaPath, containerWidth, scale,setPageNumber, setNumPages,  forcePageNumber}) => {
        const containerRef = useRef();

        const [width, setWidth] = useState(0);
        const [height, setHeight] = useState(0);
        const [pageViewports, setPageViewports] = useState(null);
        const [pdf, setPdf] = useState(null);


        useEffect(() => {
            const { offsetWidth, offsetHeight } = containerRef.current;
            setWidth(offsetWidth);
            setHeight(offsetHeight);
        }, []);

    useEffect(() => {
        setWidth(containerWidth);
    }, [containerWidth]);



        const onDocumentLoadSuccess = (nextPdf) => {
            setNumPages(nextPdf.numPages)
            setPdf(nextPdf);
        }


        useEffect(() => {
            if (!pdf) {
                return;
            }
            (async () => {
                const pageNumbers = Array.from(new Array(pdf.numPages)).map(
                    (_, index) => index + 1
                );

                const nextPageViewports = await Promise.all(pageNumbers.map(async pageNumber => {
                    return pdf.getPage(pageNumber).then((page) => page.getViewport({scale: scale}))
                }));

                setPageViewports(nextPageViewports);
            })();
        }, [pdf, scale])

        const memoizedSetPageCallback = useCallback((newPage) => {
            setPageNumber(newPage)
        }, []);



        const CustomLoading = () => (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh'
            }}>
                <CircularProgress />
            </div>
        );



    return (
        <div className="DigitizationEditorCanvas"   ref={containerRef} >
            <div>
                <Document
                    file={mediaPath}
                    onLoadSuccess={onDocumentLoadSuccess}
                    className="pdfDocument"
                    loading={<CustomLoading />}
                >


                    <Pages pdf={pdf}
                           scale={scale}
                           width={width}
                           pageViewports={pageViewports}
                           height={height}
                           setPageNumber={memoizedSetPageCallback}
                           forcePageNumber={forcePageNumber}
                    />
                </Document>
            </div>
        </div>
    )

}

EditorCanvasComponent.propTypes = {
    mediaPath: PropTypes.string,
    containerWidth: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    scale: PropTypes.number,
    setPageNumber: PropTypes.func,
    forcePageNumber: PropTypes.number,
    setNumPages: PropTypes.func
};
export default EditorCanvasComponent;