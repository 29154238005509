import {FaBackward, FaFastBackward, FaFastForward, FaForward} from "react-icons/fa";
import IconButton from "@mui/material/IconButton";
import Tooltip from '@mui/material/Tooltip';
import React, {useState, useEffect} from "react";
import TextField from "@material-ui/core/TextField";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import "../../../editorStyleSheet.css";
import {useSoftUIController} from "../../../../../context";


const PageNavigation = ({pageNumber, setPageNumber, numPages, setNumPages, setForcePageNumber, setCloseForcePageNumber}) => {


    const [anchorEl, setAnchorEl] = useState(null);
    const [newPageNumber, setNewPageNumber] = useState('');
    const [controller, dispatch] = useSoftUIController();
    const {closedForcePageNumber} = controller;

    const isFirstPage = pageNumber === 1;
    const isLastPage = pageNumber === numPages;

    const firstPageClass = isFirstPage ? 'disabled' : 'clickable';
    const lastPageClass = isLastPage ? 'disabled' : 'clickable';

    const goToFirstPage = () => {
        if (!isFirstPage) setForcePageNumber(1);
    };
    const goToPreviousPage = () => {
        if (!isFirstPage) setForcePageNumber(pageNumber - 1);
    };
    const goToNextPage = () => {
        if (!isLastPage) setForcePageNumber(pageNumber + 1);
    };
    const goToLastPage = () => {
        if (!isLastPage) setForcePageNumber(numPages);
    };

    const updatePageNumber = (e) => {
        setForcePageNumber(Number(newPageNumber));
    };

    const handleClick = (event) => {
        setNewPageNumber(pageNumber)
        setAnchorEl(event.currentTarget);
        setForcePageNumber(pageNumber);
    };

    const handleClose = () => {
        setAnchorEl(null);

    };


    return(
        <div style={{width: "30%", display:"flex", flexDirection:"row"}}>
            <Tooltip title="Go to first page">
                <IconButton
                    className={`${firstPageClass}`}
                    onClick={goToFirstPage} id="basic-button"
                    style={{width: "3.5vh",
                        height: "5vh",
                        borderRadius: "15%",
                    }}
                >
                    <FaFastBackward style={{fontSize: 'smaller'}}/>
                </IconButton>
            </Tooltip>
            <Tooltip title="Go to previous page">
                <IconButton
                    className={`${firstPageClass}`}
                    onClick={goToPreviousPage} id="basic-button"
                    style={{width: "3.5vh",
                        height: "5vh",
                        borderRadius: "15%",
                    }}
                >
                    <FaBackward style={{fontSize: 'smaller'}}/>
                </IconButton>
            </Tooltip>
            <div style={{fontSize: 'smaller', paddingTop: "0.5rem"}}>
                Page{' '}
            </div>
                <Button
                    style={{ width: '10%', margin: '1% 2%' , paddingTop: "0.7rem"}}
                    onClick={handleClick}
                >
                    {pageNumber}
                  </Button>
            <div style={{fontSize: 'smaller',paddingTop: "0.5rem" ,minWidth: "3.875rem", width:" 1.875rem",    marginRight: "0.5rem"}}>
                    of {numPages}
            </div>

                <Menu
                    className="zoom-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl) }
                    onClose={handleClose}
                >
                            <MenuItem>
                                <TextField
                                    className="num-pages"
                                    type={"text"}
                                    size="small"
                                    defaultValue={pageNumber}
                                    value={newPageNumber}
                                    style={{ width: '50%', margin: '1% 2%' }}
                                    min={1}
                                    max={numPages || 1}
                                    onChange={(event) => setNewPageNumber(event.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter")
                                        updatePageNumber();

                                    }}
                                />
                            </MenuItem>
                        </Menu>
            <Tooltip title="Go to next page">
                <IconButton
                    className={`${lastPageClass}`}
                    onClick={goToNextPage} id="basic-button"
                    style={{width: "3.5vh",
                        height: "5vh",
                        borderRadius: "15%",
                    }}
                >
                    <FaForward style={{fontSize: 'smaller'}}/>
                </IconButton>
            </Tooltip>
            <Tooltip title="Go to last page">
                <IconButton
                    className={`${lastPageClass}`}
                    onClick={goToLastPage} id="basic-button"
                    style={{width: "3.5vh",
                        height: "5vh",
                        borderRadius: "15%",
                    }}
                >
                    <FaFastForward style={{fontSize: 'smaller'}}/>
                </IconButton>
            </Tooltip>
        </div>
    );

}

PageNavigation.propTypes = {
    numPages: PropTypes.number,
    pageNumber: PropTypes.number,
    setPageNumber: PropTypes.func,
    setNumPages: PropTypes.func,
    setForcePageNumber: PropTypes.func,
    setCloseForcePageNumber: PropTypes.func,
};


export default PageNavigation;