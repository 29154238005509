import React, { useState, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { FaDownload, FaTimes } from 'react-icons/fa';
import { IoFileTrayFull } from "react-icons/io5";
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from "prop-types";
import { getTitleWithoutDate } from "../../utlis/generalUtils";

const Alert = React.forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

const DocumentDownloadSnackbar = ({ documents, handleDownload, deleteFile, onClose }) => {
    const [openSnackbars, setOpenSnackbars] = useState({});
    const [downloadedDocs, setDownloadedDocs] = useState({});

    useEffect(() => {
        if (documents.length > 0) {
            const initialSnackbarsState = documents
                .filter(doc => !doc.isReady)
                .reduce((acc, doc) => ({
                    ...acc,
                    [doc.title]: true
                }), {});

            setOpenSnackbars(initialSnackbarsState);
        }
    }, [documents]);

    useEffect(() => {
        documents.forEach(doc => {
            if (doc.isReady && !downloadedDocs[doc.title]) {
                handleDownloadAndClose(doc);
            }
        });
    }, [documents, downloadedDocs]);

    const handleClose = (docTitle, reason) => {
        if (reason !== 'clickaway') {
            setOpenSnackbars((prev) => ({ ...prev, [docTitle]: false }));
        }
    };

    const handleDownloadAndClose = (doc) => {
        handleDownload(doc);
        setDownloadedDocs((prev) => ({ ...prev, [doc.title]: true }));
        setOpenSnackbars((prev) => ({ ...prev, [doc.title]: false }));
    };

    const handleRemoveAndClose = (doc) => {
        doc.isReady ? deleteFile(doc.title) : onClose(doc.title);
        setOpenSnackbars((prev) => ({ ...prev, [doc.title]: false }));
    };

    const truncateTitle = (title) => title.length > 30 ? `${title.substring(0, 30)}...` : title;

    return (
        <>
            {documents.map((doc, index) => (
                <Snackbar
                    key={doc.title}
                    open={openSnackbars[doc.title] || false}
                    onClose={(event, reason) => handleClose(doc.title, reason)}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    style={{ bottom: `${index * 105}px`, right: '20px' }}
                >
                    <Alert onClose={() => handleRemoveAndClose(doc)} severity="info" sx={{
                        padding: 0,
                        backgroundColor: '#fff',
                        boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                        '& .MuiAlert-action, .MuiAlert-icon': { display: 'none' },
                        '& .MuiAlert-message': { padding: 0 }
                    }}>
                        <div style={{
                            backgroundColor: 'rgb(73 72 72)',
                            color: 'rgb(229 229 229)',
                            padding: '13px',
                            borderTopLeftRadius: '3px',
                            borderTopRightRadius: '3px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}>
                            <strong style={{ fontSize: '0.9rem', fontWeight: 400 }}>
                                {doc.isReady ? "Ready for download" : "Preparing download"}
                            </strong>
                            <FaTimes style={{ cursor: 'pointer', color: '#fff' }} onClick={() => handleRemoveAndClose(doc)} />
                        </div>

                        <div style={{
                            padding: '13px 16px',
                            display: 'flex',
                            alignItems: 'center',
                            color: 'rgb(101 99 99)',
                            backgroundColor: 'rgb(239 239 239)'
                        }}>
                            <div style={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                                <IoFileTrayFull style={{ marginRight: '8px', color: '#555' }} />
                                <strong style={{ fontSize: '0.9rem', fontWeight: 500, width: '271px' }}>
                                    {truncateTitle(getTitleWithoutDate(doc.title))}
                                </strong>
                            </div>

                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {!doc.isReady && <CircularProgress size={24} thickness={4} style={{ color: '#6c6c6c' }} />}
                            </div>
                        </div>
                    </Alert>
                </Snackbar>
            ))}
        </>
    );
};

DocumentDownloadSnackbar.propTypes = {
    documents: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            isReady: PropTypes.bool.isRequired,
        })
    ).isRequired,
    handleDownload: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    deleteFile: PropTypes.func.isRequired,
};

export default DocumentDownloadSnackbar;
