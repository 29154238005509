import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Divider, Typography, Box } from '@mui/material';
import SuiButton from "../../components/SoftButton";
import { IoClose } from "react-icons/io5";
import { getSubjects, addSubject, checkDuplicatedArticles, getServices, getOutputFormat, extractPageCount } from "../../axios-client";
import data from '../../constants/languages.json';
import "../../mainComponents/departments/assets/departmentsStyleSheet.css";
import CircularProgress from '@mui/material/CircularProgress';
import MetaData from "./components/MetaData";
import FileUpload from "./components/FileUpload";
import SubjectTerms from "./components/SubjectTerms";
import PropTypes from "prop-types";
import Services from "./components/Services";
import OutputFormat from "./components/OutputFormat";
import CustomSelect from "../../reusableComponents/Select/CustomSelect";

const DigitizeArticleDialog = ({ open, onClose, handleDigitizeArticle, isDigitizing, userCreditBalance }) => {
  
    const [isFocused, setIsFocused] = useState(false);
    const [languages, setLanguages] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [formData, setFormData] = useState({
        title: '',
        author: '',
        publicationDate: '',
        sourceLanguage: 'Arabic',
        targetLanguage: 'English',
        subjectTerms: [],
        outputFormats: [],
        hasMulticolumn: false,
        services: [],
        file: null,
        audioFormat: '',
        voiceName: ''
    });
    const [errors, setErrors] = useState({});
    const [searchSubject, setSearchSubject] = useState('');
    const [isAddingSubject, setIsAddingSubject] = useState(false);
    const [existingArticleId, setExistingArticleId] = useState(null);
    const [disableNextButton, setDisableNextButton] = useState(true);
    const [uploadingInProgress, setUploadingInProgress] = useState(false);
    const [services, setServices] = useState([]);
    const [outputFormats, setOutputFormats] = useState([]);
    const [step, setStep] = useState(1);
    const [totalCost, setTotalCost] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    const FIXED_AMOUNT =  parseFloat(window?.appConfig?.REACT_APP_FIXED_AMOUNT || process.env.REACT_APP_FIXED_AMOUNT || 0.75);

    const inputContainerStyle = {
        display: 'flex',
        alignItems: 'center',
        border: `1px solid ${isFocused ? '#cdcecf' : '#dcdfe3'}`,
        borderRadius: '8px',
        padding: '5px 10px',
        marginBottom: '10px',
        transition: 'border-color 0.3s ease',
    };

    const inputStyle = {
        width: '100%',
        padding: '10px 0px',
        border: 'none',
        fontSize: '14px',
        boxSizing: 'border-box',
        outline: 'none',
        fontFamily: 'Arial, Roboto',
    };


    useEffect(() => {
        setLanguages(data);
        getSubjects()
            .then(res => {
                setSubjects(res.data);
            })
            .catch(error => {
                console.error('Error fetching subjects:', error);
            });

        getServices()
            .then(res => {
                setServices(res.data);
                const defaultSelectedServices = res.data
                    .filter(service => (service.pre_selected == true))
                    .map(key => key.id);
                setFormData(prevState => ({
                    ...prevState,
                    services: defaultSelectedServices
                }));
                const initialCost = res.data
                    .filter(service => defaultSelectedServices.includes(service.id))
                    .reduce((acc, service) => acc + (service.price || 0), 0);
                setTotalCost(FIXED_AMOUNT + (pageCount * initialCost));
            })
            .catch(error => {
                console.error('Error fetching services:');
            });
    }, []);

    useEffect(() => {
        getOutputFormat()
            .then(res => {
                if (res.data) {
                    setOutputFormats(res.data);
                    const defaultSelectedOutputFormats = res.data
                        .filter(outputFormat => (outputFormat.is_selected == true))
                        .map(key => key.id);
                    setFormData(prevState => ({
                        ...prevState,
                        outputFormats: defaultSelectedOutputFormats
                    }));
                }
            })
            .catch(error => {
                console.error('Error fetching output formats:', error);
            });
    }, []);
    useEffect(() => {
        let serviceCost = formData.services.reduce((acc, id) => {
            const service = services.find((s) => s.id === id);
            return service ? acc + (service.price || 0) : acc;
        }, 0);
    
        let outputFormatCost = formData.outputFormats.reduce((acc, id) => {
            const format = outputFormats.find((of) => of.id === id);
            return format && format.price ? acc + format.price : acc;
        }, 0);

        if (formData.outputFormats.includes(outputFormats.find(format => format.name === "Structured WORD")?.id) &&
            formData.outputFormats.includes(outputFormats.find(format => format.name === "EPUB")?.id)
        ) {
            outputFormatCost -= outputFormats.find(format => format.name === "EPUB").price
        }
    
        // Calculate the total cost and round to 2 decimal places
        const total = FIXED_AMOUNT + pageCount * (serviceCost + outputFormatCost);
        setTotalCost(parseFloat(total.toFixed(2)));
    }, [formData.services, services, formData.outputFormats, outputFormats, pageCount]);
    

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const onMultiColumnChange = (e) => {
        const isMultiColumn = e.target.value === "multi";
    
        setFormData(prevState => ({
            ...prevState,
            hasMulticolumn: isMultiColumn
        }));
    
        if (isMultiColumn) {
            // Replace "Unstructured WORD" with "Structured WORD"
            setFormData(prevState => ({
                ...prevState,
                outputFormats: prevState.outputFormats.map(format =>
                    format === outputFormats.find(format => format.name === "Unstructured WORD")?.id
                        ? outputFormats.find(format => format.name === "Structured WORD")?.id
                        : format
                )
            }));
    
            // Remove "Unstructured WORD" from outputFormats
            setOutputFormats(prevFormats =>
                prevFormats.filter(format => format.name !== "Unstructured WORD")
            );
        } else {
            getOutputFormat().then(res => {
                if (res.data) {
                    setOutputFormats(res.data);
                    const defaultSelectedOutputFormats = res.data
                        .filter(outputFormat => outputFormat.is_selected)
                        .map(outputFormat => outputFormat.id);
    
                    setFormData(prevState => ({
                        ...prevState,
                        outputFormats: defaultSelectedOutputFormats
                    }));
                }
            });
        }
    };    

    const handleSelectChange = (e) => {
        const { name, value } = e.target;
        setErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleBlur = async () => {
        if (formData.title && formData.author) {
            try {
                const response = await checkDuplicatedArticles({ params: { title: formData.title, author: formData.author } });
                if (response.data.exists) {
                    setErrors(prevErrors => ({
                        ...prevErrors,
                        title: 'An article with this title and author already exists!'
                    }));
                    setExistingArticleId(response.data.data.id);
                } else {
                    setExistingArticleId(null);
                }
            } catch (error) {
                console.error("Error checking duplicated articles:", error);
            }
        }
    };

    const handleService = (e, serviceId) => {
        if (formData.services.includes(serviceId)) {
            setFormData(prevState => ({
                ...prevState,
                services: formData.services.filter(key => key != serviceId)
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                services: [...prevState.services, serviceId]
            }));
        }
    };

    const handleOutputFormat = (e, format) => {
        setErrors(prevErrors => ({ ...prevErrors, outputFormats: '' }));
        if (format.name === "Unstructured WORD") {
            if (formData.outputFormats.includes(format.id)) {
                setFormData(prevState => ({
                    ...prevState,
                    outputFormats: []  
                }));
            } else {
                setFormData(prevState => ({
                    ...prevState,
                    outputFormats: [format.id]
                }));
            }
        } else {
            if (formData.outputFormats.includes(format.id)) {
                setFormData(prevState => ({
                    ...prevState,
                    outputFormats: prevState.outputFormats.filter(key => key !== format.id)
                }));
            } else {
                setFormData(prevState => ({
                    ...prevState,
                    outputFormats: [
                        ...prevState.outputFormats.filter(id => id !== outputFormats.find(format => format.name === "Unstructured WORD")?.id),
                        format.id
                    ]
                }));
            }
        }
    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        setDisableNextButton(true)
        if (file && file.type !== 'application/pdf') {
            setErrors(prevErrors => ({ ...prevErrors, file: 'Only PDF files are allowed' }));
        } else {
            setErrors(prevErrors => ({ ...prevErrors, file: '' }));
            setFormData(prevState => ({
                ...prevState,
                file: file,
                title: prevState.title || file.name.replace(/\.[^/.]+$/, "")
            }));
            if (file) {
                setUploadingInProgress(true)
                extractPageCount(file)
                    .then((res) => {
                        if (res.data && res.data.pageCount) {
                            setPageCount(res.data.pageCount);
                        }
                        setUploadingInProgress(false)
                        setDisableNextButton(false);
                    })
                    .catch((error) => {
                        console.error("Error extracting page count:", error);
                        setPageCount(0);
                        setUploadingInProgress(false)
                        setDisableNextButton(false);
                    });
            }
        }
    };

    // In Step 1, only Title and File are required.
    const validateForm = () => {
        let newErrors = {};
        if (!formData.title) newErrors.title = 'Title is required';
        if (!formData.file) newErrors.file = 'File upload is required';
        if (formData.outputFormats.length == 0) newErrors.outputFormats = 'Output Format is required';

        const audioBookService = services.find(service => service.name === 'Text to Speech');
        if(formData.services?.includes(audioBookService?.id)){
            if (!formData.audioFormat) newErrors.audioFormat = 'Audio format is required';
            if (!formData.voiceName) newErrors.voiceName = 'Voice Name is required';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleDigitization = () => {
        if (validateForm() && !isDigitizing && !existingArticleId) {
            const data = new FormData();
            data.append('title', formData.title);
            data.append('author', formData.author);
            data.append('publication_date', formData.publicationDate);
            data.append('source_language', formData.sourceLanguage);
            data.append('target_language', formData.targetLanguage);
            data.append('file', formData.file);
            data.append('audio_format', formData.audioFormat);
            data.append('voice_name', formData.voiceName);
            data.append("has_multi_column", formData.hasMulticolumn);
            formData.outputFormats.forEach(output => {
                data.append('output_formats', output);
            });
            formData.services.forEach(service => {
                data.append('services', service);
            });
            formData.subjectTerms.forEach(subjectId => {
                data.append('subjects', subjectId);
            });
            handleDigitizeArticle(data);
        }
    };

    const handleAddNewSubject = () => {
        if (!searchSubject.trim()) return;
        setIsAddingSubject(true);
        addSubject({ name: searchSubject })
            .then(res => {
                return getSubjects();
            })
            .then(res => {
                setSubjects(res.data);
                const newSubjectId = res.data.find(subject => subject.name === searchSubject)?.id;
                if (newSubjectId) {
                    setFormData(prevState => ({
                        ...prevState,
                        subjectTerms: [...prevState.subjectTerms, newSubjectId]
                    }));
                }
                setSearchSubject('');
            })
            .catch(error => {
                console.error('Error adding subject:', error);
            })
            .finally(() => {
                setIsAddingSubject(false);
            });
    };

    const handleNext = () => {
        if (!formData.title) {
            setErrors(prevErrors => ({ ...prevErrors, title: 'Title is required' }));
            return;
        }
        if (formData.file) {
            setStep(2);
        } else {
            setErrors(prevErrors => ({ ...prevErrors, file: 'Please upload a file to proceed' }));
        }
    };

    const handleBack = () => {
        setStep(1);
    };

    return (
        <Dialog open={open} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth PaperProps={{ style: { borderRadius: 15} }}>
            <DialogTitle
                id="form-dialog-title"
                sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', background: '#f5f5f5', padding: '16px' }}
            >
                {step === 1 ? "Document Metadata" : "Service Selection"}
                <IoClose
                    aria-label="close"
                    onClick={onClose}
                    style={{ position: 'absolute', right: 8, top: 8, cursor: 'pointer' }}
                />
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ padding: "0px 24px" }}>
                {step === 1 && (
                    <>
                        <FileUpload
                            setFormData={setFormData}
                            setErrors={setErrors}
                            handleFileUpload={handleFileUpload}
                            formData={formData}
                            errors={errors}
                            isUploading={uploadingInProgress}
                        />
                        <MetaData
                            inputContainerStyle={inputContainerStyle}
                            inputStyle={inputStyle}
                            formData={formData}
                            handleInputChange={handleInputChange}
                            setIsFocused={setIsFocused}
                            errors={errors}
                            handleSelectChange={handleSelectChange}
                            onMultiColumnChange={onMultiColumnChange}
                            languages={languages}
                            handleBlur={handleBlur}
                            isEditable={true}
                            requiredTitle={true}
                            requiredSourceLanguage={false}
                            requiredTargetLanguage={false}
                        />
                        <SubjectTerms subjects={subjects} setSearchSubject={setSearchSubject} handleAddNewSubject={handleAddNewSubject}
                                      isAddingSubject={isAddingSubject} searchSubject={searchSubject} formData={formData} setFormData={setFormData}
                        />
                    </>
                )}
                {step === 2 && (
                    <>
                        <Box sx={{ backgroundColor: '#fffff', padding: '16px', borderRadius: '8px', marginBottom: '16px' }}>
                            <OutputFormat
                                label={"OCR Output Format"}
                                outputFormats={outputFormats}
                                selectedFormat={formData.outputFormats}
                                handleCheckboxFormatChange={handleOutputFormat}
                                errors={errors} formData={formData}
                                setErrors={setErrors}
                            />
                        </Box>
                        <Box sx={{ backgroundColor: '#fffff', padding: '16px', borderRadius: '8px', marginBottom: '16px' }}>
                            <Services
                                label={"Additional Services"}
                                setFormData={setFormData}
                                selectedLanguage={formData['sourceLanguage'].toLowerCase()}
                                selectedTargetLanguage={formData['targetLanguage'].toLowerCase()}
                                services={services} selectedServices={formData.services}
                                handleCheckboxServiceChange={handleService}
                                errors={errors}
                                setErrors={setErrors}
                            />
                        </Box>
                        <Box
                            sx={{
                                textAlign: 'center',
                                p: 2,
                                backgroundColor: '#fffff', 
                                borderRadius: 2,
                                border: '1px solid #cce4f7',
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                mt: 2,
                            }}
                            >
                            <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#0066cc' }}>
                                Total Cost: ${totalCost}
                            </Typography>
                            <Typography variant="caption" sx={{ color: '#555', display: 'block', mt: 1 }}>
                                Document: {pageCount} pages
                            </Typography>
                            {userCreditBalance > 0 && userCreditBalance < totalCost && (
                                <Typography variant="caption" sx={{ color: '#cc0000', display: 'block', mt: 2 }}>
                                    Your chosen services exceed your available free credit, but you can still proceed with payment.
                                </Typography>
                            )}
                        </Box>
                    </>
                )}
            </DialogContent>
            <Divider />
            <DialogActions>
                <SuiButton variant="gradient" color="secondary" size="medium" onClick={onClose}>Close</SuiButton>
                {step === 1 ? (
                    <SuiButton variant="gradient" color="primary" size="medium" onClick={handleNext} disabled={disableNextButton}>
                        Next
                    </SuiButton>
                ) : (
                    <>
                        <SuiButton variant="gradient" color="secondary" size="medium" onClick={handleBack} disabled={isDigitizing}>
                            Back
                        </SuiButton>
                        <SuiButton variant="gradient" color="primary" size="medium" onClick={handleDigitization} disabled={existingArticleId}>
                            {isDigitizing ? 'Digitizing...' : 'Digitize'}
                            {isDigitizing && <CircularProgress size={20} style={{ marginLeft: 10, color: "white" }} />}
                        </SuiButton>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
    
};

DigitizeArticleDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    handleDigitizeArticle: PropTypes.func,
    isDigitizing: PropTypes.bool,
    userCreditBalance: PropTypes.number
};
export default DigitizeArticleDialog;
