import useImage from "use-image";
import React, {useEffect, useRef} from "react";
import {Brighten} from "konva/lib/filters/Brighten";
import {Contrast} from "konva/lib/filters/Contrast";
import {Image} from "react-konva";
import PropTypes from "prop-types";

const ImageComponent = ({src, x, y, brightness, contrast}) => {
    const [image] = useImage(src, 'anonymous');
    const imageRef = useRef();

    useEffect(() => {
        if (image) {
            imageRef.current.cache();
            imageRef.current.filters([Brighten, Contrast]);
            imageRef.current.brightness(brightness);
            imageRef.current.contrast(contrast);
            imageRef.current.getLayer().batchDraw();
        }
    }, [image, brightness, contrast]);

    return <Image image={image} x={x} y={y} ref={imageRef}/>;
};

ImageComponent.propTypes = {
    src: PropTypes.string,
    x: PropTypes.number,
    y: PropTypes.number,
    brightness: PropTypes.number,
    contrast: PropTypes.number
}

export default ImageComponent