import React, {useEffect, useState} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from "@mui/material/CardMedia";
import SoftBox from "../../../components/SoftBox";
import SoftTypography from "../../../components/SoftTypography";
import { CiBookmark } from "react-icons/ci";
import { PiMagnifyingGlassPlusFill } from "react-icons/pi";
import { TbTextScan2 } from "react-icons/tb";
import { LuAlertTriangle } from "react-icons/lu";
import { BsCash } from "react-icons/bs";
import defaultMiniature from "assets/images/curved-images/digitization.png";
import PropTypes from "prop-types";
import SoftButton from "../../../components/SoftButton";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ReactReadMoreReadLess from "react-read-more-read-less";
import { MdDelete } from "react-icons/md"
import DeleteArticleDialog from "../../../layouts/publications/DeleteArticleDialog";
import {useSoftUIController} from "../../../context";

import Highlighter from "react-highlight-words";
import {io} from 'socket.io-client';
import StepperComponent from "../../../components/Steper/StepperComponent";
import {LIBRARY_ADMIN_ROLE, SUPER_ADMIN_ROLE, FREE_AGENT} from "../../../utlis/constant";
import { format } from 'date-fns';
import PayPalPaymentButton from "../../payment/PayPalPaymentButton";

const CardItem = ({
                      id,
                      title,
                      image,
                      author,
                      publishedAt,
                      summary,
                      harmfulContent,
                      createdAt,
                      status,
                      handleClickDownloadDigitizedDocument,
                      handleOpenPreview,
                      downloadBookmarkingFile,
                      subjects,
                      refreshArticles,
                      setNotification,
                      articleId,
                      cost,
                      searchText,
                      refreshList,
                      paymentStatus,
                      freeAgentDocuments = false
                  }) => {
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [controller, dispatch] = useSoftUIController();
    const steps = [
        'Upload',
        'OCR',
        'Translating',
        'Summarizing',
        'Bookmarking',
        'Done',
    ];
    const stepsData = [
        {name: 'Uploading Article Done', step: 1},
        {name: 'Extracting text Done', step: 2},
        {name: 'Translating Article Done', step: 3},
        {name: 'Summarizing Article Done', step: 4},
        {name: 'Generating Entities Done', step: 5},
        {name: 'COMPLETE', step: 6},
    ]

    const stepObj = stepsData.find(step => step.name === status);
    const [activeStep, setActiveStep] = useState(stepObj ? stepObj.step : 0 );
    const [digitizationFailed, setDigitizationFailed] = useState(status === "ERROR" ? true : false);

    useEffect(() => {

        if (status != "COMPLETE" && status != "ERROR" && !digitizationFailed) {
            const baseURL = process.env.REACT_APP_SOCKET_SERVER
            const socket = io(baseURL);
            const eventName = `digitization_status_update-${id}`;
            socket.on(eventName, (data) => {
                setActiveStep(data.step)
                if (data.step === 6) {
                    socket.off(`digitization_status_update-${id}`);
                    refreshList()
                } else if (data.step === 7) {
                    socket.off(`digitization_status_update-${id}`);
                    setDigitizationFailed(true)
                    refreshList()
                }
            });


            return () => {
                socket.off(`digitization_status_update-${id}`);
            };
        }
    }, []);
    const handleOpenDeleteDialog = () => {
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    const formattedCreatedAt = createdAt ? format(new Date(createdAt), 'MMM dd, yyyy hh:mm a') : '';

    return (
        <Card sx={{ borderRadius: '0rem', border: '1px'}}>
            <SoftBox sx={{ margin: '5px', display: 'flex', justifyContent: 'center', width: '100%', alignItems: 'center' }}>
                {status != "COMPLETE" && status != "ERROR" && status != "PENDING" && !digitizationFailed && activeStep != 6 && <StepperComponent activeStep={activeStep} steps={steps}/>}
            </SoftBox>

            <Card sx={{flexDirection: 'row', border: '1px'}}>

                <SoftBox>
                    <CardMedia
                        component="img"
                        sx={{width: 80, height: 100, borderRadius: "0px"}}
                        image={image ?? defaultMiniature}
                        alt="digitized document"
                    />
                    <SoftButton
                        size={"small"}
                        variant="text"
                        style={{marginLeft: "0.5rem", textTransform: "none"}}
                        onClick={() => {
                            handleOpenPreview()
                        }}
                        color={"info"}
                        disabled={status != "COMPLETE"}
                    >
                        <PiMagnifyingGlassPlusFill/>
                        More info
                    </SoftButton>
                </SoftBox>
                <SoftBox sx={{display: 'flex', flexDirection: 'column', minWidth: '40%'}}>
                    <CardContent sx={{flex: '1 0 auto'}}>
                        <SoftTypography component="div" variant="h6">
                            <SoftBox>

                                <Highlighter
                                    searchWords={[searchText]}
                                    textToHighlight={title}
                                />

                                {status === "ERROR" &&
                                    <SoftButton variant="gradient" color={"error"} size={"small"}
                                                style={{marginLeft: "5px"}}
                                                disabled>
                                        Digitization Failed
                                    </SoftButton>
                                }

                            </SoftBox>
                        </SoftTypography>
                        <SoftTypography component="div" sx={{paddingTop: "10px", paddingLeft: "10px", width: '85%'}}>

                                <SoftTypography component="div" variant="body2" sx={{fontSize: "0.9rem"}}>
                                    <span> <strong>Author: </strong></span>
                                    {
                                        author && <Highlighter
                                            searchWords={[searchText]}
                                            textToHighlight={author}
                                        />
                                    }
                                </SoftTypography>


                                <SoftTypography component="div" variant="body2" sx={{fontSize: "0.9rem"}}>
                                    <span><strong>Published: </strong></span>
                                    {
                                        publishedAt && <Highlighter
                                            searchWords={[searchText]}
                                            textToHighlight={publishedAt}
                                        />
                                    }
                                </SoftTypography>


                                <SoftTypography component="div" variant="body2" sx={{fontSize: "0.9rem"}}>
                                    <span><strong>Summary: </strong></span>
                                    {summary && (
                                        <ReactReadMoreReadLess
                                            charLimit={400}
                                            readMoreText={"Read more ▼"}
                                            readLessText={"Read less ▲"}
                                        >
                                        {summary}
                                    </ReactReadMoreReadLess>
                                    )}
                                </SoftTypography>

                            {subjects && subjects.length > 0 && (
                                <SoftTypography component="div" variant="body2"
                                                sx={{fontSize: "0.7rem", paddingTop: "8px"}}>
                                    {subjects.map((subject, index) => (
                                        <SoftBox
                                            key={index}
                                            bgColor="dark"
                                            color="white"
                                            borderRadius="lg"
                                            sx={{
                                                display: "inline-block",
                                                padding: "5px 10px",
                                                margin: "5px",
                                                fontWeight: "lighter"
                                            }}
                                        >
                                            {subject}
                                        </SoftBox>
                                    ))}
                                </SoftTypography>
                            )}
                        </SoftTypography>
                    </CardContent>
                </SoftBox>
                <SoftBox sx={{position: "absolute", right: 0, marginRight: "10px"}}>
                    {(controller.role === SUPER_ADMIN_ROLE || controller.role === LIBRARY_ADMIN_ROLE) && (
                        <Tooltip title="Delete article">
                            <IconButton aria-label="Delete article" color="info" onClick={handleOpenDeleteDialog}>
                                <MdDelete/>
                            </IconButton>
                        </Tooltip>
                    )}
                    <Tooltip title="Download Bookmarking" arrow>
                        <IconButton aria-label="Download Bookmarking" disabled={status != "COMPLETE"} color="info"
                                    onClick={downloadBookmarkingFile}>
                            <CiBookmark/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Download digitized article">
                        <IconButton aria-label="Download digitized article" disabled={status != "COMPLETE"} color="info"
                                    onClick={() => handleClickDownloadDigitizedDocument()}>
                            <TbTextScan2/>
                        </IconButton>
                    </Tooltip>
                </SoftBox>
                {openDeleteDialog && (
                    <DeleteArticleDialog isModalOpen={openDeleteDialog} handleCloseModal={handleCloseDeleteDialog}
                                         articleId={articleId} articleTitle={title} refreshArticles={refreshArticles}
                                         setNotification={setNotification}/>
                )}
                <SoftBox sx={{position: "absolute", right: 0, marginRight: "140px", marginTop: "10px"}}>
                    <SoftTypography fontWeight={"light"} style={{fontSize: "0.8rem"}}>{formattedCreatedAt}</SoftTypography>
                </SoftBox>
                {harmfulContent && <SoftBox sx={{position: "absolute", right: 0, marginRight: "280px"}}>
                    <Tooltip title={harmfulContent}>
                        <IconButton aria-label="harmful_content" color="error">
                            <LuAlertTriangle />
                        </IconButton>
                    </Tooltip>
                </SoftBox>}

                {controller.role === FREE_AGENT && <SoftBox sx={{position: "absolute", right: 0, marginRight: "10px", marginTop: "45px"}}>
                    <div>
                        <SoftBox sx={{
                            float: 'right',
                            marginRight: '10px',
                            fontSize: '1rem',
                            fontWeight: 500
                        }}>${parseFloat(cost).toFixed(2)}</SoftBox>
                        <div>
                            {(status == "PENDING" || status == 'FAILED') && <PayPalPaymentButton articleId={articleId} refreshList={refreshList}
                                                                          setNotification={setNotification}
                                                                          cost={parseFloat(cost).toFixed(2)}
                            />}
                        </div>
                    </div>
                </SoftBox>}

                {freeAgentDocuments && controller.role === SUPER_ADMIN_ROLE && <SoftBox sx={{position: "absolute", right: 0, marginRight: "10px", marginTop: "45px"}}>
                    <SoftBox>
                        <SoftBox sx={{
                            float: 'right',
                            marginRight: '10px',
                            fontSize: '1rem',
                            fontWeight: 500
                        }}>${parseFloat(cost).toFixed(2)}

                            {paymentStatus == "COMPLETED" &&
                                <Tooltip title="Payment successful">
                                    <IconButton aria-label="harmful_content" color="success">
                                        <BsCash />
                                    </IconButton>
                                </Tooltip>
                            }
                            {paymentStatus == "FAILED" &&
                                <Tooltip title="Payment error">
                                    <IconButton aria-label="harmful_content" color="error">
                                        <BsCash />
                                    </IconButton>
                                </Tooltip>
                            }
                            {(paymentStatus == null || paymentStatus == "pending") &&
                                <Tooltip title="Payment pending">
                                    <IconButton aria-label="harmful_content" color="default">
                                        <BsCash />
                                    </IconButton>
                                </Tooltip>
                            }
                        </SoftBox>
                    </SoftBox>
                </SoftBox>}
            </Card>
        </Card>
    );
};

// Typechecking props for the BasicLayout
CardItem.propTypes = {
    id: PropTypes.number,
    title: PropTypes.string,
    summary: PropTypes.string,
    harmfulContent: PropTypes.string,
    author: PropTypes.string,
    image: PropTypes.string,
    status: PropTypes.string,
    paymentStatus: PropTypes.string,
    freeAgentDocuments: PropTypes.bool,
    cost: PropTypes.string,
    publishedAt: PropTypes.node,
    handleOpenPreview: PropTypes.func,
    createdAt: PropTypes.string,
    searchText: PropTypes.string,
    handleClickDownloadDigitizedDocument: PropTypes.func,
    downloadBookmarkingFile: PropTypes.func,
    subjects: PropTypes.array,
    setNotification: PropTypes.func,
    refreshArticles: PropTypes.func,
    refreshList: PropTypes.func,
    articleId: PropTypes.number
};
export default CardItem;
