import React from 'react';
import PropTypes from 'prop-types';
import CheckIcon from '@mui/icons-material/Check';
import CircularProgress from '@mui/material/CircularProgress';
import ClearIcon from '@mui/icons-material/Clear';


const CustomStepIcon = ({ status }) => {
    const renderIcon = () => {
        switch (status) {
            case 'COMPLETED':
                return (
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                            backgroundColor: '#4caf50',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 2,
                        }}
                    >
                        <CheckIcon style={{ color: 'white', fontSize: 13 }} />
                    </div>
                );
            case 'IN_PROGRESS':
                return (
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                            backgroundColor: '#e0e0e0',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'relative',
                            zIndex: 1,
                        }}
                    >
                        <CircularProgress
                            size={13}
                            thickness={3}
                            style={{
                                color: '#4caf50',
                                position: 'absolute',
                            }}
                        />
                    </div>
                );
            case 'ERROR':
                return (

                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                            backgroundColor: '#f44336',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 2,
                            cursor: 'pointer'
                        }}
                        title={"Rerun service"}
                    >
                        <ClearIcon style={{color: 'white', fontSize: 13}}/>
                    </div>
                );
            case 'PENDING':
            default:
                return (
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                            backgroundColor: '#e0e0e0',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    />
                );
        }
    };

    return <div style={{ position: 'relative', width: 20, height: 20, zIndex: 1 }}>{renderIcon()}</div>;
};

CustomStepIcon.propTypes = {
    status: PropTypes.oneOf(['PENDING', 'IN_PROGRESS', 'COMPLETED', 'ERROR']).isRequired,
};

export default CustomStepIcon