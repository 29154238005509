import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import CustomStepIcon from "./CustomStepIcon";

const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
    '&.Mui-active .MuiStepConnector-line': {
        borderColor: '#4caf50',
    },
    '&.Mui-completed .MuiStepConnector-line': {
        borderColor: '#4caf50',
    },
    '& .MuiStepConnector-line': {
        borderColor: '#e0e0e0',
    },
}));

const StepperStatusComponent = ({stepsData, handleRerunService }) => {
    return (
        <Box sx={{ justifyContent: 'center', width: '80%', alignItems: 'center' }}>
            <Stepper
                // activeStep={activeStep}
                alternativeLabel
                connector={<CustomStepConnector />}
                sx={{ margin: '20px 0 0 0' }}
            >
                {stepsData?.map((step, index) => (
                    <Step key={step.service_name} completed={step?.service_status === "COMPLETED" || step?.service_status === "ERROR"}>
                        <StepLabel
                            StepIconComponent={() => (
                                <CustomStepIcon status={step?.service_status}/>
                            )}
                            sx={{
                                fontSize: '12px',
                                '& .MuiStepLabel-label': { fontSize: '0.8rem' },
                            }}
                            onClick={() => {
                                if(step?.service_status === "ERROR"){
                                    handleRerunService({service_name: step.service_name})
                            }}}
                        >
                            {step.service_name}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
};

StepperStatusComponent.propTypes = {
    handleRerunService: PropTypes.func,
    stepsData: PropTypes.arrayOf(
        PropTypes.shape({
            service_name: PropTypes.string.isRequired,
            service_status: PropTypes.oneOf(['PENDING', 'IN_PROGRESS', 'COMPLETED', 'ERROR']).isRequired,
        })
    ).isRequired,
};

export default StepperStatusComponent