import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from "@mui/material/Divider";
import SoftButton from "../../components/SoftButton";
import PropTypes from "prop-types";

const EulaAgreementDialog = ({ open, handleAccept, handleDecline }) => {

    return (
        <Dialog open={open} maxWidth="sm" fullWidth>
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                End User License Agreement (EULA)
            </DialogTitle>
            <Divider />
            <DialogContent dividers={scroll === 'paper'} sx={{padding: "0px 24px", fontSize: "14px"}}>
                <p><b>Last Updated:</b> March 14, 2025</p>
                <br/>
                <p>PLEASE READ THIS END-USER LICENSE AGREEMENT (&quot;EULA&quot;) CAREFULLY BEFORE ACCESSING, PURCHASING,
                    INSTALLING, OR USING ANY SOFTWARE PRODUCTS OFFERED BY <b>YaiGlobal</b>. BY ACCESSING, PURCHASING,
                    INSTALLING, OR USING ANY PORTION OF OUR SOFTWARE, APPLICATIONS, WEBSITES, OR RELATED SERVICES
                    (COLLECTIVELY, THE &ldquo;PRODUCTS&rdquo; OR &ldquo;SERVICES&rdquo;), YOU (&ldquo;CUSTOMER&rdquo; OR &ldquo;YOU&rdquo;) ACKNOWLEDGE AND AGREE THAT
                    YOU ACCEPT THE TERMS OF THIS EULA AND AGREE TO BE BOUND BY THIS LEGALLY ENFORCEABLE AGREEMENT WITH
                    YaiGlobal (&ldquo;COMPANY,&rdquo; &ldquo;WE,&rdquo; &ldquo;US,&rdquo; OR &ldquo;OUR&rdquo;). If you do not agree to the terms of this EULA, you may
                    not use the Products.</p>
                <br/>
                <p>Business Customers: If you are a business customer, you may have an additional written agreement with
                    us (e.g., a volume license agreement or specific enterprise terms). Such agreements will supplement
                    this EULA.</p>
                <br/>
                <p>The Products and Services are licensed, not sold, in accordance with the terms of this EULA. If the
                    Product integrates with or utilizes third-party materials, components, or services (&quot;Third-Party
                    Services&quot;), additional terms imposed by those third parties may apply.</p>
                <h3 style={{ marginTop: "15px", marginBottom: "8px" }}>1. Introduction</h3>
                <p>This EULA, along with any applicable additional terms, policies, or guidelines (collectively, the
                    &ldquo;Agreement&rdquo;), governs your access to and use of our Products and related websites, applications, and
                    Services. It also applies to any software Product included as part of the Services, including but
                    not limited to desktop, mobile, and web-based applications, features, tools, content, and related
                    documentation. If you have entered into a separate agreement with us for specific Products or
                    Services, this EULA supplements that agreement.</p>

                <h4 style={{ marginTop: "9px", marginBottom: "5px" }}>1.1 Acceptance and Consent</h4>
                <p>By accepting this EULA through any provided consent method (e.g., clicking &ldquo;I agree,&rdquo; or using the
                    Product), you enter into a legally binding agreement with YAiGlobal.</p>

                <h4 style={{ marginTop: "9px", marginBottom: "5px" }}>1.2 Minors</h4>
                <p>By using the Products and Services, you affirm that you are of legal age to enter into this
                    Agreement. If you are a minor, you confirm that you have obtained parental or guardian consent to do
                    so.</p>

                <h4 style={{ marginTop: "9px", marginBottom: "5px" }}>1.3 Updates and Revisions</h4>
                <p>We reserve the right to modify, update, or discontinue any Product or Service (including features or
                    components) at any time without liability. This may include changes required for compliance with
                    regulatory requirements. The Company reserves the right to change these terms and conditions at any
                    time without prior notice.</p>

                <h4 style={{ marginTop: "9px", marginBottom: "5px" }}>1.4 Business Users & Authorization</h4>
                <p>If you accept this EULA on behalf of a business entity, organization, or other legal entity
                    (&ldquo;Business&rdquo;), you represent and warrant that you have the legal authority to bind that entity to
                    this Agreement. If you lack such authority or do not agree to these terms, you may not use the
                    Products on behalf of the Business. The Business will be fully responsible for ensuring compliance
                    with this EULA, including any obligations applicable to individual users under its direction.</p>

                <h3 style={{ marginTop: "15px", marginBottom: "8px" }}>2. Definitions</h3>
                <h4 style={{ marginTop: "9px", marginBottom: "5px" }}>2.1 Company</h4>
                <p>Company refers to YaiGlobal, a company headquartered in California USA.</p>

                <h4 style={{ marginTop: "9px", marginBottom: "5px" }}>2.2 Content</h4>
                <p>Content means any text, information, communication, or material, such as images, graphics, audio,
                    animation, video, electronic documents, or images, that you upload, import into, embed for use by,
                    or create using the Product or Services.</p>

                <h4 style={{ marginTop: "9px", marginBottom: "5px" }}>2.3 Services</h4>
                <p>Services means any Product-related service provided before or during the operation of a Product,
                    including software functionality, websites, contact channels, and any other support or maintenance
                    features and Content.</p>

                <h4 style={{ marginTop: "9px", marginBottom: "5px" }}>2.4 Product</h4>
                <p>Product refers to the software product or application, files, and related Content, whether
                    mobile/desktop/tablet, including, without limitation; (i) any feature or component offered to you
                    via the software (ii) any proprietary scripting embedded within; (iii) related explanatory written
                    materials and files (&ldquo;Documentation&rdquo;); and (iv) any modified versions and copies of, and upgrades,
                    updates, and additions to, such information, provided by us at any time, to the extent not provided
                    under separate terms (&ldquo;Updates&rdquo;).</p>

                <h3 style={{ marginTop: "15px", marginBottom: "8px" }}>3. Scope and Applicability</h3>
                <h4 style={{ marginTop: "9px", marginBottom: "5px" }}>3.1 Products in scope</h4>
                <p>This EULA governs the use of the YAi4Edu platform, accessible via https://yai4edu.yai365.com/ and any
                    associated services provided by YaiGlobal.</p>

                <h4 style={{ marginTop: "9px", marginBottom: "5px" }}>3.2 Users</h4>
                <p>This Agreement applies to two categories of end users: (a) Individual Users – Includes private
                    individuals using the Product for personal, non-commercial purposes; (b) Educational Institutions
                    and Organizations – Includes libraries, universities, archival institutions and other entities that
                    purchase the Product for academic, operational, or enterprise-wide use.</p>

                <h4 style={{ marginTop: "9px", marginBottom: "5px" }}>3.3 Trial and Paid Licenses</h4>
                <p>Certain products may be available in a trial version for a limited period. After the trial period
                    expires, the license will commence automatically as per the conditions of each Product license,
                    unless you cancel within the trial period.</p>

                <h4 style={{ marginTop: "9px", marginBottom: "5px" }}>3.4 Third-Party Services and Integrations</h4>
                <p>Yai4Edu may integrate with third-party services or platforms, such as cloud storage or online
                    collaboration tools. Users must comply with the respective third-party terms of use when utilizing
                    these features. YaiGlobal disclaims responsibility for any disruptions or security risks arising
                    from third-party service failures.</p>

                <h3 style={{ marginTop: "15px", marginBottom: "8px" }}>4. License Grant</h3>
                <p>Subject to your compliance with the Agreement and payment of the applicable license fees, we grant
                    you a non-exclusive, fully revocable, and limited license to use the Product (a) within the
                    authorized region, (b) during the term of such license, and (c) in a manner consistent with the
                    terms and conditions of this Agreement and any applicable Documentation.</p>

                <h3 style={{ marginTop: "15px", marginBottom: "8px" }}>5. Restrictions</h3>
                <p>You may not: (i) Rent, lease, sell, sublicense, or transfer your rights in the Product; (ii) Modify,
                    reverse engineer, or decompile the Product; (iii) Remove or alter any copyright or trademark
                    notices.</p>

                <h3 style={{ marginTop: "15px", marginBottom: "8px" }}>6. Disclaimers and Limitation of Liability</h3>
                <p>The Product and Services are provided &ldquo;as is&rdquo; without warranties of any kind. YaiGlobal is not
                    responsible for any damages arising from your use of the Product.</p>

                <h3 style={{ marginTop: "15px", marginBottom: "8px" }}>7. Pricing</h3>
                <p>We do everything we can to ensure that the prices on our and other companies’ websites are correct,
                    and we try to keep our prices constant. We reserve the right to change our product’s prices at any
                    time, either up or down, without further notice.</p>

                <h3 style={{ marginTop: "15px", marginBottom: "8px" }}>8. Governing Law</h3>
                <p>This Agreement is governed by the laws of the state of California, USA, without regard to its
                    conflict of law provisions.</p>

                <h3 style={{ marginTop: "15px", marginBottom: "8px" }}>9. Contact Information</h3>
                <p>For questions or support, please contact us at <a
                    href="mailto:info@yaiglobal.com">info@yaiglobal.com</a>.</p>

            </DialogContent>

            <DialogActions>
                <SoftButton variant="outlined" color="dark" onClick={handleDecline}>
                    Decline
                </SoftButton>
                <SoftButton variant="gradient" color="primary" onClick={handleAccept}>
                    Accept
                </SoftButton>
            </DialogActions>

        </Dialog>

    );
}

EulaAgreementDialog.propTypes = {
    open: PropTypes.bool,
    handleAccept: PropTypes.func,
    handleDecline: PropTypes.func
}
export default EulaAgreementDialog;