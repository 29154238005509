import React, { useState, useEffect } from 'react';
import Tables from "../../reusableComponents/Tables/Table/TableCard";
import {formatDataToUrlQueryParams, getStatusIcon,getFormattedTodayDate} from "../../utlis/generalUtils";
import Tooltip from "@mui/material/Tooltip";
import logo from "assets/images/logo.png";
import {downloadInvoicePDF} from "./helper";
import { FaDownload } from "react-icons/fa6";
import {captureOrder, getOrdersList, refundPayment} from "../../axios-client";
import RefundDialog from './RefundDialog';
import SnackbarNotification from "../../reusableComponents/Snackbar/SnackbarNotification";
import MenuItem from "@mui/material/MenuItem";
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from "@mui/material/Button";
import {useSoftUIController} from "../../context";
import {SUPER_ADMIN_ROLE} from "../../utlis/constant";


const PaymentList = () => {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [ordersCount, setOrdersCount] = useState(0);
    const [controller] = useSoftUIController();
    const { role } = controller;
    const today = getFormattedTodayDate();
    const {profileData} = controller;
    const [filterData, setFilterData] = useState({
        page: 1,
        size: 10,
        from: today,
        to: today,
        status: '',
        order_id: '',
        email: '',
    });
    const [statuses] = useState([
      { id: '', name: 'Select Status' },
      { id: 'completed', name: 'Completed' },
      { id: 'pending', name: 'Pending' },
      { id: 'failed', name: 'Failed' },
  ]);


  const [openDialog, setOpenDialog] = useState(false);
  const [refundType, setRefundType] = useState("custom");
  const [refundAmount, setRefundAmount] = useState(0);
  const [description, setDescription] = useState("");
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [notification, setNotification] = useState({show: false, snackbarMessage: '', severity: ""});
    const handlePageChange = (newPage) => {
        setFilterData((prev) => ({...prev, page: newPage}));
    };

    const handleRowsPerPageChange = (newSize) => {
        setFilterData((prev) => ({...prev, size: newSize, page: 1}));
    };

    const handleRefundClick = (order) => {
        setRefundAmount(parseFloat(order.amount));
        setOpenDialog(true);
        setSelectedOrder(order);
    };

    const handleFilterChange = (key, value) => {
      if (key === 'from' || key === 'to') {
          setFilterData((prev) => ({
              ...prev,
              from: value.from,
              to: value.to,
              page: 1, 
          }));
      } else {
          setFilterData((prev) => ({
              ...prev,
              [key]: value,
              page: 1, 
          }));
      }
  };

  const handleCloseDialog = () => {
    setSelectedOrder(null);
    setLoading(false)
    setRefundAmount(0);
    setOpenDialog(false);
};

const handleRefundSubmit = () => {
    setLoading(true)
    refundPayment(selectedOrder.id, refundAmount, description).then((res) => {
        setNotification({
            show: true,
            snackbarMessage: 'Amount refunded successfully!',
            severity: "success"
        });
        setOpenDialog(false);
        setLoading(false)
        refreshList();
    }).catch((e) => {
        setNotification({
            show: true,
            snackbarMessage: "An error occurred. Please try again.",
            severity: "error"
        });
        setOpenDialog(false);
        setLoading(false)
    });
};


const refreshList = () => {
        const queryParams = formatDataToUrlQueryParams(filterData);
        getOrdersList(`?${queryParams}`)
            .then(res => {
                const formattedRows = res.data.orders.map(order => {
                    const row = {
                        "Order ID": order.order_id,
                        "Date": order.date,
                        ...(order.payer_email ? { "Email": order.payer_email.toLowerCase() } : {}),
                        "Payment Method": <>{getStatusIcon(order?.payment_method)}</>,
                        "Document title": order.articles.map(article => article.title).join(", "),
                        "Pages": order.articles.map(article => article.page_count).join(", "),
                        "Status": <>{getStatusIcon(order?.status)}</>,
                        "Amount (USD)": `$${order.amount}`,
                    };

                    const actions = [];
                    if (order.download_invoice) {
                        actions.push(
                            <Tooltip title="Download invoice" key="download">
                            <span>
                                <FaDownload onClick={() => downloadInvoicePDF(order, profileData, logo)} />
                            </span>
                            </Tooltip>
                        );
                    }

                    if (res.data.role === SUPER_ADMIN_ROLE && order?.status === 'COMPLETED') {
                        actions.push(
                            <Button
                                title="Refund"
                                key="refund"
                                onClick={() => handleRefundClick(order)}
                            >
                                {getStatusIcon('REFUNDED-ICON')}
                            </Button>
                        );
                    }

                    row["Action"] = <>{actions}</>;
                    return row;
                });

                setRows(formattedRows);
                setLoading(false);
                setOrdersCount(res.data.count);
            })
            .catch(error => {
                console.error('Error fetching orders:', error);
                setLoading(false);
            });
    };

    useEffect(() => {
        refreshList();
    }, [filterData, profileData]);
    let filters = [
      {
          key: 'from',
          label: 'Start Date',
          type: 'date',
      },
      {
          key: 'status',
          label: 'Payment Status',
          type: 'select',
          options: statuses.map((status) => ({ id: status.id, label: status.name })),
      },
  ];

  if (role === SUPER_ADMIN_ROLE) {
      filters = [
          ...filters,
          {
              key: 'order_id',
              label: 'Order ID',
              type: 'input',
          },
          {
              key: 'email',
              label: 'Email',
              type: 'input',
          },
      ];
  }

    return (
        <>
            <Tables
                rows={rows}
                title={"Order List"}
                loadingData={loading}
                size={filterData.size}
                page={filterData.page}
                handlePageChange={handlePageChange}
                handleRowsPerPageChange={handleRowsPerPageChange}
                count={ordersCount}
                filters={filters}
                filterData={filterData}
                handleFilterChange={handleFilterChange}
            />

            <RefundDialog
                open={openDialog}
                onClose={handleCloseDialog}
                refundType={refundType}
                setRefundType={setRefundType}
                refundAmount={refundAmount}
                setRefundAmount={setRefundAmount}
                selectedOrder={selectedOrder}
                description={description}
                setDescription={setDescription}
                onSubmit={handleRefundSubmit}
                loading={loading}
            />

            <SnackbarNotification
                notification={notification}
                setNotification={setNotification}
            />
        </>
    );
};

export default PaymentList;