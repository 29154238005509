import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {createOrder, createPaymentIntent} from "../../axios-client";
import {Dialog, DialogContent, DialogTitle, Divider} from "@mui/material";
import {IoClose} from "react-icons/io5";
import StripePaymentButton from "./StripePaymentButton";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import Button from "@mui/material/Button";
import CreditCardIcon from '@mui/icons-material/CreditCard';

const CreditCardPaymentButton = ({articleId, refreshList, setNotification, cost}) => {


    const stripePromise = loadStripe(window?.appConfig?.REACT_APP_STRIPE_PUBLIC_KEY || process.env.REACT_APP_STRIPE_PUBLIC_KEY);
    const [clientSecret, setClientSecret] = useState("");
    const [isModalOpen, setModalOpen] = useState(false);
    const options = {
        // passing the client secret obtained from the server
        clientSecret: clientSecret,
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handleClick = () => {
        createOrder([articleId], 'credit-card')
            .then((res) => {
                setClientSecret(res.data.client_secret)
                setModalOpen(true);
            });
        return false;
    };

    return (
        <>
            <Button
                style={{padding: '0', width: "100%", borderRadius: "5px", backgroundColor: "#344767",
                    minWidth: "200px",
                    height: "35px",
                    minHeight: "35px",
                    color: "white",
                    fontWeight: "100",
                    textTransform: "none"
            }}
                onClick={handleClick}
            >
                Pay with Credit Card
                <CreditCardIcon style={{marginLeft: "5px"}}/>
            </Button>
            <Dialog open={isModalOpen} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth
                    PaperProps={{style: {borderRadius: 15}}}>
                <DialogTitle
                    id="form-dialog-title"
                    sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}
                >
                    Pay {cost} USD using
                    <IoClose
                        aria-label="close"
                        onClick={handleModalClose}
                        style={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            cursor: 'pointer'
                        }}
                    />
                </DialogTitle>
                <Divider/>
                <DialogContent sx={{padding: "0px 24px"}}>

                    <Elements stripe={stripePromise} options={options}>
                        <StripePaymentButton cost={cost}
                                             refreshList={refreshList} setNotification={setNotification}
                        />
                    </Elements>

                </DialogContent>
            </Dialog>


        </>

    );
};

CreditCardPaymentButton.propTypes = {
    articleId: PropTypes.number.isRequired,
    setNotification: PropTypes.func.isRequired,
    refreshList: PropTypes.func,
    stripePromise: PropTypes.string,
    cost: PropTypes.string.isRequired
};

export default CreditCardPaymentButton;