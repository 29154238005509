import Scale from "./editorToolbarElements/Scale";
import PageNavigation from "./editorToolbarElements/PageNavigation";
import PropTypes from "prop-types";
import { FaExpand, FaCompress } from 'react-icons/fa';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import {useSoftUIController} from "../../../../context";

const EditorToolbarComponent = ({
                                    handleFullScreen,
                                    isFullScreen,
                                    numPages,
                                    pageNumber,
                                    setForcePageNumber,
                                    setNotification,
                                    articleId,
                                    zoom,
                                    setZoom
                                }) => {

    const [controller] = useSoftUIController();
    const {role} = controller;

    return (
        <div className="digitizationEditorToolbar">
            <Tooltip title={isFullScreen ? "Exit Full Screen" : "Full Screen"}>
                <IconButton aria-label={isFullScreen ? "Exit Full Screen" : "Full Screen"} id="basic-button"
                            style={{
                                width: "5vh",
                                height: "5vh",
                                borderRadius: "15%",
                                color: 'white'
                            }}
                            onClick={handleFullScreen}>
                    {isFullScreen ? <FaCompress style={{fontSize: 'smaller', color: 'white'}}/> :
                        <FaExpand style={{fontSize: 'smaller', color: 'white'}}/>}
                </IconButton>
            </Tooltip>

            <Scale
                scale={zoom}
                setScale={setZoom}
            />

            <PageNavigation
                pageNumber={pageNumber}
                numPages={numPages}
                setForcePageNumber={setForcePageNumber}
            />


        </div>
    );
}

EditorToolbarComponent.propTypes = {
    numPages: PropTypes.number,
    pageNumber: PropTypes.number,
    articleId: PropTypes.number,
    setForcePageNumber: PropTypes.func,
    handleFullScreen: PropTypes.func,
    setNotification: PropTypes.func,
    isFullScreen: PropTypes.bool,
    zoom: PropTypes.number,
    setZoom: PropTypes.func,
};
export default EditorToolbarComponent;