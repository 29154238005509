import {memo, useEffect, useRef} from "react";
import {Document, Page} from "react-pdf";
import {VariableSizeList as List} from "react-window";
import PropTypes, {object} from "prop-types";
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import '../../editorStyleSheet.css';


const Pages = ({pdf, pageViewports, width, height, scale, setPageNumber, forcePageNumber}) => {

    const listRef = useRef();
    useEffect(() => {

        if (listRef.current) {
            listRef.current.resetAfterIndex(0);
        }
    }, [pageViewports]);

    useEffect(() => {


            if (listRef.current) {
                listRef.current.scrollToItem(forcePageNumber - 1 , "end");
        }


    }, [forcePageNumber]);
    const getPageHeight = (pageIndex) => {
        if (!pageViewports) {
            throw new Error("getPageHeight() called too early");
        }
        const pageViewport = pageViewports[pageIndex];
        return pageViewport.height + 50;
    }



    return (pdf && pageViewports ? (
            <List
                width={width}
                height={height}
                estimatedItemSize={getPageHeight(0)}
                itemCount={pdf.numPages}
                itemSize={getPageHeight}
                ref={listRef}
                overscanCount={5}
                onItemsRendered={({ visibleStartIndex }) => {
                    setPageNumber(visibleStartIndex + 1 );
                }}
                style={{overflow:  'auto'}}
            >
                {({index, style}) => {
                    return (
                        <div style={style} >
                            <Page  pageIndex={index}
                                  scale={scale}
                            />
                        </div>
                    );
                }}
            </List>
        ) : null
    )
}
Pages.propTypes = {
    pdf: PropTypes.object,
    pageViewports: PropTypes.array,
    width: PropTypes.number,
    height: PropTypes.number,
    scale: PropTypes.number,
    setPageNumber: PropTypes.func,
    forcePageNumber: PropTypes.number,
};



export default memo(Pages);
