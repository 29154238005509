import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { useRef } from 'react';

const colorPairs = [
    ["#f00084", "#833ab4"],
    ["#01708f", "#63b5e8"],
    ["#ffcc00", "#ff9933"],
    ["#43b36b", "#1a883f"],
    ["#f58231", "#e03a00"],
    ["#911eb4", "#52057b"],
];
ChartJS.register(ArcElement, Tooltip, Legend);

const DonutChart = ({ dataValues, labels, title }) => {
    const chartRef = useRef(null);

    // Function to create a gradient color
    const createGradient = (ctx, chartArea, colorStart, colorEnd) => {
        const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
        gradient.addColorStop(0, colorStart);
        gradient.addColorStop(1, colorEnd);
        return gradient;
    };

    const data = {
        labels: labels,
        datasets: [
            {
                data: dataValues,
                backgroundColor: (context) => {
                    const chart = context.chart;
                    const { ctx, chartArea } = chart;

                    if (!chartArea) {
                        return null;
                    }

                    // Create gradients for each segment
                    return dataValues.map((_, index) => {
                        const [colorStart, colorEnd] = colorPairs[index % colorPairs.length];
                        return createGradient(ctx, chartArea, colorStart, colorEnd);
                    });
                },
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        cutout: "70%",
        plugins: {
            legend: {
                display: false, // Custom legend below
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        return `${tooltipItem.label}: ${tooltipItem.raw}`;
                    },
                },
            },
        },
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                padding: "3.813rem",
                minWidth: "fit-content",
                maxWidth: "fit-content",
                borderRadius: "15px",
                position: "relative",

            }}
        >
            <Typography
                variant="h6"
                component="h6"
                sx={{
                    position: "absolute",
                    top: "1rem",
                    left: "13rem",
                }}
            >
                {title}
            </Typography>

            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Box sx={{ position: "relative", width: "11.5rem", height: "11.5rem", marginLeft:"2rem"}}>
                    <Doughnut ref={chartRef} data={data} options={options} />

                    <Box
                        sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            textAlign: "center",
                        }}
                    >
                        <Typography variant="h5" fontWeight="bold">{dataValues.reduce((sum, val) => sum + val, 0)}</Typography>
                        <Typography variant="caption" color="textSecondary">
                            Total
                        </Typography>
                    </Box>
                </Box>

                <Box sx={{ paddingLeft: "0.5rem" }}>
                    {data.labels.map((label, index) => (
                        <Box key={index} sx={{ display: "flex", alignItems: "center", marginBottom: "0.625rem", marginRight: "6rem" }}>
                            <Box
                                sx={{
                                    width: "10px",
                                    height: "10px",
                                    backgroundColor: colorPairs[index % colorPairs.length][0],
                                    borderRadius: "50%",
                                    marginRight:"1rem",
                                    marginLeft:"4.5rem"
                                }}
                            />
                            <Typography variant="body2" fontWeight="bold" sx={{ marginRight: "1.5rem" }}>
                                {label}
                            </Typography>
                            <Typography variant="body2" sx={{ marginLeft: "auto" }}>
                                {dataValues[index]}
                            </Typography>
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    );
};

DonutChart.propTypes = {
    title: PropTypes.string,
    dataValues: PropTypes.arrayOf(PropTypes.number),
    labels: PropTypes.arrayOf(PropTypes.string),

}
export default DonutChart;
