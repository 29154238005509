import React, {useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import { FaPlay, FaPause } from 'react-icons/fa';
import WaveSurfer from 'wavesurfer.js';

const AudioPlayer = ({ audioUrl}) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const wavesurferRef = useRef(null);

    useEffect(() => {
        if (wavesurferRef.current) {
            wavesurferRef.current.destroy();
        }

        const wavesurfer = WaveSurfer.create({
            container: '#waveform',
            waveColor: '#ddd',
            progressColor: '#f00084',
            height: 80,
            barWidth: 2,
            cursorWidth: 1,
        });

        wavesurferRef.current = wavesurfer;

        const loadAudio = async () => {
            try {
                if (audioUrl) {
                    await wavesurfer.load(audioUrl);
                }
            } catch (error) {
            }
        };

        loadAudio();
        setIsPlaying(false)

        return () => {
            if (wavesurferRef.current) {
                wavesurferRef.current.destroy();
            }
        };
    }, [audioUrl]);

    const handlePlayPause = () => {
        setIsPlaying(!isPlaying);
        if (!isPlaying) {
            wavesurferRef.current.play();
        } else {
            wavesurferRef.current.pause();
        }
    };

    return (
        <div
            style={{
                marginTop: '20px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '10px',
                padding: '0 15px',
                maxWidth: '100%',
            }}
        >
            <button
                onClick={handlePlayPause}
                style={{ border: 'none', background: 'none', cursor: 'pointer' }}
            >
                {isPlaying ? <FaPause size={20} /> : <FaPlay size={20} />}
            </button>
            <div id="waveform" style={{ width: '80%', height: '80px', maxWidth: '100%' }} />
        </div>
    );
};

AudioPlayer.propTypes = {
    audioUrl: PropTypes.string,
};

export default AudioPlayer;
