import Tables from "../../reusableComponents/Tables/Table/TableCard";
import React, { useState, useEffect } from 'react';
import {
    getDepartementsList,
    getLibrarianAdminsList,
    getLibrariansList,
    getUniversities,
    deleteLibrarianAdmin,
    activateUsers
} from "../../axios-client";
import AddLibrarianDialog from "./AddLibrarianDialog";
import { IconButton } from '@mui/material';
import Edit from '@mui/icons-material/Edit';
import KeyIcon from '@mui/icons-material/Key';
import Tooltip from "@mui/material/Tooltip";
import EditLibrarianDialog from "./EditLibrarianDialog";
import {formatDataToUrlQueryParams} from "../../utlis/generalUtils";
import ResetPasswordDialog from "./ResetPasswordDialog";
import AvatarComponent from "../../reusableComponents/Avatar/AvatarComponent";
import {useSoftUIController} from "../../context";
import {SUPER_ADMIN_ROLE} from "../../utlis/constant";
import { MdDelete } from "react-icons/md";
import DeleteLibrarianDialog from "./DeleteLibrarianDialog";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import SuiButton from "../../components/SoftButton";
import CircularProgress from '@mui/material/CircularProgress';


const Librarians = (props) => {
    const [controller, dispatch] = useSoftUIController();
    const {role} = controller;
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openAddLibrarianDialog, setOpenAddLibrarianDialog] = useState(false);
    const [openEditLibrarianDialog, setOpenEditLibrarianDialog] = useState(false);
    const [openResetPasswordDialog, setOpenResetPasswordDialog] = useState(false);
    const [selectedLibrarian, setSelectedLibrarian] = useState(null);
    const [librariansCount, setLibrariansCount] = useState(0);
    const [librarianAdminsCount, setLibrarianAdminsCount] = useState(0);
    const [filterData, setFilterData] = useState({
        page: 1,
        size: 10,
    });
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [librarianToDelete, setLibrarianToDelete] = useState(null);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });
    const [isActivating, setIsActivating] = useState(false);
    const [updatedUserIds, setUpdatedUserIds] = useState([]);
    const [notification, setNotification] = useState({show: false, snackbarMessage: '', severity: ""})

    const handleOpenDeleteDialog = (librarian) => {
        setLibrarianToDelete(librarian);
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
        setLibrarianToDelete(null);
    };

    useEffect(() => {
            fetchLibrarians(filterData);
    }, [filterData, role, isActivating]);


    const handlePageChange = (newPage) => {
        setFilterData((prev) => ({ ...prev, page: newPage }));
    };

    const handleRowsPerPageChange = (newSize) => {
        setFilterData((prev) => ({ ...prev, size: newSize, page: 1 }));
    };
    const handleUserStatus = (ids, isActive) => {

        if (ids.length === 0) {
            setNotification({
                show: true,
                snackbarMessage: 'No inactive librarian to activate!',
                severity: 'info',
            });
            return;
        }

        setIsActivating(true)
        const userIds = Array.isArray(ids) ? ids.join(',') : ids;
        const payload = { is_active: isActive };

        setUpdatedUserIds(userIds)

        activateUsers(userIds, payload)
            .then(() => {
                setIsActivating(false)
                setNotification({
                    show: true,
                    snackbarMessage: 'Librarian activated successfully!',
                    severity: "success"
                });
                fetchLibrarians(filterData);
            })
            .catch(err => {
                setIsActivating(false)
                console.error('Error activating user:', err);
                setNotification({
                    show: true,
                    snackbarMessage: 'Failed to activate librarian. Please try again!',
                    severity: "error"
                })
            });
    }

    const fetchLibrarians = (filterData = {}) => {
        setLoading(true);
        const queryParams = formatDataToUrlQueryParams(filterData);

        if (role === SUPER_ADMIN_ROLE) {
            getLibrarianAdminsList(`?${queryParams}`)
                .then(res => {
                    const formattedRows = res.data.users.map(librarianAdmin => {
                        return {
                            "Name": (
                                <AvatarComponent
                                    name={librarianAdmin.name}
                                    image={librarianAdmin.profile_picture ?? librarianAdmin.name}
                                    bgColor={"secondary"}
                                    variant={"rounded"}
                                />
                            ),
                            "Email": librarianAdmin.email,
                            "Phone": librarianAdmin.phone,
                            "University": librarianAdmin.university,
                            "Action": (
                                <>
                                    <Tooltip title="Edit librarian's information">
                                        <IconButton
                                            onClick={() => openEditDialog(librarianAdmin)}
                                            style={{ cursor: 'pointer' }}
                                            size="small"
                                        >
                                            <Edit />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Reset password">
                                        <IconButton
                                            onClick={() => handleOpenResetPasswordDialog(librarianAdmin)}
                                            style={{ cursor: 'pointer' }}
                                            size="small"
                                        >
                                            <KeyIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Delete librarian admin">
                                        <IconButton
                                            onClick={() => handleOpenDeleteDialog(librarianAdmin)}
                                            style={{ cursor: 'pointer' }}
                                            size="small"
                                        >
                                            <MdDelete />
                                        </IconButton>
                                    </Tooltip>
                                </>
                            ),
                        };
                    });

                    setLibrarianAdminsCount(res.data.count);
                    setRows(formattedRows);
                    setLoading(false);
                });
        } else {
            getLibrariansList(`?${queryParams}`)
                .then(res => {
                    const formattedRows = res.data.users.map(librarian => ({
                        "Name": (
                            <AvatarComponent
                                name={librarian.name}
                                image={librarian.profile_picture ?? librarian.name}
                                bgColor={"secondary"}
                                variant={"rounded"}
                            />
                        ),
                        "Email": librarian.email,
                        "Phone": librarian.phone,
                        "Department": librarian.departments.map(department => department.name).join(", "),
                        "Action": (
                            <>
                                <Tooltip title="Edit librarian's information">
                                    <IconButton
                                        onClick={() => openEditDialog(librarian)}
                                        style={{ cursor: 'pointer' }}
                                        size="small"
                                    >
                                        <Edit />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Reset password">
                                    <IconButton
                                        onClick={() => handleOpenResetPasswordDialog(librarian)}
                                        style={{ cursor: 'pointer' }}
                                        size="small"
                                    >
                                        <KeyIcon />
                                    </IconButton>
                                </Tooltip>
                                <SuiButton variant="outlined" color="primary" size="medium" onClick={() => handleUserStatus([librarian.id], !librarian.is_active)}>
                                    {isActivating && updatedUserIds.includes(librarian.id) ? 'Processing...' : librarian.is_active ? "Desactivate" : "Activate"}
                                    {isActivating && updatedUserIds.includes(librarian.id) &&  <CircularProgress size={20} style={{ marginLeft: 10, color: "white" }} />}
                                </SuiButton>

                            </>
                        ),
                    }));

                    setLibrariansCount(res.data.count);
                    setRows(formattedRows);
                    setLoading(false);
                });
        }
    };


    const handleOpenDialog = () => {
        setOpenAddLibrarianDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenAddLibrarianDialog(false);
    };
    const openEditDialog = (librarian) => {
        setSelectedLibrarian(librarian);
        setOpenEditLibrarianDialog(true);
    };

    const closeEditDialog = () => {
        setOpenEditLibrarianDialog(false);
        setSelectedLibrarian(null);
    };

    const handleOpenResetPasswordDialog = (librarian) => {
        setSelectedLibrarian(librarian);
        setOpenResetPasswordDialog(true);
    };

    const handleCloseResetPasswordDialog = () => {
        setOpenResetPasswordDialog(false);
        setSelectedLibrarian(null);
    };


    const refreshLibrariansList = () => {
        handleCloseDialog();
        fetchLibrarians(filterData);
    };
    const handleDeleteConfirm = (id) => {
        deleteLibrarianAdmin(id)
            .then(() => {
                setSnackbar({ open: true, message: 'Librarian admin user successfully deleted.', severity: 'success' });
                fetchLibrarians(filterData); 
                handleCloseDeleteDialog();
            })
            .catch((error) => {
                setSnackbar({ open: true, message: error.response?.data?.message || 'Failed to delete librarian admin.', severity: 'error' });
                handleCloseDeleteDialog();
            });
    };
    const handleSnackbarClose = () => {
        setSnackbar({ open: false, message: "", severity: "" });
    };


    return (
        <div>
            <Tables
                rows={rows}
                title={role === SUPER_ADMIN_ROLE ? "Librarian Admins" : "Librarian"}
                showAddButton={true}
                ButtonName={role === SUPER_ADMIN_ROLE ? "Add Librarian Admin" : "Add Librarian"}
                loadingData={loading}
                size={filterData.size}
                page={filterData.page}
                handlePageChange={handlePageChange}
                handleRowsPerPageChange={handleRowsPerPageChange}
                count={role === SUPER_ADMIN_ROLE ? librarianAdminsCount : librariansCount}
                onButtonClick={handleOpenDialog}
            />
            {openAddLibrarianDialog && (
                <AddLibrarianDialog
                    open={openAddLibrarianDialog}
                    onClose={handleCloseDialog}
                    onLibrarianAdded={refreshLibrariansList}
                    isSuperAdmin={role === SUPER_ADMIN_ROLE}
                />
            )}
            {openEditLibrarianDialog && (
                <EditLibrarianDialog
                    open={openEditLibrarianDialog}
                    onClose={closeEditDialog}
                    onLibrarianAdded={fetchLibrarians}
                    librarianData={selectedLibrarian}
                    isSuperAdmin={role === SUPER_ADMIN_ROLE}
                />
            )}
            {openResetPasswordDialog && (
                <ResetPasswordDialog
                    open={openResetPasswordDialog}
                    onClose={handleCloseResetPasswordDialog}
                    onLibrarianAdded={fetchLibrarians}
                    librarianData={selectedLibrarian}
                    isSuperAdmin={role === SUPER_ADMIN_ROLE}
                />
            )}
            {openDeleteDialog && librarianToDelete && (
                <DeleteLibrarianDialog
                    open={openDeleteDialog}
                    onClose={handleCloseDeleteDialog}
                    onConfirm={handleDeleteConfirm}
                    librarian={librarianToDelete}
                />
            )}
            {/* Snackbar for feedback */}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default Librarians;
