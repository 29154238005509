import colors from "../../assets/theme/base/colors";
import PropTypes from "prop-types";

const StarsComponent = ({rating, isHighlighted}) => {
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 > 0;
    const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);
    const starColor = isHighlighted ? `${colors.primary.focus}` : "#F59E0B";

    return (
        <>
            {/* Full stars */}
            {Array.from({length: fullStars}).map((_, i) => (
                <svg
                    key={`full-${i}`}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill={starColor}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M9.99999 1.66667L12.575 6.88334L18.3333 7.725L14.1667 11.7833L15.15 17.5167L9.99999 14.8083L4.84999 17.5167L5.83333 11.7833L1.66666 7.725L7.42499 6.88334L9.99999 1.66667Z"/>
                </svg>
            ))}

            {/* Half star */}
            {hasHalfStar && (
                <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <defs>
                        <linearGradient id={`halfStar-${starColor.replace('#', '')}`}>
                            <stop offset="50%" stopColor={starColor}/>
                            <stop offset="50%" stopColor="transparent" stopOpacity="1"/>
                        </linearGradient>
                    </defs>
                    <path
                        d="M9.99999 1.66667L12.575 6.88334L18.3333 7.725L14.1667 11.7833L15.15 17.5167L9.99999 14.8083L4.84999 17.5167L5.83333 11.7833L1.66666 7.725L7.42499 6.88334L9.99999 1.66667Z"
                        fill={`url(#halfStar-${starColor.replace('#', '')})`}
                        stroke={starColor}
                        strokeWidth="0.5"
                    />
                </svg>
            )}

            {/* Empty stars */}
            {Array.from({length: emptyStars}).map((_, i) => (
                <svg
                    key={`empty-${i}`}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    stroke={starColor}
                    strokeWidth="0.5"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M9.99999 1.66667L12.575 6.88334L18.3333 7.725L14.1667 11.7833L15.15 17.5167L9.99999 14.8083L4.84999 17.5167L5.83333 11.7833L1.66666 7.725L7.42499 6.88334L9.99999 1.66667Z"/>
                </svg>
            ))}
        </>
    );
};

StarsComponent.propTypes = {
    rating: PropTypes.number,
    isHighlighted: PropTypes.bool,
};


export default StarsComponent;