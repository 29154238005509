import React, { useEffect, useState } from 'react';
import Tables from "../../reusableComponents/Tables/Table/TableCard";
import { IconButton, Avatar } from '@mui/material';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import AddUniversityDialog from "./AddUniversityDialog";
import EditUniversityDialog from "./EditUniversityDialog";
import SnackbarNotification from "../../reusableComponents/Snackbar/SnackbarNotification";
import { getUniversities, AddUniversities, EditUniversities, DeleteUniversity } from "../../axios-client";
import { formatDataToUrlQueryParams } from "../../utlis/generalUtils";
import "./assets/universitiesStyleSheet.css";
import universityAvatar from "../../assets/images/universityAvatar.png";
import AvatarComponent from "../../reusableComponents/Avatar/AvatarComponent";
const DEFAULT_AVATAR_URL = universityAvatar

const Universities = () => {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openAddUniversityDialog, setOpenAddUniversityDialog] = useState(false);
    const [openEditUniversityDialog, setOpenEditUniversityDialog] = useState(false);
    const [selectedUniversity, setSelectedUniversity] = useState(null);
    const [notification, setNotification] = useState({ show: false, snackbarMessage: '', severity: "" });
    const [universitiesCount, setUniversitiesCount] = useState(0);
    const [filterData, setFilterData] = useState({ page: 1, size: 10 });

    const fetchData = () => {
        const queryParams = formatDataToUrlQueryParams(filterData);
        getUniversities(`?${queryParams}`)
            .then(res => {
                const formattedRows = res.data.universities.map(university => ({
                    "University Logo": (
                        <AvatarComponent
                            name={university.name}          
                            image={university.logo_url || DEFAULT_AVATAR_URL}  
                            bgColor={"secondary"}
                            variant={"rounded"}
                            size={"small"}
                        />
                    ),
                    "University Name": university.name,
                    "Email": university.email,
                    "Type": university.university_type,
                    "Action": (
                        <>
                            <IconButton
                                onClick={() => handleOpenEditDialog(university)}
                                style={{ cursor: 'pointer' }}
                                size="small"
                            >
                                <Edit />
                            </IconButton>
                        </>
                    ),
                }));
                
                setUniversitiesCount(res.data.total_count);
                setRows(formattedRows);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching universities:", error);
                setLoading(false);
                setNotification({
                    show: true,
                    snackbarMessage: "Failed to fetch universities. Please try again!",
                    severity: "error",
                });
            });
    };

    
    
    const handleOpenEditDialog = (university) => {
        setSelectedUniversity(university);
        setOpenEditUniversityDialog(true);
    };

    const handleCloseEditDialog = () => {
        setOpenEditUniversityDialog(false);
        setSelectedUniversity(null);
    };

    useEffect(() => {
        if (filterData) {
            fetchData();
        }
    }, [filterData]);

    const handleAddUniversity = async (formData) => {
        try {
            await AddUniversities(formData);
            fetchData();
            setOpenAddUniversityDialog(false);
            setNotification({ show: true, snackbarMessage: 'University added successfully!', severity: "success" });
        } catch (error) {
            console.error('Error adding university:', error);
            setNotification({ show: true, snackbarMessage: 'Failed to add university. Please try again!', severity: "error" });
        }
    };

    const handleUpdateUniversity = async (id, updatedData) => {
        try {
            await EditUniversities(id, updatedData);
            fetchData();
            setOpenEditUniversityDialog(false);
            setNotification({ show: true, snackbarMessage: 'University updated successfully!', severity: "success" });
        } catch (error) {
            console.error('Error updating university:', error);
            setNotification({ show: true, snackbarMessage: 'Failed to update university. Please try again!', severity: "error" });
        }
    };


    const handleOpenDialog = () => {
        setOpenAddUniversityDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenAddUniversityDialog(false);
    };

    const handlePageChange = (newPage) => {
        setFilterData((prev) => ({ ...prev, page: newPage }));
    };

    const handleRowsPerPageChange = (newSize) => {
        setFilterData((prev) => ({ ...prev, size: newSize, page: 1 }));
    };

    return (
        <>
            <Tables 
                rows={rows} 
                title={"Universities"} 
                showAddButton={true} 
                ButtonName={"Add University"}
                onButtonClick={handleOpenDialog} 
                loadingData={loading} 
                size={filterData.size}
                page={filterData.page} 
                handlePageChange={handlePageChange}
                handleRowsPerPageChange={handleRowsPerPageChange} 
                count={universitiesCount} 
            />
            {openAddUniversityDialog && (
                <AddUniversityDialog
                    open={openAddUniversityDialog}
                    onClose={handleCloseDialog}
                    handleAddUniversity={handleAddUniversity}
                />
            )}
            {openEditUniversityDialog && (
                <EditUniversityDialog
                    open={openEditUniversityDialog}
                    onClose={handleCloseEditDialog}
                    handleUpdateUniversity={handleUpdateUniversity}
                    universityData={selectedUniversity}
                />
            )}
            <SnackbarNotification
                notification={notification}
                setNotification={setNotification}
            />
        </>
    );
};

export default Universities;