
import React, {useEffect} from "react";
import { ChevronDown, X, Check } from "lucide-react";
import "./CustomSelect.css";
import PropTypes from "prop-types";
export const CustomSelect = React.forwardRef(
    (
        {
            options,
            value,
            onChange,
            placeholder = "Select an option",
            disabled = false,
            error,
            required = false,
            name,
            id,
            variant = "outlined",
            className = "",
            menuClassName = "",
            optionClassName = "",
            clearable = false,
            fullWidth = false,
            onBlur,
            ...props
        },
        ref
    ) => {
        const [isOpen, setIsOpen] = React.useState(false);
        const [selectedValue, setSelectedValue] = React.useState(value);
        const [highlightedIndex, setHighlightedIndex] = React.useState(-1);
        const selectRef = React.useRef(null);
        const menuRef = React.useRef(null);



      useEffect(() => {
            setSelectedValue(value);
        }, [value]);


      useEffect(() => {
            const handleClickOutside = (event) => {
                if (
                    selectRef.current &&
                    !selectRef.current.contains(event.target) &&
                    menuRef.current &&
                    !menuRef.current.contains(event.target)
                ) {
                    setIsOpen(false);
                    if (onBlur) onBlur();
                }
            };

            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [onBlur]);


        const handleKeyDown = (e) => {
            if (disabled) return;

            switch (e.key) {
                case "Enter":
                    if (isOpen && highlightedIndex >= 0 && highlightedIndex < options.length) {
                        const option = options[highlightedIndex];
                        if (!option.disabled) {
                            handleSelect(option.value);
                        }
                    } else {
                        setIsOpen((prev) => !prev);
                    }
                    e.preventDefault();
                    break;
                case " ":
                    if (!isOpen) {
                        setIsOpen(true);
                        e.preventDefault();
                    }
                    break;
                case "ArrowDown":
                    if (isOpen) {
                        setHighlightedIndex((prev) => {
                            let nextIndex = prev + 1;
                            while (nextIndex < options.length && options[nextIndex].disabled) {
                                nextIndex++;
                            }
                            return nextIndex >= options.length ? prev : nextIndex;
                        });
                    } else {
                        setIsOpen(true);
                    }
                    e.preventDefault();
                    break;
                case "ArrowUp":
                    if (isOpen) {
                        setHighlightedIndex((prev) => {
                            let nextIndex = prev - 1;
                            while (nextIndex >= 0 && options[nextIndex].disabled) {
                                nextIndex--;
                            }
                            return nextIndex < 0 ? prev : nextIndex;
                        });
                    } else {
                        setIsOpen(true);
                    }
                    e.preventDefault();
                    break;
                case "Escape":
                    setIsOpen(false);
                    if (onBlur) onBlur();
                    break;
                case "Tab":
                    setIsOpen(false);
                    if (onBlur) onBlur();
                    break;
            }
        };

        const handleSelect = (optionValue) => {
            setSelectedValue(optionValue);
            setIsOpen(false);
            if (onChange) onChange(optionValue);
        };

        const handleClear = (e) => {
            e.stopPropagation();
            setSelectedValue(undefined);
            if (onChange) onChange("");
        };

        const getSelectedLabel = () => {
            if (!selectedValue) return "";
            const option = options.find((opt) => opt.value === selectedValue);
            return option ? option.label : "";
        };


        const getVariantClass = () => {
            switch (variant) {
                case "filled":
                    return "select-filled";
                case "standard":
                    return "select-standard";
                default:
                    return "select-outlined";
            }
        };

        const containerClasses = [
            "select-container",
            fullWidth ? "select-full-width" : "",
            disabled ? "select-disabled" : "",
            className,
        ]
            .filter(Boolean)
            .join(" ");

        const triggerClasses = [
            "select-trigger",
            getVariantClass(),
            error ? "select-error" : "",
            disabled ? "select-trigger-disabled" : "",
        ]
            .filter(Boolean)
            .join(" ");

        const menuClasses = ["select-menu", menuClassName].filter(Boolean).join(" ");

        return (
            <div
                className={containerClasses}
                ref={(node) => {
                    selectRef.current = node;
                    if (ref) {
                        if (typeof ref === "function") {
                            ref(node);
                        } else {
                            ref.current = node;
                        }
                    }
                }}
                onKeyDown={handleKeyDown}
                tabIndex={disabled ? -1 : 0}
                role="combobox"
                aria-expanded={isOpen}
                aria-haspopup="listbox"
                aria-controls="select-dropdown"
                aria-required={required}
                aria-invalid={!!error}
                {...props}
            >
                <div
                    className={triggerClasses}
                    onClick={() => {
                        if (!disabled) setIsOpen(!isOpen);
                    }}
                >
                    <div className="select-value">
                        {selectedValue ? (
                            <span>{getSelectedLabel()}</span>
                        ) : (
                            <span className="select-placeholder">{placeholder}</span>
                        )}
                    </div>
                    <div className="select-actions">
                        {clearable && selectedValue && (
                            <button
                                type="button"
                                onClick={handleClear}
                                className="select-clear-button"
                                aria-label="Clear selection"
                            >
                                <X className="select-icon" />
                            </button>
                        )}
                        <ChevronDown className={`select-icon ${isOpen ? "select-icon-rotate" : ""}`} />
                    </div>
                </div>

                {error && <div className="select-error-message">{error}</div>}

                {isOpen && (
                    <div ref={menuRef} id="select-dropdown" role="listbox" className={menuClasses}>
                        {options.length > 0 ? (
                            options.map((option, index) => {
                                const optionClasses = [
                                    "select-option",
                                    selectedValue === option.value ? "select-option-selected" : "",
                                    highlightedIndex === index ? "select-option-highlighted" : "",
                                    option.disabled ? "select-option-disabled" : "",
                                    optionClassName,
                                ]
                                    .filter(Boolean)
                                    .join(" ");

                                return (
                                    <div
                                        key={option.value}
                                        role="option"
                                        aria-selected={selectedValue === option.value}
                                        aria-disabled={option.disabled}
                                        className={optionClasses}
                                        onClick={() => {
                                            if (!option.disabled) handleSelect(option.value);
                                        }}
                                        onMouseEnter={() => {
                                            if (!option.disabled) setHighlightedIndex(index);
                                        }}
                                    >
                                        <span>{option.label}</span>
                                        {/*{selectedValue === option.value && <Check className="select-check-icon" />}*/}
                                    </div>
                                );
                            })
                        ) : (
                            <div className="select-no-options">No options available</div>
                        )}
                    </div>
                )}
            </div>
        );
    }
);

CustomSelect.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            label: PropTypes.string,
            disabled: PropTypes.bool,
        })
    ),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    required: PropTypes.bool,
    name: PropTypes.string,
    id: PropTypes.string,
    variant: PropTypes.oneOf(["outlined", "filled", "standard"]),
    className: PropTypes.string,
    menuClassName: PropTypes.string,
    optionClassName: PropTypes.string,
    clearable: PropTypes.bool,
    fullWidth: PropTypes.bool,
    onBlur: PropTypes.func,
};

CustomSelect.defaultProps = {
    placeholder: "Select an option",
    disabled: false,
    required: false,
    variant: "outlined",
    className: "",
    menuClassName: "",
    optionClassName: "",
    clearable: false,
    fullWidth: false,
};



export default CustomSelect;