
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import {useState} from "react";
import Box from "@mui/material/Box";

// Images
import dashboard from "assets/images/shapes/dashboard.png";
import "../../../../layouts/style.css"
import SuiButton from "../../../../components/SoftButton";
import {useNavigate} from "react-router-dom";

function BuildByDevelopers() {
    const navigate = useNavigate();

    const fullText = `Yai4edu provides researchers with innovative tools to streamline article digitization, making it easier to extract insights, organize knowledge, and unlock new opportunities for learning. Upload your documents and let our state-of-the-art AI tools do the heavy lifting—whether it's text extraction, translation, or content structuring, Yai4Edu is here to support your research and educational needs. Get started today and experience seamless digitization with Yai4edu!`;
    const redirectToDigitizationPage = () => {
        navigate("/documents")
    };

    return (
        <Box sx={{ width: "100%", padding:"0.875rem" }}>
            <Grid
                container
                spacing={2}
                alignItems="center"
            >
                <Grid
                    item
                    xs={12}
                    md={7}
                    order={{ xs: 2, md: 1 }}
                >
                    <SoftBox display="flex" flexDirection="column" height="100%">
                        <SoftBox mb={0.5}>
                            <SoftTypography variant="body2" color="text" fontWeight="medium">
                                Getting Started
                            </SoftTypography>
                        </SoftBox>
                        <SoftTypography variant="h5" fontWeight="bold" gutterBottom>
                            Yai4edu
                        </SoftTypography>
                        <SoftBox mb={2}>
                            <SoftTypography variant="body2" color="text">
                                {fullText}
                            </SoftTypography>
                        </SoftBox>

                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: { xs: "center", sm: "flex-start" },
                                mt: { xs: 1, md: 2 }
                            }}
                        >
                            <SuiButton
                                variant="gradient"
                                color="primary"
                                size="small"
                                onClick={redirectToDigitizationPage}
                            >
                                Try Now
                            </SuiButton>
                        </Box>
                    </SoftBox>
                </Grid>

                <Grid item xs={12} md={5} order={{ xs: 1, md: 2 }}
                      sx={{
                          display: "flex",
                          justifyContent: "center",
                          mb: { xs: 2, md: 0 }
                      }}
                >
                    <SoftBox borderRadius="lg" display="flex" justifyContent="center" alignItems="center">
                        <SoftBox
                            component="img"
                            src={dashboard}
                                alt="waves"
                            sx={{
                                maxWidth: "100%",
                                height: "auto",
                                maxHeight: { xs: "180px", md: "200px" },
                                borderRadius: 2,
                                objectFit: "contain"
                            }}
                        />
                    </SoftBox>
                </Grid>
            </Grid>
        </Box>
    );
}

export default BuildByDevelopers;