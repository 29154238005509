import {
    SET_PICTURE,
    SET_TOKEN,
    SET_USERINFO,
    SET_EULA_ACCEPTED,
    SET_PROFILE_DATA,
    SET_PAGE_NUMBER,
    SET_FORCE_PAGE_NUMBER,
    UPDATE_EDITOR_CONTAINER_WIDTH,
    UPDATE_CONFIG_WIDTH, FORCED_MINIFIED, CLOSED_FORCE_PAGE_NUMBER
} from "../actions/app-actions";


export const initialState = {
    miniSidenav: false,
    forceMinified: false,
    transparentSidenav: false,
    sidenavColor: "primary",
    transparentNavbar: false,
    fixedNavbar: true,
    openConfigurator: false,
    direction: "ltr",
    layout: "dashboard",
    username: null,
    picture: null,
    token: null,
    role: null,
    userId: null,
    eulaAccepted: true,
    profileData: {} ,
    pageNumber:1,
    forcePageNumber: null,
    containerWidth: null,
    configWidth: null,
    closedForcePageNumber: true
}

export function appReducer(state = initialState, action) {

    switch (action.type) {
        case "MINI_SIDENAV": {
            return { ...state, miniSidenav: action.value };
        }
        case FORCED_MINIFIED: {
            return { ...state, forceMinified: action.value };
        }
        case "TRANSPARENT_SIDENAV": {
            return { ...state, transparentSidenav: action.value };
        }
        case "SIDENAV_COLOR": {
            return { ...state, sidenavColor: action.value };
        }
        case "TRANSPARENT_NAVBAR": {
            return { ...state, transparentNavbar: action.value };
        }
        case "FIXED_NAVBAR": {
            return { ...state, fixedNavbar: action.value };
        }
        case "OPEN_CONFIGURATOR": {
            return { ...state, openConfigurator: action.value };
        }
        case "DIRECTION": {
            return { ...state, direction: action.value };
        }
        case "LAYOUT": {
            return { ...state, layout: action.value };
        }
        case SET_USERINFO: {
            return { ...state, username: action.value.name, role: action.value.role, userId: action.value.user_id };
        }
        case SET_PICTURE: {
            return { ...state, picture: action.value };
        }
        case SET_PROFILE_DATA: {
            return { ...state, profileData: action.value };
        }
        case SET_TOKEN: {
            return { ...state, token: action.value };
        }
        case SET_PAGE_NUMBER: {
            return { ...state, pageNumber: action.value };
        }
        case SET_FORCE_PAGE_NUMBER: {
            return { ...state, forcePageNumber: action.value, pageNumber: action.value};
        }
        case CLOSED_FORCE_PAGE_NUMBER: {
            console.log('action.value', action)
            return { ...state, closedForcePageNumber: action.value};
        }
        case UPDATE_EDITOR_CONTAINER_WIDTH: {
            return { ...state, containerWidth: action.payload };
        }
        case UPDATE_CONFIG_WIDTH: {
            return {...state, configWidth: action.payload};
        }
        case SET_EULA_ACCEPTED: {
            return { ...state, eulaAccepted: action.value };
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}