import React, {useState, useEffect} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Divider} from '@mui/material';
import SuiButton from "../../components/SoftButton";
import {IoClose} from "react-icons/io5";
import {addSubject, checkDuplicatedArticles, getSubjects, updateMetadata} from "../../axios-client";
import "../../mainComponents/departments/assets/departmentsStyleSheet.css";
import MetaData from "./components/MetaData";
import SubjectTerms from "./components/SubjectTerms";
import PropTypes from "prop-types";

const EditDigitizeArticleDialog = ({
                                       open,
                                       onClose,
                                       author,
                                       title,
                                       subjects,
                                       publishedAt,
                                       sourceLanguage,
                                       targetLanguage,
                                       digitizationId,
                                       setNotification,
                                       refreshList
                                   }) => {
    const [formData, setFormData] = useState({
        title: '',
        author: '',
        publicationDate: '',
        sourceLanguage: '',
        targetLanguage: '',
        subjectTerms: subjects?.map(subject => subject.id) || [],

    });
    const [availableSubjects, setAvailableSubjects] = useState([]);
    const [isFocused, setIsFocused] = useState(false);
    const [errors, setErrors] = useState({});
    const [existingArticleId, setExistingArticleId] = useState(null);
    const [searchSubject, setSearchSubject] = useState('');
    const [isAddingSubject, setIsAddingSubject] = useState(false);
    const inputContainerStyle = {
        display: 'flex',
        alignItems: 'center',
        border: `1px solid ${isFocused ? '#cdcecf' : '#dcdfe3'}`,
        borderRadius: '8px',
        padding: '5px 10px',
        marginBottom: '10px',
        transition: 'border-color 0.3s ease',
    };

    const inputStyle = {
        width: '100%',
        padding: '10px 0px',
        border: 'none',
        fontSize: '14px',
        boxSizing: 'border-box',
        outline: 'none',
        fontFamily: 'Arial, Roboto',
    };
    useEffect(() => {
        if (open) {
            getSubjects()
                .then(res => {
                    setAvailableSubjects(res.data);
                    const selectedSubjectIds = subjects?.map(subject => {
                        const foundSubject = res.data.find(s => s.name === subject);
                        return foundSubject ? foundSubject.id : null;
                    }).filter(Boolean) || [];

                    setFormData({
                        title,
                        author,
                        publicationDate: publishedAt,
                        sourceLanguage,
                        targetLanguage,
                        subjectTerms: selectedSubjectIds,
                    });
                })
                .catch(error => {
                    console.error('Error fetching subjects:', error);
                })
        }
    }, [open, title, author, publishedAt, subjects]);

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setErrors(prevErrors => ({...prevErrors, [name]: ''}));
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };


    const validateForm = () => {
        const newErrors = {};
        if (!formData.title) newErrors.title = 'Title is required';
        if (!formData.author) newErrors.author = 'Author is required';
        if (!formData.publicationDate) newErrors.publicationDate = 'Publication date is required';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleBlur = () => {
        if (formData.title && formData.author) {
            checkDuplicatedArticles(
                {
                    params:
                        {
                            title: formData.title,
                            author: formData.author,
                            excluded_id: digitizationId
                        }
                })
                .then(response => {
                    if (response.data.exists) {
                        setErrors(prevErrors => ({
                            ...prevErrors,
                            title: 'An article with this title and author already exists!'
                        }));
                        setExistingArticleId(response.data.data.id)
                    } else {
                        setExistingArticleId(null);
                    }
                })
                .catch(error => {

                });
        }
    };
    const handleUpdateMetadata = async () => {
        if (validateForm() && !existingArticleId) {
            const updatedMetadata = {
                title: formData.title,
                author: formData.author,
                publication_date: formData.publicationDate,
                subjects: formData.subjectTerms,
            };

            updateMetadata(digitizationId, updatedMetadata)
                .then(response => {
                    refreshList();
                    onClose();

                }).catch(error => {
                    refreshList();
                    onClose();
            })
        }
    };


    const handleAddNewSubject = () => {
        if (!searchSubject.trim()) return;

        setIsAddingSubject(true);
        addSubject({name: searchSubject})
            .then(res => {
                return getSubjects();
            })
            .then(res => {
                setSubjects(res.data);
                const newSubjectId = res.data.find(subject => subject.name === searchSubject)?.id;
                if (newSubjectId) {
                    setFormData(prevState => ({
                        ...prevState,
                        subjectTerms: [...prevState.subjectTerms, newSubjectId]
                    }));
                }
                setSearchSubject('');
            })
            .catch(error => {
                console.error('Error adding subject:', error);
            })
    };


    return (
        <Dialog open={open} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth
                PaperProps={{style: {borderRadius: 15}}}>
            <DialogTitle
                id="form-dialog-title"
                sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}
            >
                Update Article Metadata
                <IoClose
                    aria-label="close"
                    onClick={onClose}
                    style={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        cursor: 'pointer'
                    }}
                />
            </DialogTitle>
            <Divider/>
            <DialogContent sx={{padding: "0px 24px"}}>

                <MetaData
                    inputContainerStyle={inputContainerStyle}
                    inputStyle={inputStyle}
                    formData={formData}
                    handleInputChange={handleInputChange}
                    setIsFocused={setIsFocused}
                    errors={errors}
                    handleBlur={handleBlur}
                    isEditable={false}
                />
                <SubjectTerms
                    subjects={availableSubjects}
                    setSearchSubject={setSearchSubject}
                    handleAddNewSubject={handleAddNewSubject}
                    isAddingSubject={isAddingSubject}
                    searchSubject={searchSubject}
                    formData={{...formData, subjectTerms: formData.subjectTerms || []}}
                    setFormData={setFormData}
                />

            </DialogContent>
            <Divider/>
            <DialogActions>
                <SuiButton variant="gradient" color="secondary" size="medium" onClick={onClose}>Close</SuiButton>
                <SuiButton variant="gradient" color="primary" size="medium"
                           onClick={handleUpdateMetadata} disabled={existingArticleId}>
                    Save
                </SuiButton>
            </DialogActions>
        </Dialog>
    );
};

EditDigitizeArticleDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    handleDigitizeArticle: PropTypes.func,
    author: PropTypes.string,
    title: PropTypes.string,
    subjects: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
        })
    ),
    publishedAt: PropTypes.string,
    sourceLanguage: PropTypes.string,
    targetLanguage: PropTypes.string,
    digitizationId: PropTypes.number,
    setNotification: PropTypes.func,
    refreshList: PropTypes.func.isRequired,
}
export default EditDigitizeArticleDialog;