import React, {useState} from "react";
import {TextField, Checkbox, Grid, Box, IconButton, Collapse} from "@mui/material";
import SoftTypography from "../../../components/SoftTypography";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import PropTypes from "prop-types";

const TermsFilter = ({
                         title,
                         searchTerm,
                         setSearchTerm,
                         terms,
                         selectedTerms,
                         handleCheckboxChange,
                         termCollapsed,
                         handleTermCollapseToggle,
                         itemKey,
                         placeholder
                     }) => {

    const filteredTerms = terms.filter((term) =>
        term[itemKey].toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center">
                    <SoftTypography
                        variant="h6"
                        mb={0}
                        style={{
                            fontSize: '0.9rem',
                            lineHeight: 'normal'
                        }}
                    >
                        {title}
                        {selectedTerms.length > 0 && <IconButton
                            style={{
                                borderRadius: '50%',
                                backgroundColor: '#f0f0f0',
                                padding: '8px',
                                margin: '5px'
                            }}
                        >
                            <SoftTypography style={{
                                lineHeight: '0.6rem',
                                fontSize: '0.9rem'
                            }}>{selectedTerms.length}</SoftTypography>
                        </IconButton>}

                    </SoftTypography>
                </Box>
                <IconButton
                    onClick={handleTermCollapseToggle}
                >
                    {termCollapsed ? <ExpandMoreIcon/> : <ExpandLessIcon/>}
                </IconButton>
            </Box>

            <Collapse in={!termCollapsed}>
                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder={placeholder}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    sx={{marginBottom: '16px'}}
                />

                <div style={{
                    overflowY: "scroll",
                    height: '200px',
                    padding: '0 20px 0 0',
                    // marginBottom: selectedTerms.length ? '70px' : '20px',
                    marginBottom: '20px',
                    scrollbarWidth: 'thin'
                }}>
                    <Grid container direction="column">
                        {filteredTerms.map((item) => (
                            <Grid
                                key={item[itemKey]}
                                container
                                alignItems="center"
                                justifyContent="space-between"
                                sx={{
                                    padding: "10px 0",
                                    borderBottom: "1px solid #ddd",
                                    display: 'ruby'
                                }}
                            >
                                <Grid item>
                                    <Checkbox
                                        checked={selectedTerms.includes(item[itemKey])}
                                        onChange={() => handleCheckboxChange(item[itemKey])}
                                        color="primary"
                                    />
                                </Grid>

                                <SoftTypography variant="body2" sx={{fontSize: "0.9rem"}}>
                                    {item[itemKey]}
                                </SoftTypography>
                            </Grid>
                        ))}
                    </Grid>
                </div>
            </Collapse>
        </>
    );
};

TermsFilter.propTypes = {
    title: PropTypes.string,
    searchTerm: PropTypes.string,
    setSearchTerm: PropTypes.string,
    terms: PropTypes.array,
    selectedTerms: PropTypes.string,
    handleCheckboxChange: PropTypes.func,
    termCollapsed: PropTypes.bool,
    handleTermCollapseToggle: PropTypes.func,
    itemKey: PropTypes.string,
    placeholder: PropTypes.string
}

export default TermsFilter;
