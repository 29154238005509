import React, { useEffect, useState } from 'react';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import {useSoftUIController} from "../context";
import {setLayout} from "../store/actions/app-actions";

const SwaggerDoc = () => {
    const [controller, dispatch] = useSoftUIController();

    useEffect(() => {
        setLayout(dispatch, "page");
    }, []);

    const apiHost = window?.appConfig?.REACT_APP_API_GATEWAY || process.env.REACT_APP_API_GATEWAY;

    return (
            <SwaggerUI
                url={`${apiHost}/api/swagger`}
            />
    );
};

export default SwaggerDoc;
