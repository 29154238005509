import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Divider } from '@mui/material';
import SuiButton from "../../components/SoftButton";
import { IoClose } from "react-icons/io5";
import {runSelectedServices, getNonExecutedServices} from "../../axios-client";
import "../../mainComponents/departments/assets/departmentsStyleSheet.css";
import PropTypes from "prop-types";
import Services from "./components/Services";

const RunNonExecutedServices = ({open, onClose, id, sourceLanguage, targetLanguage, refreshList, setNotification}) => {
    const [formData, setFormData] = useState({
        services: [],
        audioFormat: '',
        voiceName: ''
    });
    const [errors, setErrors] = useState({});
    const [services, setServices] = useState([]);

    const validateForm = () => {
        let newErrors = {};
        const audioBookService = services.find(service => service.name === 'Text to Speech');
        if(formData.services?.includes(audioBookService?.id)){
            if (!formData.audioFormat) newErrors.audioFormat = 'Audio format is required';
            if (!formData.voiceName) newErrors.voiceName = 'Voice Name is required';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    useEffect(() => {
        if(open){
            getNonExecutedServices(id)
                .then(res => {
                    setServices(res.data);

                    const defaultSelectedServices = res.data.filter(service => (service.pre_selected == true)).map(key => key.id);

                    setFormData(prevState => ({
                        ...prevState,
                        services: defaultSelectedServices
                    }));
                })
                .catch(error => {
                    console.error('Error fetching services:');
                });
        }
    }, [open]);

    const handleService = (e, serviceId) => {

        if (formData.services.includes(serviceId)) {
            setFormData(prevState => ({
                ...prevState,
                services: formData.services.filter(key => key != serviceId)
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                services: [...prevState.services, serviceId]
            }));
        }
    }

    const handleRun =  () => {
        if (validateForm()){
            runSelectedServices(id, formData).then(()=>{
                onClose()
                refreshList()
            }).catch((e)=>{
                onClose()
                setNotification({
                    show: true,
                    snackbarMessage: 'An error occurred. Please try again later.',
                    severity: 'error'
                });
            })
        }
    };

    return (
        <Dialog open={open} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth PaperProps={{ style: { borderRadius: 15 } }}>
            <DialogTitle
                id="form-dialog-title"
                sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
                Run Non-Executed Services
                <IoClose
                    aria-label="close"
                    onClick={onClose}
                    style={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        cursor: 'pointer'
                    }}
                />
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ padding: "0px 24px" }}>

                <Services label={"Services"}
                          setFormData={setFormData}
                          selectedLanguage={sourceLanguage.toLowerCase()}
                          selectedTargetLanguage={targetLanguage.toLowerCase()}
                          services={services} selectedServices={formData.services}
                          handleCheckboxServiceChange={handleService}
                          errors={errors}
                          setErrors={setErrors}
                />

            </DialogContent>
            <Divider />
            <DialogActions>
                <SuiButton variant="gradient" color="secondary" size="medium" onClick={onClose}>Close</SuiButton>
                <SuiButton variant="gradient" color="primary" size="medium" onClick={handleRun}>
                    Run
                </SuiButton>
            </DialogActions>
        </Dialog>
    );
};

RunNonExecutedServices.propTypes = {
    id: PropTypes.number,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    refreshList: PropTypes.func,
    setNotification: PropTypes.func,
    sourceLanguage: PropTypes.string,
    targetLanguage: PropTypes.string,
}
export default RunNonExecutedServices;