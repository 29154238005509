import Tables from "../../reusableComponents/Tables/Table/TableCard";
import React, { useState, useEffect } from 'react';
import {
    activateUsers,
    getFreeAgentsList,

} from "../../axios-client";
import {formatDataToUrlQueryParams} from "../../utlis/generalUtils";
import AvatarComponent from "../../reusableComponents/Avatar/AvatarComponent";
import {useSoftUIController} from "../../context";
import {SUPER_ADMIN_ROLE} from "../../utlis/constant";
import Badge from "../../reusableComponents/Badge/Badge";
import SuiButton from "../../components/SoftButton";
import CircularProgress from '@mui/material/CircularProgress';
import { format } from 'date-fns';

const FreeAgents = (props) => {
    const [controller, dispatch] = useSoftUIController();
    const {role} = controller;
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [freeAgentsCount, setFreeAgentsCount] = useState(0);
    const [notification, setNotification] = useState({show: false, snackbarMessage: '', severity: ""})
    const [isActivating, setIsActivating] = useState(false);
    const [updatedUserIds, setUpdatedUserIds] = useState([]);
    const [filterData, setFilterData] = useState({
        page: 1,
        size: 10,
    });

    useEffect(() => {
            fetchFreeAgents(filterData);
    }, [filterData, role, isActivating]);


    const handlePageChange = (newPage) => {
        setFilterData((prev) => ({ ...prev, page: newPage }));
    };

    const handleRowsPerPageChange = (newSize) => {
        setFilterData((prev) => ({ ...prev, size: newSize, page: 1 }));
    };

    const handleUserStatus = (ids, isActive) => {
        if (ids.length === 0) {
            setNotification({
                show: true,
                snackbarMessage: 'No inactive free-agents to activate!',
                severity: 'info',
            });
            return;
        }
        setIsActivating(true)
        const userIds = Array.isArray(ids) ? ids.join(',') : ids;
        const payload = { is_active: isActive };
        setUpdatedUserIds(userIds)
        activateUsers(userIds, payload)
            .then(() => {
                setIsActivating(false)
                setNotification({
                    show: true,
                    snackbarMessage: 'Free-agent activated successfully!',
                    severity: "success"
                });
            })
            .catch(err => {
                setIsActivating(false)
                console.error('Error activating user:', err);
                setNotification({
                    show: true,
                    snackbarMessage: 'Failed to activate free-agent. Please try again!',
                    severity: "error"
                })
            });
    }

    const fetchFreeAgents = () => {
        setLoading(true);
        const queryParams = formatDataToUrlQueryParams(filterData);
        if (role === SUPER_ADMIN_ROLE) {
            getFreeAgentsList(`?${queryParams}`)
                .then(res => {
                    const formattedRows = res.data.free_agents.map(freeAgent => {
                        const badgeColor = freeAgent.is_active ? "success" : "secondary";
                        const badgeContent = freeAgent.is_active ? "Active" : "Inactive";
                        const variant = "gradient";
                        return {
                            "Name": (
                                <AvatarComponent
                                    name={freeAgent.name}
                                    image={freeAgent.profile_picture ?? freeAgent.name}
                                    bgColor={"secondary"}
                                    variant={"rounded"}
                                />
                            ),
                            "Email": freeAgent.email,
                            "Registred date": format(new Date(freeAgent.registration_date), 'MMM dd, yyyy hh:mm a'),
                            "Status": (
                                <Badge
                                    color={badgeColor}
                                    badgeContent={badgeContent}
                                    variant={variant}
                                />
                            ),
                            "Action": (
                                <SuiButton variant="outlined" color="primary" size="medium" onClick={() => handleUserStatus([freeAgent.id], !freeAgent.is_active)}>
                                    {isActivating && updatedUserIds.includes(freeAgent.id) ? 'Processing...' : freeAgent.is_active ? "Desactivate" : "Activate"}
                                    {isActivating && updatedUserIds.includes(freeAgent.id) &&  <CircularProgress size={20} style={{ marginLeft: 10, color: "white" }} />}
                                </SuiButton>
                            ),
                        };
                    });

                    setFreeAgentsCount(res.data.count);
                    setRows(formattedRows);
                    setLoading(false);
                });
        }
    };

    return (
        <div>
            <Tables
                rows={rows}
                title={"Free Agents"}
                showAddButton={false}
                loadingData={loading}
                size={filterData.size}
                page={filterData.page}
                handlePageChange={handlePageChange}
                handleRowsPerPageChange={handleRowsPerPageChange}
                count={freeAgentsCount}
            />
        </div>
    );
};

export default FreeAgents;
