import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  Select,
  FormControlLabel,
  Checkbox,
  Typography,
  Divider
} from '@mui/material';
import SuiButton from "../../components/SoftButton";
import { IoClose } from "react-icons/io5";
import { FaUniversity, FaCity, FaGlobeAmericas } from "react-icons/fa";
import { MdLocationOn, MdLocalPhone, MdEmail, MdWeb, MdCloudUpload } from "react-icons/md";
import { IoMdSchool } from "react-icons/io";
import PropTypes from 'prop-types';
import "./assets/universitiesStyleSheet.css";

const AddUniversityDialog = ({ open, onClose, handleAddUniversity }) => {
  const [formData, setFormData] = useState({
    name: '',
    address: '',
    email: '',
    logo_url: '',
    university_type: '',
    description: '',
    phone: '',
    website_url: ''
  });
  const [errors, setErrors] = useState({});
  const [logoPreview, setLogoPreview] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const inputContainerStyle = (field) => ({
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${errors[field] ? '#f44336' : isFocused ? '#cdcecf' : '#dcdfe3'}`,
    borderRadius: '8px',
    padding: '5px 10px',
    marginBottom: '10px',
    transition: 'border-color 0.3s ease',
  });

  const inputStyle = {
    width: '100%',
    padding: '10px 0px',
    border: 'none',
    fontSize: '14px',
    boxSizing: 'border-box',
    outline: 'none',
    fontFamily: 'Arial, Roboto',
  };

  const iconStyle = {
    marginRight: '8px',
    color: '#888'
  };

  const errorTextStyle = {
    color: '#f44336',
    fontSize: '12px',
    marginTop: '4px',
    marginLeft: '35px' 
  };

  const handleSelectChange = (event) => {
    setFormData({
      ...formData,
      university_type: event.target.value,
    });
    setErrors({ ...errors, university_type: '' });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setErrors({ ...errors, [name]: '' });
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFormData({ ...formData, logo_url: file });
      setLogoPreview(URL.createObjectURL(file));
    }
  };

  const handleCheckboxChange = (event) => {
    setFormData({ ...formData, is_active: event.target.checked });
  };

  const validateForm = () => {
    let newErrors = {};
    if (!formData.name.trim()) newErrors.name = 'University name is required.';
    if (!formData.email.trim()) {
      newErrors.email = 'Email is required.';
    } else {
      // Basic email format validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.email)) {
        newErrors.email = 'Enter a valid email address.';
      }
    }
    return newErrors;
  };

  const handleSave = () => {
    const newErrors = validateForm();
    if (Object.keys(newErrors).length === 0) {
        const formDataToSend = new FormData();

        // Append each field to FormData, including the file
        Object.keys(formData).forEach((key) => {
            formDataToSend.append(key, formData[key]);
        });

        // Handle the logo file
        if (formData.logo_url) {
            formDataToSend.append('logo_url', formData.logo_url);
        }

        // Call the parent handler with FormData
        handleAddUniversity(formDataToSend);
    } else {
        setErrors(newErrors);
        console.log("Form has errors, not submitting");
    }
};


  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        Add a New University
        <IoClose
          aria-label="close"
          onClick={onClose}
          style={{ position: 'absolute', right: 8, top: 8, cursor: 'pointer', fontSize: '1.5em' }}
        />
      </DialogTitle>
      <Divider/>
      <DialogContent>
        {/* University Name */}
        <div style={inputContainerStyle('name')}>
          <FaUniversity style={iconStyle} />
          <input
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            placeholder="University Name"
            style={inputStyle}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
        </div>
        {errors.name && <Typography style={errorTextStyle}>{errors.name}</Typography>}

        {/* Email */}
        <div style={inputContainerStyle('email')}>
          <MdEmail style={iconStyle} />
          <input
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            placeholder="Email"
            style={inputStyle}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
        </div>
        {errors.email && <Typography style={errorTextStyle}>{errors.email}</Typography>}

        {/* Phone */}
        <div style={inputContainerStyle('phone')}>
          <MdLocalPhone style={iconStyle} />
          <input
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
            placeholder="Phone"
            style={inputStyle}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
        </div>
        {errors.phone && <Typography style={errorTextStyle}>{errors.phone}</Typography>}

        {/* Logo Upload */}
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            style={{ display: 'none' }}
            id="upload-logo"
          />
          <label htmlFor="upload-logo" style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
            <MdCloudUpload size={48} />
            <Typography variant="body2" style={{ marginLeft: '8px' }}>Upload Logo</Typography>
          </label>
          {logoPreview && (
            <img
              src={logoPreview}
              alt="University Logo"
              style={{ height: '50px', width: '50px', borderRadius: '50%', marginLeft: '15px' }}
            />
          )}
        </div>

        {/* Address */}
        <div style={inputContainerStyle('address')}>
          <MdLocationOn style={iconStyle} />
          <input
            name="address"
            value={formData.address}
            onChange={handleInputChange}
            placeholder="address"
            style={inputStyle}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
        </div>
        {errors.address && <Typography style={errorTextStyle}>{errors.address}</Typography>}

        {/* Website URL */}
        <div style={inputContainerStyle('website_url')}>
          <MdWeb style={iconStyle} />
          <input
            name="website_url"
            value={formData.website_url}
            onChange={handleInputChange}
            placeholder="Website URL"
            style={inputStyle}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
        </div>
        {errors.website_url && <Typography style={errorTextStyle}>{errors.website_url}</Typography>}

        {/* University Type */}
        <div style={inputContainerStyle('university_type')}>
          <IoMdSchool style={iconStyle} />
          <Select
            id="universityType"
            displayEmpty
            name="university_type"
            fullWidth
            value={formData.university_type}
            onChange={handleSelectChange}
            style={{ ...inputStyle, padding: '10px 0px' }}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            renderValue={(selected) => {
              if (selected === "") {
                return <span style={{ color: '#888' }}>Select a university type</span>;
              }
              return selected;
            }}
          >
            <MenuItem value="" disabled>Select a university type</MenuItem>
            <MenuItem value="Private">Private</MenuItem>
            <MenuItem value="Public">Public</MenuItem>
          </Select>
        </div>
        {errors.university_type && <Typography style={errorTextStyle}>{errors.university_type}</Typography>}

        {/* Description */}
        <TextField
          fullWidth
          name="description"
          value={formData.description}
          onChange={handleInputChange}
          placeholder="Description"
          multiline
          rows={4}
          variant="outlined"
          margin="normal"
        />
        {errors.description && <Typography style={errorTextStyle}>{errors.description}</Typography>}
      </DialogContent>
      <DialogActions>
        <SuiButton onClick={onClose} color="secondary">Cancel</SuiButton>
        <SuiButton onClick={handleSave} color="primary">Save</SuiButton>
      </DialogActions>
    </Dialog>
  );
};

AddUniversityDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleAddUniversity: PropTypes.func.isRequired
};

export default AddUniversityDialog;
