import {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import Switch from "@mui/material/Switch";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import signUpBgImage from "assets/images/curved-images/login.png";
import logo from "assets/images/logo.png";
import {login} from "../../../axios-client";
import {useSoftUIController} from "../../../context";
import {setToken, setUserInfo} from "../../../store/actions/app-actions";
import Alert from "@mui/material/Alert";
import CheckIcon from '@mui/icons-material/Check';
import {styled} from '@mui/material/styles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUser, faUserShield} from '@fortawesome/free-solid-svg-icons';
import {FREE_AGENT} from "../../../utlis/constant";
import Card from "@mui/material/Card";

const RoleButton = styled(SoftButton)(({selected}) => ({
    border: selected ? '2px solid #cb0c9f' : '2px solid #3a416ff2',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '75px',
    height: '90px',
    color: selected ? '#cb0c9f' : '#3a416ff2',
    fontSize: '10px',
    '& .check-icon': {
        display: selected ? 'block' : 'none',
        position: 'absolute',
        color: 'white',
        left: '-5px',
        top: '0px',
        transform: 'translateY(-60%)',
        backgroundColor: '#cb0c9f',
        borderRadius: '50%',
    },
}));

function SignIn() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [rememberMe, setRememberMe] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const [role, setRole] = useState("free-agent");
    const [controller, dispatch] = useSoftUIController();
    const navigate = useNavigate();
    const handleSetRememberMe = () => setRememberMe(!rememberMe);

    useEffect(() => {
        setErrorMessage(null);
    }, [email, password]);

    const handleLogin = (event) => {
        event.preventDefault();
        login(email, password, FREE_AGENT)
            .then((response) => {
                setUserInfo(dispatch, response?.data);
                setToken(dispatch, response.data?.access_token);
                localStorage.setItem("token", response.data?.access_token);
                navigate('/documents');
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.message) {
                    setErrorMessage(error.response.data.message);
                } else {
                    setErrorMessage('An error occurred. Please try again later.');
                }
            });
    };

    const handleRoleSelect = (selectedRole) => {
        setRole(selectedRole);
    };
    return (
        <CoverLayout
            title="Welcome back"
            image={signUpBgImage}
            logo={logo}
        >
            {errorMessage && <Alert severity="error" onClose={() => setErrorMessage(null)}>{errorMessage}</Alert>}
            <Card sx={{boxShadow:10}}>
                <SoftBox p={3} mb={1} >
                    <SoftBox component="form" role="form" onSubmit={handleLogin}>
                        <SoftBox mb={2}>
                            <SoftBox mb={1} ml={0.5}>
                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                    Email
                                </SoftTypography>
                            </SoftBox>
                            <SoftInput
                                type="email"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </SoftBox>

                        <SoftBox mb={2}>
                            <SoftBox mb={1} ml={0.5}>
                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                    Password
                                </SoftTypography>
                            </SoftBox>
                            <SoftInput
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </SoftBox>

                        <SoftBox display="flex" alignItems="center" justifyContent="space-between">
                            <SoftBox display="flex" alignItems="center">
                                <Switch checked={rememberMe} onChange={handleSetRememberMe}/>
                                <SoftTypography
                                    variant="button"
                                    fontWeight="regular"
                                    onClick={handleSetRememberMe}
                                    sx={{cursor: "pointer", userSelect: "none"}}
                                >
                                    &nbsp;&nbsp;Remember me
                                </SoftTypography>
                            </SoftBox>
                            {/* Forget password link */}
                            <SoftTypography
                                variant="button"
                                fontWeight="regular"
                                to="/authentication/reset-password"
                                onClick={() => navigate("/authentication/reset-password")}
                                sx={{cursor: "pointer", userSelect: "none", color: "primary.main"}}
                            >
                                Forget password?
                            </SoftTypography>
                        </SoftBox>

                        <SoftBox mt={4} mb={1}>
                            <SoftButton variant="gradient" color="primary" fullWidth type="submit">
                                Sign in
                            </SoftButton>
                        </SoftBox>

                        <SoftBox mt={3} textAlign="center">
                            <SoftTypography variant="button" color="text" fontWeight="regular">
                                Don&apos;t have an account?{" "}
                                <SoftTypography
                                    component={Link}
                                    to="/authentication/sign-up"
                                    variant="button"
                                    color="primary"
                                    fontWeight="medium"
                                    textGradient
                                >
                                    Sign up
                                </SoftTypography>
                            </SoftTypography>
                        </SoftBox>
                    </SoftBox>
                </SoftBox>
            </Card>
        </CoverLayout>
    );
}

export default SignIn;
