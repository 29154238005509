import {useState, useEffect} from 'react'
import DigitizationContentComponent from "./editorDigitizationContent/DigitizationContentComponent";
import "../editorStyleSheet.css"
import BodyComponents from "./BodyComponents";
import PropTypes from "prop-types";

const EditorBodyComponent = ({
                                 handleFullScreen,
                                 isFullScreen,
                                 mediaPath,
                                 containerWidth,
                                 scale,
                                 setPageNumber,
                                 setNumPages,
                                 forcePageNumber,
                                 setScale,
                                 numPages,
                                 pageNumber,
                                 setForcePageNumber,
                                 configWidth,
                                 updateConfigWidth,
                                 pages,
                                 sourceLanguage,
                                 targetLanguage,
                                 articleId,
                                 setCloseForcePageNumber,
                                 setNotification,
                                 setPages
                             }) => {
    const [updatedText, setUpdateText] = useState([])

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (updatedText.length > 0) {
                const message = "You have unsaved changes, are you sure you want to leave?";
                e.returnValue = message;
                return message;
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [updatedText]);

    const addOrUpdateText = (num, newText, field) => {
        setUpdateText(prevTexts => {
            const existingText = prevTexts.find(item => item.num === num);

            if (existingText) {
                return prevTexts.map(item =>
                    item.num === num ? { ...item, [field]: newText } : item
                );
            } else {
                return [...prevTexts, { num, [field]: newText }];
            }
        });

        setPages(prevTexts => {
            const existingText = prevTexts.find(item => item.num === num);

            if (existingText) {
                return prevTexts.map(item =>
                    item.num === num ? { ...item, [field]: newText } : item
                );
            }
        });
    };

    return (
        <div className="digitizationEditorBody">
            <div className="body-component">
                <BodyComponents
                    mediaPath={mediaPath}   numPages={numPages}
                    setNumPages={setNumPages}  forcePageNumber={forcePageNumber}  scale={scale}
                    setScale={setScale}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    setForcePageNumber={setForcePageNumber}
                    containerWidth={containerWidth}
                    configWidth={configWidth}
                    setCloseForcePageNumber={setCloseForcePageNumber}
                    handleFullScreen={handleFullScreen}
                    isFullScreen={isFullScreen}
                    setNotification={setNotification}
                    articleId={articleId}
                    updatedText={updatedText}
                    setUpdateText={setUpdateText}
                />

                <DigitizationContentComponent
                    pageNumber={pageNumber}
                    configWidth={configWidth}
                    containerWidth={containerWidth}
                    updateConfigWidth={updateConfigWidth}
                    pages={pages}
                    sourceLanguage={sourceLanguage}
                    targetLanguage={targetLanguage}
                    addOrUpdateText={addOrUpdateText}
                />
            </div>
        </div>
    )

}


EditorBodyComponent.propTypes = {
    mediaPath: PropTypes.string,
    containerWidth: PropTypes.string,
    configWidth: PropTypes.string,
    numPages: PropTypes.number,
    pageNumber: PropTypes.number,
    scale: PropTypes.number,
    articleId: PropTypes.number,
    setPageNumber: PropTypes.func,
    forcePageNumber: PropTypes.number,
    setNumPages: PropTypes.func,
    setScale: PropTypes.func,
    setForcePageNumber: PropTypes.func,
    setCloseForcePageNumber: PropTypes.func,
    updateConfigWidth: PropTypes.func,
    sourceLanguage: PropTypes.string,
    targetLanguage: PropTypes.string,
    pages: PropTypes.array,
    setPages: PropTypes.func,
    handleFullScreen: PropTypes.func,
    setNotification: PropTypes.func,
    isFullScreen: PropTypes.bool
};
export default EditorBodyComponent;