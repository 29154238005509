import React, {useEffect, useState} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from "@mui/material/CardMedia";
import SoftBox from "../../../components/SoftBox";
import SoftTypography from "../../../components/SoftTypography";
import { CiBookmark } from "react-icons/ci";
import { TbDeviceTabletSearch } from "react-icons/tb";
import { PiMagnifyingGlassPlusFill } from "react-icons/pi";
import { FaFileAudio, FaPlay, FaCogs } from 'react-icons/fa';
import { TbTextScan2 } from "react-icons/tb";
import { BsCash } from "react-icons/bs";
import defaultMiniature from "assets/images/curved-images/digitization.png";
import PropTypes from "prop-types";
import SoftButton from "../../../components/SoftButton";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ReactReadMoreReadLess from "react-read-more-read-less";
import { MdDelete, MdEdit, MdRefresh, MdMoreVert } from "react-icons/md"
import DeleteArticleDialog from "../../../layouts/publications/DeleteArticleDialog";
import {useSoftUIController} from "../../../context";
import {PayPalScriptProvider, PayPalButtons, FUNDING } from "@paypal/react-paypal-js";
import Highlighter from "react-highlight-words";
import {io} from 'socket.io-client';
import {LIBRARY_ADMIN_ROLE, SUPER_ADMIN_ROLE, FREE_AGENT} from "../../../utlis/constant";
import { format } from 'date-fns';
import {captureOrder, createOrder, downloadAudio, rerunDigitizer, rerunService, skipService} from "../../../axios-client";
import {getStatusIcon, getTitleWithDate} from "../../../utlis/generalUtils";
import StepperStatusComponent from "../../../components/Steper/StepperStatusComponent";
import EditDigitizedArticleDialog from "../../../layouts/publications/EditDigitizedArticleDialog";
import {openTheEditor} from "../../../mainComponents/payment/helper";
import api from "assets/images/apiRed.png";
import RunNonExecutedServices from "../../../layouts/publications/RunNonExecutedServices";
import CreditCardPaymentButton from "../../payment/CreditCardPaymentButton";
import CreditsPaymentButton from "../../payment/CreditsPaymentButton";
import { MdStarRate } from "react-icons/md";
import FeedbackDialog from "../../Feedback/FeedbackDialog";
import { FaRegThumbsUp } from "react-icons/fa";
import {LuCalendarClock, LuFileSearch} from "react-icons/lu";
import { useMediaQuery, useTheme, Menu, MenuItem } from '@mui/material';
const CardItem = ({
                      id,
                      title,
                      image,
                      author,
                      sourceLanguage,
                      targetLanguage,
                      publishedAt,
                      summary,
                      services_data,
                      createdAt,
                      status,
                      origin,
                      handleClickDownloadDigitizedDocument,
                      handleOpenPreview,
                      downloadBookmarkingFile,
                      downloadSearchableFile,
                      bookmarkingPath,
                      subjects,
                      setNotification,
                      articleId,
                      cost,
                      userCreditBalance,
                      searchText,
                      refreshList,
                      paymentStatus,
                      freeAgentDocuments = false,
                      digitizerId,
                      setEventNameList,
                      updateAudioToDownloadList,
                      has_audio_option,
                      all_services_selected,
                      feedback_rating
                  }) => {
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [controller, dispatch] = useSoftUIController();

    const [showFeedback, setShowFeedback] = useState(false)

    const updated_services_data =  services_data?.map((service) => {
        if (service.service_name === "OCR" && service.service_status === "PENDING") {
            return { ...service, service_status: "IN_PROGRESS" };
        }
        return service;

    });

    const stripePublicKey = window?.appConfig?.REACT_APP_STRIPE_PUBLIC_KEY || process.env.REACT_APP_STRIPE_PUBLIC_KEY;
    const activeCreditCard = stripePublicKey != "";
    const isMarkdownSelected = services_data?.filter(service => service.service_name === "Markdown").length > 0;

    const [stepsData, setStepsData] = useState(updated_services_data);
    const [digitizationFailed, setDigitizationFailed] = useState(status === "ERROR" ? true : false);
    const [rerunDigitization, setRerunDigitization] = useState(false);
    const [isMetadataDialogOpen, setMetadataDialogOpen] = useState(false);
    const [openRunNonExecutedServicesDialog, setOpenRunNonExecutedServicesDialog] = useState(false);
    const [hasSufficientCredits, setHasSufficientCredits] = useState(false);

    useEffect(() => {
        if (userCreditBalance > 0 && userCreditBalance >= cost) {
            setHasSufficientCredits(true);
        } else {
            setHasSufficientCredits(false);
        }
    }, [userCreditBalance, cost]);

    useEffect(() => {

        if((status == "COMPLETE" && stepsData?.some(service => service.service_status === 'ERROR' || service.service_status === 'IN_PROGRESS')) ||
            (status != "COMPLETE" && status != "ERROR" && status != "PENDING") || (rerunDigitization === true )){
            const baseURL = window?.appConfig?.REACT_APP_SOCKET_SERVER || process.env.REACT_APP_SOCKET_SERVER
            const socket = io(baseURL, {
                path: "/socket.io"
            });
            const eventName = `digitization_status_update-${id}`;
            socket.on(eventName, (res) => {
                setStepsData(res.data)
                if (res.status === 'COMPLETE') {
                    socket.off(`digitization_status_update-${id}`);
                    setRerunDigitization(false)
                    refreshList()
                } else if (res.status === 'ERROR') {
                    setDigitizationFailed(true)
                    setRerunDigitization(false)
                    refreshList()
                }
            });

            return () => {
                socket.off(`digitization_status_update-${id}`);
            };
        }
    }, [rerunDigitization]);

    const downloadArticleAudio = async () => {
        let documentTitle = getTitleWithDate(title)
        await setEventNameList((prevEventNames) => [
            ...prevEventNames,
            `document_to_download_${documentTitle}`
        ]);
        downloadAudio(articleId, documentTitle).then((res) => {
            updateAudioToDownloadList(documentTitle)
        }).catch((error) => {
        })
    };

    const handleOpenMetadataDialog = () => {
        setMetadataDialogOpen(true);
    };

    const handleCloseMetadataDialog = () => {
        setMetadataDialogOpen(false);
    };

    const handleRerunDigitization = () => {
        setDigitizationFailed(false)
        setRerunDigitization(true)
        rerunDigitizer(id).then((res) => {
            setNotification({ show: true, snackbarMessage: 'Digitization rerun successfully!', severity: "success" });
        }).catch((error) => {
            setNotification({ show: true, snackbarMessage: 'An error occurred. Please try again!', severity: "error" });
        })
    };

    const handleOpenDeleteDialog = () => {
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    const formattedCreatedAt = createdAt ? format(new Date(createdAt), 'MMM dd, yyyy hh:mm a') : '';



    const handleRerunService = (serviceName) => {
        rerunService(id, serviceName)
            .then(() => {})
            .catch(e => {
                setNotification({ show: true, snackbarMessage: 'An error occurred. Please try again!', severity: "error" });
        })
    }

    const handleSkipService = (serviceName) => {
        skipService(id, serviceName)
            .then((res) => {
                setStepsData(res.data.services)
            })
            .catch(e => {
                setNotification({ show: true, snackbarMessage: 'An error occurred. Please try again!', severity: "error" });
            })
    }

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [actionMenuAnchor, setActionMenuAnchor] = useState(null);
    const handleActionMenuOpen = (event) => setActionMenuAnchor(event.currentTarget);
    const handleActionMenuClose = () => setActionMenuAnchor(null);


    return (
        <>
        <Card sx={{ borderRadius: '0rem', border: '1px'}}>
            <SoftBox sx={{ margin: '5px', display: 'flex', justifyContent: 'center', width: '100%', alignItems: 'center' }}>
                {((status == "COMPLETE" && stepsData.some(service => service.service_status === 'ERROR' || service.service_status === 'IN_PROGRESS')) ||
                        (status != "COMPLETE" && status != "ERROR" && status != "PENDING") || (rerunDigitization === true )) && origin == "Internal" &&
                    <StepperStatusComponent stepsData={stepsData} handleRerunService={handleRerunService} handleSkipService={handleSkipService}/>}
            </SoftBox>

            <Card sx={{ display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row', border: '1px'}}>

                <SoftBox sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start' ,
                }}>
                    <div style={{position: "relative", display: "inline-block"}}>
                        <CardMedia
                            component="img"
                            sx={{ width: isSmallScreen ? 60 : 80, height: isSmallScreen ? 75 : 100, borderRadius: "0px" }}
                            image={image ?? defaultMiniature}
                            alt="digitized document"
                        />
                        {origin === "External" && (<div
                            style={{
                                position: "absolute", top: 0, right: 0, padding: "0.25rem", borderRadius: "50%",
                            }}
                        >
                            <img src={api} alt="API" style={{maxWidth: "1.5rem"}}/>
                        </div>)}
                        {feedback_rating !== null && feedback_rating !== undefined &&
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "0.5rem",
                                paddingTop: "10px"
                            }}>
                                <div style={{display: "flex", justifyContent: "center"}}>
                                    {Array(feedback_rating).fill().map((_, index) => (
                                        <MdStarRate key={index} className="h-5 w-5" style={{color: "#FFD700"}}/>
                                    ))}
                                </div>
                            </div>
                        }

                    </div>


                    <SoftButton
                        size={"small"}
                        variant="text"
                        style={{textTransform: "none"}}
                        onClick={() => {
                            handleOpenPreview()
                        }}
                        color={"info"}
                        disabled={status != "COMPLETE"}
                    >
                        <PiMagnifyingGlassPlusFill/>
                        More info
                    </SoftButton>

            </SoftBox>


                <SoftBox sx={{display: 'flex', flexDirection: 'column', width: '100%',  minWidth: isSmallScreen ? '100%' : '40%' }}>
                    <CardContent sx={{flex: '1 0 auto', padding: isSmallScreen ? '8px' : '16px' }}>
                        <SoftTypography component="div" variant={isSmallScreen ? "subtitle1" : "h6"}>

                            <SoftBox
                                style={{display: "flex", flexDirection: "row", alignItems: "center", gap: "0.5rem", justifyContent: "space-between"}}>

                                <SoftBox style={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent: "space-between", width: "100%"}}>

                                    <div style={{display: "flex", width: "100%", paddingRight: isSmallScreen ? "5px" : "20px"}}>

                                        {!isMarkdownSelected ?
                                            <Tooltip title="Open editor in a new tab">
                                                <span>
                                                    <a
                                                        style={{
                                                            display: "flex",
                                                            paddingRight: "10px",
                                                            maxWidth: "400px",
                                                            cursor: "pointer"
                                                        }}
                                                        target="_blank"
                                                        onClick={() => openTheEditor(id)}
                                                    >
                                                      <span style={{ maxWidth: isSmallScreen ? '180px' : '320px' }}>

                                                          <Highlighter
                                                              searchWords={[searchText]}
                                                              textToHighlight={title.length > 60 ? `${title.substring(0, 60)}...` : title}
                                                          />
                                                      </span>
                                                    </a>
                                                </span>
                                            </Tooltip>
                                            :

                                            <span>
                                                <span style={{ maxWidth: isSmallScreen ? '180px' : '300px' }}>

                                                      <Highlighter
                                                          searchWords={[searchText]}
                                                          textToHighlight={title.length > 60 ? `${title.substring(0, 60)}...` : title}
                                                      />
                                                </span>
                                            </span>
                                        }


                                        <div style={{height: "20px"}}>
                                            {status === "ERROR" && !rerunDigitization &&
                                                getStatusIcon('FAILED')
                                            }
                                        </div>

                                    </div>



                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        {isSmallScreen ? (
                                            // Action menu dropdown for small screens
                                            <div>
                                                <Tooltip title="Actions">
                                                    <IconButton
                                                        aria-label="Actions"
                                                        color="info"
                                                        onClick={handleActionMenuOpen}
                                                        size="small"
                                                    >
                                                        <MdMoreVert />
                                                    </IconButton>
                                                </Tooltip>

                                                <Menu
                                                    anchorEl={actionMenuAnchor}
                                                    open={Boolean(actionMenuAnchor)}
                                                    onClose={handleActionMenuClose}
                                                >
                                                    {(!freeAgentDocuments && controller.role === SUPER_ADMIN_ROLE ||
                                                        controller.role === LIBRARY_ADMIN_ROLE) ||
                                                    controller.profileData.id === digitizerId ? (
                                                        <MenuItem   onClick={() => {
                                                            handleOpenMetadataDialog();
                                                            handleActionMenuClose();
                                                        }} >
                                                            <MdEdit style={{ marginRight: '8px' }} />
                                                            Edit

                                                        </MenuItem> ): null}


                                                    {(controller.role === SUPER_ADMIN_ROLE || controller.role === LIBRARY_ADMIN_ROLE) && (

                                                        <MenuItem
                                                            onClick={() => {
                                                                handleOpenDeleteDialog();
                                                                handleActionMenuClose();
                                                            }}
                                                        >
                                                            <MdDelete style={{ marginRight: '8px' }} />
                                                            Delete article
                                                        </MenuItem>
                                                    )}


                                                    { status == "ERROR" && !rerunDigitization && (

                                                        <MenuItem
                                                            onClick={() => {
                                                                handleRerunDigitization();
                                                                handleActionMenuClose();
                                                            }}
                                                        >
                                                            <MdRefresh style={{ marginRight: '8px' }} />
                                                            Rerun digitization
                                                        </MenuItem>

                                                    )}

                                                    {bookmarkingPath && (
                                                        <MenuItem
                                                            onClick={() => {
                                                                downloadBookmarkingFile();
                                                                handleActionMenuClose();
                                                            }}
                                                            disabled={status !== "COMPLETE"}
                                                        >
                                                            <CiBookmark style={{ marginRight: '8px' }} />
                                                            Download Bookmarking
                                                        </MenuItem>
                                                    )}

                                                    <MenuItem
                                                        onClick={() => {
                                                            handleClickDownloadDigitizedDocument();
                                                            handleActionMenuClose();
                                                        }}
                                                        disabled={status !== "COMPLETE"}
                                                    >
                                                        <TbTextScan2 style={{ marginRight: '8px' }} />
                                                        Download digitized article
                                                    </MenuItem>

                                                    {has_audio_option && (
                                                        <MenuItem
                                                            onClick={() => {
                                                                downloadArticleAudio();
                                                                handleActionMenuClose();
                                                            }}
                                                            disabled={status !== "COMPLETE"}
                                                        >
                                                            <FaFileAudio style={{ marginRight: '8px' }} />
                                                            Download the audio
                                                        </MenuItem>
                                                    )}

                                                    {!all_services_selected && controller.role !== FREE_AGENT && (
                                                        <MenuItem
                                                            onClick={() => {
                                                                setOpenRunNonExecutedServicesDialog(true);
                                                                handleActionMenuClose();
                                                            }}
                                                            disabled={!isRunNonExecutedServicesButtonEnabled}
                                                        >
                                                            <FaCogs style={{ marginRight: '8px' }} />
                                                            Run Non-Executed Services
                                                        </MenuItem>
                                                    )}
                                                </Menu>
                                            </div>
                                        ) : (


                                    <div style={{display: "flex"}}>
                                        {(!freeAgentDocuments && controller.role === SUPER_ADMIN_ROLE ||
                                            controller.role === LIBRARY_ADMIN_ROLE) ||
                                        controller.profileData.id === digitizerId ? (
                                            <Tooltip title="Edit">
                                                <IconButton onClick={handleOpenMetadataDialog} color="info">
                                                    <MdEdit />
                                                </IconButton>
                                            </Tooltip>
                                        ) : null}
                                        {(controller.role === SUPER_ADMIN_ROLE || controller.role === LIBRARY_ADMIN_ROLE) && (
                                            <Tooltip title="Delete article">
                                                <IconButton aria-label="Delete article" color="info" onClick={handleOpenDeleteDialog}>
                                                    <MdDelete/>
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                        { status == "ERROR" && !rerunDigitization && (
                                            <Tooltip title="Rerun digitization">
                                                <IconButton aria-label="Rerun digitization" color="info" onClick={handleRerunDigitization}>
                                                    <MdRefresh/>
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                        {bookmarkingPath && <Tooltip title="Download Bookmarking" arrow>
                                            <IconButton aria-label="Download Bookmarking" disabled={status != "COMPLETE"} color="info"
                                                        onClick={downloadBookmarkingFile}>
                                                <CiBookmark/>
                                            </IconButton>
                                        </Tooltip>}
                                        {!bookmarkingPath && (status == "COMPLETE" || status == "ERROR") && <Tooltip title="Download Searchable PDF" arrow>
                                            <IconButton aria-label="Searchable PDF" disabled={status == "ERROR"} color="info"
                                                        onClick={downloadSearchableFile}>
                                                <LuFileSearch/>
                                            </IconButton>
                                        </Tooltip>}
                                        <Tooltip title="Download digitized article">
                                            <IconButton aria-label="Download digitized article" disabled={status != "COMPLETE"} color="info"
                                                        onClick={() => handleClickDownloadDigitizedDocument()}>
                                                <TbTextScan2/>
                                            </IconButton>
                                        </Tooltip>
                                        {has_audio_option && <Tooltip title="Download the audio">
                                            <IconButton aria-label="Download the audio" disabled={status != "COMPLETE"} color="info"
                                                        onClick={() => downloadArticleAudio()}>
                                                <FaFileAudio/>
                                            </IconButton>
                                        </Tooltip>}

                                        {/*{!all_services_selected && controller.role !== FREE_AGENT && <Tooltip title="Run Non-Executed Services">*/}
                                        {/*    <IconButton aria-label="Run Non-Executed Services" color="info"*/}
                                        {/*                onClick={() => {*/}
                                        {/*                    setOpenRunNonExecutedServicesDialog(true)*/}
                                        {/*                }}>*/}
                                        {/*        <FaCogs/>*/}
                                        {/*    </IconButton>*/}
                                        {/*</Tooltip>}*/}
                                    </div>
                                        )}
                                    </div>
                                    {openDeleteDialog && (
                                        <DeleteArticleDialog isModalOpen={openDeleteDialog} handleCloseModal={handleCloseDeleteDialog}
                                                             articleId={articleId} articleTitle={title} refreshArticles={refreshList}
                                                             setNotification={setNotification}/>
                                    )}

                                </SoftBox>

                            </SoftBox>
                        </SoftTypography>

                        <div style={{display:"flex", flexDirection:"row", gap:"5px"}}>
                            <LuCalendarClock fontWeight={"light"} style={{ fontSize: "0.8rem" }}/>
                            <SoftTypography fontWeight={"light"} style={{ fontSize: "0.8rem", fontWeight:"400" }}>
                                {formattedCreatedAt}
                            </SoftTypography>
                        </div>

                        <SoftTypography component="div" sx={{paddingTop: isSmallScreen ? "5px" : "10px", paddingLeft: isSmallScreen ? "5px" : "10px", width: isSmallScreen ? '95%' : '85%' }}>
                            <SoftTypography component="div" variant="body2" sx={{fontSize: "0.9rem"}}>
                                <span> <strong>Author: </strong></span>
                                {
                                    author && <Highlighter
                                        searchWords={[searchText]}
                                        textToHighlight={author}
                                    />
                                }
                            </SoftTypography>


                            <SoftTypography component="div" variant="body2" sx={{fontSize: "0.9rem"}}>
                                <span><strong>Published: </strong></span>
                                {
                                    publishedAt && <Highlighter
                                        searchWords={[searchText]}
                                        textToHighlight={publishedAt}
                                    />
                                }
                            </SoftTypography>


                            <SoftTypography component="div" variant="body2" sx={{fontSize: "0.9rem"}}>
                                <span><strong>Summary: </strong></span>
                                {summary && (
                                    <ReactReadMoreReadLess
                                        charLimit={isSmallScreen ? 200 : 400}
                                        readMoreText={"Read more ▼"}
                                        readLessText={"Read less ▲"}
                                    >
                                        {summary}
                                    </ReactReadMoreReadLess>
                                )}
                            </SoftTypography>

                            {subjects && subjects.length > 0 && (
                                <SoftTypography component="div" variant="body2"
                                                sx={{fontSize: "0.7rem", paddingTop: isSmallScreen ? "4px" : "8px", display: 'flex', flexWrap: 'wrap'}}>
                                    {subjects.map((subject, index) => (
                                        <SoftBox
                                            key={index}
                                            bgColor="dark"
                                            color="white"
                                            borderRadius="lg"
                                            sx={{
                                                display: "inline-block",
                                                padding: isSmallScreen ? "3px 6px" : "5px 10px",
                                                margin: isSmallScreen ? "2px" : "5px",
                                                fontSize: isSmallScreen ? "0.6rem" : "0.7rem",
                                                fontWeight: "lighter"
                                            }}
                                        >
                                            {subject}
                                        </SoftBox>
                                    ))}
                                </SoftTypography>
                            )}
                        </SoftTypography>


                        <div style={{ float: "inline-end" }}>
                            {(status === "FAILED" || status === "COMPLETE") && (
                                controller.role === "free-agent" && !feedback_rating &&
                                <Tooltip title="FeedBack">
                                    <IconButton
                                        size="icon"
                                        color="secondary"
                                        onClick={() => setShowFeedback(true)}
                                        className="text-primary hover:text-primary hover:bg-primary/10"
                                    >
                                        <FaRegThumbsUp className="h-5 w-5" />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </div>
                    </CardContent>
                </SoftBox>

                {openDeleteDialog && (
                    <DeleteArticleDialog isModalOpen={openDeleteDialog} handleCloseModal={handleCloseDeleteDialog}
                                         articleId={articleId} articleTitle={title} refreshArticles={refreshList}
                                         setNotification={setNotification}/>
                )}

                {controller.role === FREE_AGENT && <SoftBox sx={{position: "absolute", right: 0, marginRight: "10px", marginTop: isSmallScreen ? '4px' : '60px'}}>
                    <div>
                        <SoftBox sx={{
                            float: 'right',
                            marginRight: '10px',
                            fontSize: '1rem',
                            fontWeight: 500
                        }}>${parseFloat(cost).toFixed(2)}</SoftBox>
                        <div>
                            {(status == "PENDING" || status == 'FAILED') && !hasSufficientCredits &&

                                (<React.Fragment>
                                        <PayPalScriptProvider
                                            options={{"client-id": window?.appConfig?.REACT_APP_PAYPAL_CLIENT_ID || process.env.REACT_APP_PAYPAL_CLIENT_ID}}
                                        >
                                            <PayPalButtons
                                                style={{layout: "vertical", label: 'pay'}}
                                                fundingSource={FUNDING.PAYPAL}
                                                createOrder={(data, actions) => {
                                                    return createOrder([articleId], 'paypal')
                                                        .then(response => {
                                                            return response.data.id;
                                                        })
                                                        .catch(error => {
                                                            setNotification({
                                                                show: true,
                                                                snackbarMessage: "Order creation failed. Please try again.",
                                                                severity: "error",
                                                            });
                                                        });
                                                }}

                                                onApprove={(data, actions) => {
                                                    return captureOrder(data.orderID)
                                                        .then(response => {
                                                            if (response.data.status === 'COMPLETED') {
                                                                setNotification({
                                                                    show: true,
                                                                    snackbarMessage: "Payment successful!",
                                                                    severity: "success",
                                                                });
                                                                refreshList();
                                                                handleModalClose();
                                                            }
                                                        })
                                                        .catch(error => {
                                                            setNotification({
                                                                show: true,
                                                                snackbarMessage: "Payment capture error. Please try again.",
                                                                severity: "error",
                                                            });
                                                        });
                                                }}

                                                onError={(err) => {
                                                    console.error("An error occurred during the payment process. Please try again.");
                                                }}
                                            />
                                        </PayPalScriptProvider>
                                        {activeCreditCard && <CreditCardPaymentButton articleId={articleId} refreshList={refreshList}
                                                                 setNotification={setNotification}
                                                                 cost={parseFloat(cost).toFixed(2)}
                                        />}
                                    </React.Fragment>
                                )
                            }
                            {(status == "PENDING" || status == 'FAILED') && hasSufficientCredits &&
                                <CreditsPaymentButton articleId={articleId} refreshList={refreshList}
                                                      setNotification={setNotification}
                                />
                            }
                        </div>
                    </div>
                </SoftBox>}

                {freeAgentDocuments && controller.role === SUPER_ADMIN_ROLE &&
                    <SoftBox sx={{position: "absolute", right: 0, marginRight: "10px", marginTop: "45px"}}>
                        <SoftBox>
                            <SoftBox sx={{
                                float: 'right',
                                marginRight: '10px',
                                fontSize: '1rem',
                                fontWeight: 500
                            }}>${parseFloat(cost).toFixed(2)}

                                {paymentStatus == "COMPLETED" &&
                                <Tooltip title="Payment successful">
                                    <IconButton aria-label="harmful_content" color="success">
                                        <BsCash />
                                    </IconButton>
                                </Tooltip>
                            }
                            {paymentStatus == "FAILED" &&
                                <Tooltip title="Payment error">
                                    <IconButton aria-label="harmful_content" color="error">
                                        <BsCash />
                                    </IconButton>
                                </Tooltip>
                            }
                            {(paymentStatus == null || paymentStatus.toLowerCase() == "pending") &&
                                <Tooltip title="Payment pending">
                                    <IconButton aria-label="harmful_content" color="default">
                                        <BsCash />
                                    </IconButton>
                                </Tooltip>
                            }
                        </SoftBox>
                    </SoftBox>
                </SoftBox>}

                {!all_services_selected && controller.role !== FREE_AGENT && <RunNonExecutedServices
                    id={id}
                    sourceLanguage={sourceLanguage}
                    targetLanguage={targetLanguage}
                    open={openRunNonExecutedServicesDialog}
                    onClose={() => {
                        setOpenRunNonExecutedServicesDialog(false)
                    }}
                    refreshList={refreshList}
                    setNotification={setNotification}
                />}
                <EditDigitizedArticleDialog
                    open={isMetadataDialogOpen}
                    onClose={handleCloseMetadataDialog}
                    author={author}
                    title={title}
                    subjects={subjects}
                    publishedAt={publishedAt}
                    sourceLanguage={sourceLanguage}
                    targetLanguage={targetLanguage}
                    digitizationId={id}
                    setNotification={setNotification}
                    refreshList={refreshList}
                />

            </Card>
        </Card>


            {showFeedback &&
                <FeedbackDialog
                    showFeedback={showFeedback}
                    setShowFeedback={setShowFeedback}
                    digitizationId={id}
                    refreshList={refreshList}
                />
            }


        </>

    );
};

// Typechecking props for the BasicLayout
CardItem.propTypes = {
    id: PropTypes.number,
    title: PropTypes.string,
    summary: PropTypes.string,
    author: PropTypes.string,
    origin: PropTypes.string,
    bookmarkingPath: PropTypes.string,
    sourceLanguage: PropTypes.string,
    targetLanguage: PropTypes.string,
    image: PropTypes.string,
    status: PropTypes.string,
    paymentStatus: PropTypes.string,
    freeAgentDocuments: PropTypes.bool,
    has_audio_option: PropTypes.bool,
    all_services_selected: PropTypes.bool,
    cost: PropTypes.string,
    publishedAt: PropTypes.node,
    handleOpenPreview: PropTypes.func,
    createdAt: PropTypes.string,
    searchText: PropTypes.string,
    handleClickDownloadDigitizedDocument: PropTypes.func,
    downloadBookmarkingFile: PropTypes.func,
    downloadSearchableFile: PropTypes.func,
    subjects: PropTypes.array,
    services_data: PropTypes.array,
    setNotification: PropTypes.func,
    refreshList: PropTypes.func,
    setEventNameList: PropTypes.func,
    updateAudioToDownloadList: PropTypes.func,
    articleId: PropTypes.number,
    digitizerId: PropTypes.number,
    userCreditBalance: PropTypes.number,
    feedback_rating: PropTypes.number
};
export default CardItem;
