import PropTypes from "prop-types";
import StarsComponent from "./StarsComponent";

const StarRating = ({ rating }) => {
    return (
        <div style={{ display: "flex", alignItems: "center", position: "relative" }} title={rating.toFixed(2)}>
            <StarsComponent rating={rating} isFirstInView={true}/>               
        </div>
    );
};

StarRating.propTypes = {
    rating: PropTypes.number.isRequired,
};

export default StarRating;