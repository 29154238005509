import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import {register, getDepartmentsByUniversity, getPublicUniversities} from "../../../axios-client";
import signUpBgImage from "assets/images/curved-images/registration.png";
import TermsAndConditionsDialog from "./TermsAndConditionsDialog";
import { Info } from '@mui/icons-material'
import Tooltip from '@mui/material/Tooltip';




function SignUp() {
    const [agreement, setAgreement] = useState(true);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        password: "",
        university: "",
        department: [],
        agreement: "",
        isFreeAgent: true
    });
    const [showPassword, setShowPassword] = useState(false);
    const [errors, setErrors] = useState({});
    const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "" });
    const [isActivating, setIsActivating] = useState(false);
    const [openTermsDialog, setOpenTermsDialog] = useState(false);


    const navigate = useNavigate();

    const handleTermsOpen = () => {
        setOpenTermsDialog(true);
    };

    const handleTermsClose = () => {
        setOpenTermsDialog(false);
    };
    

    const handleSetAgreement = () => {
        setAgreement(!agreement);
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };


    const handleSubmit =  (e) => {
        e.preventDefault();
        if (validateForm()) {
          setIsActivating(true);
          const updatedFormData = {
            ...formData,
          };
          
          register(updatedFormData)
            .then((response) => {
                setSnackbar({
                    open: true,
                    message: "Successfully registered!",
                    severity: "success",
                });
                return new Promise(resolve => setTimeout(resolve, 1000));
            })
            .then(() => {
                navigate("/authentication/confirmation", { state:  "free-agent"  });
            })
            .catch((error) => {
                return new Promise(resolve => setTimeout(resolve, 2000))
                    .then(() => handleRegistrationError(error));
            })
            .finally(() => {
                setIsActivating(false);
            });
    }
};

      const handleRegistrationError = (error) => {
        if (error.response && error.response.status === 400 && error.response.data.message === "Email already exists") {
          setSnackbar({
            open: true,
            message: "This email is already registered",
            severity: "error",
          });
        } else {
          setSnackbar({
            open: true,
            message: "An error occurred during registration.",
            severity: "error",
          }
        );}
        setIsActivating(false);
    };
    

    const validateForm = () => {
        const newErrors = {};
        if (!formData.name) newErrors.name = "Name is required";
        if (!formData.email) newErrors.email = "Email is required";
        if (!formData.password) newErrors.password = "Password is required";
        if (!agreement) newErrors.agreement = "Accepting the terms and conditions is required";
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (formData.email && !emailPattern.test(formData.email)) {
            newErrors.email = "Please enter a valid email address";
        }
        if (formData.password && formData.password.length < 8) {
            newErrors.password = "Password must be at least 8 characters long";
        } else if (formData.password && !/[!@#$%^&*]/.test(formData.password)) {
            newErrors.password = "Password must contain at least one special character";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSnackbarClose = () => {
        setSnackbar({ open: false, message: "", severity: "" });
    };



    return (
        <BasicLayout
            title="Welcome!"
            image={signUpBgImage}
        >
            <Card>
                <SoftBox p={3} mb={1} textAlign="center">
                    <SoftTypography variant="h5" fontWeight="medium" color="primary">
                        Register
                    </SoftTypography>
                </SoftBox>
                <SoftBox pt={2} pb={3} px={3}>
                    <SoftBox component="form" role="form" onSubmit={handleSubmit}>
                        <SoftBox mb={2}>

                            <SoftInput
                                type="text"
                                placeholder="Name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                            />
                            {errors.name && (
                                <SoftTypography color="error" fontSize="small">
                                    {errors.name}
                                </SoftTypography>
                            )}
                        </SoftBox>
                        <SoftBox mb={2}>
                            <SoftInput
                                type="email"
                                placeholder="Email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                            />
                            {errors.email && (
                                <SoftTypography color="error" fontSize="small">
                                    {errors.email}
                                </SoftTypography>
                            )}
                        </SoftBox>
                        <SoftBox mb={2} position="relative">
                            <SoftInput
                                type={showPassword ? "text" : "password"}
                                placeholder="Password"
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                                sx={{ paddingRight: "40px" }}
                            />
                            <IconButton
                                onClick={togglePasswordVisibility}
                                sx={{
                                    position: "absolute",
                                    top: "50%",
                                    right: "10px",
                                    transform: "translateY(-50%)",
                                    padding: "5px",
                                }}
                                aria-label="toggle password visibility"
                            >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                            {errors.password && (
                                <SoftTypography color="error" fontSize="small">
                                    {errors.password}
                                </SoftTypography>
                            )}
                        </SoftBox>
                        <SoftBox display="flex" alignItems="center">
                            <Checkbox checked={agreement} onChange={handleSetAgreement}  />
                            <SoftTypography
                                variant="button"
                                fontWeight="regular"
                                onClick={handleSetAgreement}
                                sx={{ cursor: "pointer", userSelect: "none" }}
                            >
                                &nbsp;&nbsp;I agree the&nbsp;
                            </SoftTypography>
                            <SoftTypography
                                component="a"
                                variant="button"
                                fontWeight="bold"
                                textGradient
                                color="primary"
                                onClick={handleTermsOpen}
                                sx={{ cursor: "pointer" }}
                            >
                                Terms and Conditions
                            </SoftTypography>
                        </SoftBox>
                        {errors.agreement && (
                            <SoftTypography color="error" fontSize="small">
                                {errors.agreement}
                            </SoftTypography>
                        )}
                        <SoftBox mt={4} mb={1}>
                            <SoftButton variant="gradient" color="primary" fullWidth type="submit" >
                                {isActivating ? 'Please Wait...' : 'Sign up'}
                                {isActivating && <CircularProgress size={20} style={{ marginLeft: 10, color: "white" }} />}
                            </SoftButton>
                        </SoftBox>
                        <SoftBox mt={3} textAlign="center">
                            <SoftTypography variant="button" color="text" fontWeight="regular">
                                Already have an account?&nbsp;
                                <SoftTypography
                                    component={Link}
                                    to="/authentication/sign-in"
                                    variant="button"
                                    color="primary"
                                    fontWeight="bold"
                                    textGradient
                                >
                                    Sign in
                                </SoftTypography>
                            </SoftTypography>
                        </SoftBox>
                    </SoftBox>
                </SoftBox>
            </Card>

            <TermsAndConditionsDialog
                open={openTermsDialog}
                close={handleTermsClose}
            />
            <Snackbar
                open={snackbar.open}
                autoHideDuration={4000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </BasicLayout>
    );
}

export default SignUp;
