
// Soft UI Dashboard React layouts
import Dashboard from "layouts/dashboard";

// Soft UI Dashboard React icons
import Shop from "reusableComponents/Icons/Shop";
import Office from "reusableComponents/Icons/Office";
import Departments from "./mainComponents/departments/Departments";
import Universities from "./mainComponents/universities/Universities";
import Librarians from "./mainComponents/librarians/Librarians";
import MyPublications from "layouts/mydocuments";
import { MdGroup } from 'react-icons/md';
import { HiMiniUsers } from "react-icons/hi2";
import ResearcherManagement from "./mainComponents/researcherManagement/ResearcherManagement";
import Document from "./reusableComponents/Icons/Document";
import Report from "./mainComponents/report/Report";
import { BiSolidReport } from "react-icons/bi";
import { FaUniversity } from "react-icons/fa";
import { FaUserTie } from "react-icons/fa6";
import PaymentList from "./mainComponents/payment/PaymentList";
import { FaFileInvoiceDollar } from "react-icons/fa6";
import AgentDigitizations from "layouts/agent-publications";
import SwaggerDoc from "swagger/swaggerDocs";
import { BsFiletypeJson } from "react-icons/bs";
import { FaUsersCog } from 'react-icons/fa';
import ServicesList from "./layouts/services/ServicesList";
import { MdMiscellaneousServices } from "react-icons/md";
import FeedbackList from "./reusableComponents/Feedback/FeedbackList";
import { VscFeedback } from "react-icons/vsc";

const sideBarItems = {
    'super-admin':
        [
            {
                type: "collapse",
                name: "Dashboard",
                key: "dashboard",
                route: "/dashboard",
                icon: <Shop size="12px"/>,
                component: <Dashboard/>,
                noCollapse: true,
            },
            {
                type: "collapse",
                name: "Manage free agents",
                key: "free-agents",
                route: "free-agents",
                icon:<FaUsersCog size="14px" />,
                component: <Librarians/>,
                noCollapse: true,
            },
            {
                type: "collapse",
                name: "Agent Digitizations",
                key: "agent-digitizations",
                route: "/agent-digitizations",
                icon: <FaUserTie size="12px"/>,
                component: <AgentDigitizations/>,
                noCollapse: true,
            },
            {
                type: "collapse",
                name: "Payment list",
                key: "payment-list",
                route: "/payment-list",
                icon: <FaFileInvoiceDollar size="12px"/>,
                component: <PaymentList/>,
                noCollapse: true,
            },
            {
            type: "collapse",
            name: "Services List",
            key: "services-list",
            route: "/services-list",
            icon: <MdMiscellaneousServices size="12px"/>,
            component: <ServicesList />,
            noCollapse: true,
            },
            {
                type: "collapse",
                name: "Manage feedback List",
                key: "feedback-list",
                route: "/feedback-list",
                icon: <VscFeedback size="12px"/>,
                component: <FeedbackList />,
                noCollapse: true,
            }
        ],
    'free-agent':
        [
            {
                type: "collapse",
                name: "Dashboard",
                key: "dashboard",
                route: "/dashboard",
                icon: <Shop size="12px"/>,
                component: <Dashboard/>,
                noCollapse: true,
            },
            {
                type: "collapse",
                name: "Digital Database",
                key: "documents",
                route: "/documents",
                icon: <Document size="12px" />,
                noCollapse: true,
            },
            {
                type: "collapse",
                name: "Invoices",
                key: "invoices",
                route: "/invoices",
                icon: <FaFileInvoiceDollar size="12px"/>,
                component: <PaymentList/>,
                noCollapse: true,
            }
        ]
};

export default sideBarItems;
