import "../../editorStyleSheet.css";
import * as pdfjs from "pdfjs-dist/legacy/build/pdf";
import pdfWorker from "pdfjs-dist/legacy/build/pdf.worker.entry";
pdfjs.GlobalWorkerOptions.workerSrc = pdfWorker;
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";
import {Stage, Layer} from 'react-konva';
import ImageComponent from "./ImageComponent";

const EditorCanvasComponent = ({mediaPath, containerWidth, page, pages, setForcePageNumber, zoom, setZoom}) => {

    const containerRef = useRef();
    const [searchParams, setSearchParams] = useSearchParams();
    const [stageWidth, setStageWidth] = useState(0);
    const [stageHeight, setStageHeight] = useState(0);
    const [imageWidth, setImageWidth] = useState(596);
    const [imageHeight, setImageHeight] = useState(842);
    const [brightness, setBrightness] = useState(0);
    const [contrast, setContrast] = useState(0);
    const thumbnailContainerRef = useRef(null);
    const isHand = 'hand';
    let scrollInterval;

    useEffect(() => {
        const selectedThumbnail = document.querySelector(`.thumbnail:nth-child(${page})`);
        if (selectedThumbnail) {
            selectedThumbnail.scrollIntoView({
                behavior: 'smooth',
                inline: 'center',
                block: 'nearest',
            });
        }
    }, [page]);

    const handleZooming = (newZoom) => {
        setZoom(newZoom);
    };

    const updateDimensions = () => {
        setStageWidth(containerRef.current.offsetWidth);
        setStageHeight(containerRef.current.offsetHeight);
    };

    const handleSidebarToggle = () => {
        updateDimensions();
    };

    useEffect(() => {
        updateDimensions();
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    useEffect(() => {
        localStorage.setItem("SidebarStatus", "Collapsed");
        window.addEventListener('sidebarToggled', handleSidebarToggle);
        window.addEventListener('resize', updateDimensions);
        window.addEventListener('configResized', updateDimensions);

        return () => {
            window.removeEventListener('sidebarToggled', handleSidebarToggle);
            window.removeEventListener('resize', updateDimensions);
            window.removeEventListener('configResized', updateDimensions);
        };
    }, [containerWidth]);

    const handleWheel = (e) => {
        e.evt.preventDefault();
        const scaleBy = 1.1;
        const stage = e.target.getStage();
        const oldScale = stage.scaleX();
        const mousePointTo = {
            x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
            y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
        };

        const newScale = e.evt.deltaY > 0 ? oldScale / scaleBy : oldScale * scaleBy;

        stage.scale({x: newScale, y: newScale});
        stage.position({
            x: -(mousePointTo.x - stage.getPointerPosition().x / newScale) * newScale,
            y: -(mousePointTo.y - stage.getPointerPosition().y / newScale) * newScale,
        });
        stage.batchDraw();
        handleZooming(newScale);
    };

    const pointerShape = () => {
        switch (isHand) {
            case "hand":
                return "pointer";
            case "bounding":
                return "crosshair";
            case "boundingCategory":
                return "auto";
        }
    };

    const startAutoScroll = (direction) => {
        const scrollAmount = 100

        scrollInterval = setInterval(() => {
            if (direction === "left") {
                thumbnailContainerRef.current.scrollLeft -= scrollAmount;
            } else if (direction === "right") {
                thumbnailContainerRef.current.scrollLeft += scrollAmount;
            }
        }, 100);
    };

    const stopAutoScroll = () => {
        clearInterval(scrollInterval);
    };

    const handleThumbnailClick = async (newPage) => {
        setForcePageNumber(newPage)
        searchParams.set("page", newPage);
        setSearchParams(searchParams);
    };

    return (
        <div className="DigitizationEditorCanvas" ref={containerRef}>
            <div>
                <Stage
                    width={stageWidth}
                    height={stageHeight}
                    onWheel={handleWheel}
                    scaleX={zoom}
                    scaleY={zoom}
                    draggable={isHand}
                    style={{cursor: pointerShape(), background: '#363537'}}
                >
                    <Layer>
                        <ImageComponent
                            src={mediaPath}
                            x={(stageWidth - imageWidth) / 2}
                            y={(stageHeight - imageHeight) / 2}
                            brightness={brightness}
                            contrast={contrast}
                        />
                    </Layer>
                </Stage>
                <div className="thumbnail-navigation">
                    <button
                        onMouseEnter={() => startAutoScroll("left")}
                        onMouseLeave={stopAutoScroll}
                        className="nav-arrow left-arrow"
                    >
                        {"<"}
                    </button>

                    <div className="thumbnail-container" ref={thumbnailContainerRef}>
                        {pages?.map((thumbnail, index) => (
                            <div
                                key={thumbnail.pageNumber}
                                className={`thumbnail ${thumbnail.pageNumber === page ? "active" : ""}`}
                                onClick={() => handleThumbnailClick(thumbnail.pageNumber)}
                            >
                                <img src={thumbnail.src} alt={`Page ${thumbnail.pageNumber}`}/>
                                <div className={`page-number ${thumbnail.pageNumber === page ? "highlighted" : ""}`}>
                                    {`${thumbnail.pageNumber}`}
                                </div>
                            </div>
                        ))}
                    </div>

                    <button
                        onMouseEnter={() => startAutoScroll("right")}
                        onMouseLeave={stopAutoScroll}
                        className="nav-arrow right-arrow"
                    >
                        {">"}
                    </button>
                </div>
            </div>
        </div>
    );
};

EditorCanvasComponent.propTypes = {
    mediaPath: PropTypes.string,
    containerWidth: PropTypes.string,
    zoom: PropTypes.number,
    setZoom: PropTypes.func,
    page: PropTypes.object,
    pages: PropTypes.array,
    setForcePageNumber: PropTypes.func
};

export default EditorCanvasComponent;
