import React, {useEffect, useState} from "react";
import PropTypes, {object} from "prop-types";
import CardItem from "./CardItem";
import SoftBox from "../../../components/SoftBox";
import {
    downloadFileFromUrl, getTitleWithDate, getTitleWithoutDate,
    prepareDownload
} from "../../../utlis/generalUtils";
import Pagination from "@mui/material/Pagination";
import {MenuItem, Select} from "@mui/material";
import DownloadDigitizedArticleDialog from "../../../layouts/publications/DownloadDigitizedArticleDialog";
import PreviewCard from "../../../layouts/publications/components/PreviewCard";
import {deleteFile, downloadFile, getExportedDocuments, scheduleDownload} from "../../../axios-client";
import {useSoftUIController} from "../../../context";
import DocumentDownloadSnackbar from "../../Snackbar/DocumentDownloadSnackbar";
import {io} from 'socket.io-client';

const CardComponent = ({
                           data,
                           count,
                           size,
                           page,
                           handlePageChange,
                           handleRowsPerPageChange,
                           loadingData,
                           getArchivesBySelectedDocument,
                           setNotification,
                           searchText,
                           refreshList,
                       }) => {
    const [controller] = useSoftUIController();
    const {userId, role} = controller;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [previewData, setPreviewData] = useState(null);
    const initialExpandedSections = {
        ocr_text: false,
        translated_text: false,
        summary: false,
        bullet_points_summary: false,
        description: false
    }
    const [selectedDigitization, setSelectedDigitization] = useState({id: null, title: "", picture_path: ""});
    const [expandedSections, setExpandedSections] = useState(initialExpandedSections);
    const initialSelectedFields = {
        ocr_text: false,
        translated_text: false,
        summary: false,
        bullet_points_summary: false,
        description: false
    }
    const [selectedFields, setSelectedFields] = useState(initialSelectedFields);
    const [documents, setDocuments] = useState([]);
    const [eventName, setEventName] = useState("");
    const key = `document_to_download_by_${role}_${userId}`

    useEffect(() => {
        fetchStoredDocuments();
    }, []);

    useEffect(() => {
        const baseURL = window?.appConfig?.REACT_APP_SOCKET_SERVER || process.env.REACT_APP_SOCKET_SERVER;
        const socket = io(baseURL, {
            path: "/socket.io"
        });
        socket.on(eventName, () => {
            fetchStoredDocuments();
        });

        return () => {
            socket.off(eventName);
            socket.disconnect();
        };
    }, [eventName]);

    const fetchStoredDocuments = () => {
        const storedDocuments = JSON.parse(localStorage.getItem(key)) || [];
        if (storedDocuments.length > 0) {
            getExportedDocuments({ titles: storedDocuments })
                .then(res => {
                    setDocuments(res.data.documents);
                })
                .catch(e => {
                    console.error("Failed to fetch documents:", e);
                });
        }
    };

    const handlePaginationSizeChange = (event) => {
        let newSize = parseInt(event.target.value, 10);
        handleRowsPerPageChange(newSize);
    };

    const handlePaginationPageChange = (event, newPage) => {
        const numberOfPages = Math.ceil((count ?? data.length) / size);
        let page = newPage <= numberOfPages ? newPage : 1;
        handlePageChange(page);
    };

    const handleOpenModal = async (id, title, picture_path) => {
        setIsModalOpen(true);
        setSelectedDigitization({id: id, title: title, picture_path: picture_path})
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedDigitization(null)
        setSelectedFields(initialSelectedFields);
    };

    const handleFieldChange = (field) => {
        setSelectedFields((prev) => ({
            ...prev,
            [field]: !prev[field]
        }));
    };


    const updateDocumentToDownloadList = (documentTitle) => {
        const existingDocuments = JSON.parse(localStorage.getItem(key)) || [];
        existingDocuments.push(documentTitle);
        localStorage.setItem(key, JSON.stringify(existingDocuments));
        fetchStoredDocuments()
    }

    const handleGenerateDocument = async () => {

        let documentTitle = getTitleWithDate(selectedDigitization.title)
        await setEventName(`document_to_download_${documentTitle}`)
        scheduleDownload(selectedDigitization.id, documentTitle, selectedFields).then(res => {
            updateDocumentToDownloadList(documentTitle)
            handleCloseModal();
        }).catch(e => {
            handleCloseModal();
        })
    };

    const handleOpenPreview = async (id) => {
        const selectedDocumentData = await getArchivesBySelectedDocument(id);
        if (selectedDocumentData) {
            setPreviewData(selectedDocumentData);
            setIsPreviewOpen(true);
        }
    };

    const handleClosePreview = () => {
        setIsPreviewOpen(false);
        setPreviewData(null);
        setExpandedSections(initialExpandedSections)
    };

    const toggleSection = (section) => {
        setExpandedSections((prev) => ({
            ...prev,
            [section]: !prev[section]
        }));
    };

    const downloadBookmarkingFile = async (id) => {
        const selectedDocumentData = await getArchivesBySelectedDocument(id);
        if (selectedDocumentData) {
            downloadFileFromUrl(selectedDocumentData.filename, selectedDocumentData.bookmarking_path)
        }
    };

    const handleDownload = (doc) => {

        downloadFile({ title: doc.title })
            .then((response) => {
                prepareDownload([response.data], response.data.type, getTitleWithoutDate(doc.title))
            })
            .then(() => {
                deleteDocumentFile(doc.title);
            })
            .catch((error) => {
                console.error("Error downloading document:", error);
            });
    };

    const deleteDocumentFromLocalStorage = (documentTitle) => {
        let existingDocuments = JSON.parse(localStorage.getItem(key)) || [];
        existingDocuments = existingDocuments.filter(doc => doc !== documentTitle);
        localStorage.setItem(key, JSON.stringify(existingDocuments));

        setDocuments(prevDocuments =>
            prevDocuments.filter(document => document.title !== documentTitle)
        );

    };

    const deleteDocumentFile = (documentTitle) => {
            deleteFile(documentTitle)
            .then((result) => {
                deleteDocumentFromLocalStorage(documentTitle);
            })
            .catch((error) => {
                console.error('Error deleting file:', error);
            });
    };

    return (
        <React.Fragment>
            {data && data.map((element, index) => {
                return (
                    <SoftBox key={index} style={{paddingBottom: "5px"}}>
                        <CardItem
                            id={element.id}
                            title={element.title}
                            subjects={element.subjects}
                            author={element.author}
                            sourceLanguage={element.source_language}
                            targetLanguage={element.target_language}
                            status={element.status}
                            origin={element.origin}
                            services_data={element.services_data}
                            all_services_selected={element.all_services_selected}
                            paymentStatus={element.paymentStatus}
                            freeAgentDocuments={element.freeAgentDocuments}
                            image={element.picture_path}
                            publishedAt={element.publishedAt}
                            createdAt={element.createdAt}
                            summary={element.summary}
                            bookmarkingPath={element.bookmarking_path}
                            searchText={searchText}
                            handleClickDownloadDigitizedDocument={() => handleOpenModal(element.id, element.title, element.picture_path)}
                            handleOpenPreview={() => handleOpenPreview(element.id)}
                            downloadBookmarkingFile={() => downloadBookmarkingFile(element.id)}
                            getArchivesBySelectedDocument={getArchivesBySelectedDocument}
                            setNotification={setNotification}
                            articleId={element.articleId}
                            cost={element.cost}
                            refreshList={refreshList}
                            digitizerId={element.digitizerId}
                            has_audio_option={element.has_audio_option}
                            setEventName={setEventName}
                            updateDocumentToDownloadList={updateDocumentToDownloadList}
                        />
                    </SoftBox>
                );
            })}
            {data.length > 0 ? (
                <SoftBox key={"pagination-card"}>
                    <SoftBox>
                        <SoftBox style={{display: "flex"}}>
                            <SoftBox style={{marginLeft: "auto"}}>
                                <Select
                                    id="rowsPerPage"
                                    fullWidth
                                    value={size}
                                    onChange={handlePaginationSizeChange}
                                    style={{
                                        width: "100%",
                                        padding: "10px 0px",
                                        border: "none",
                                        fontSize: "14px",
                                        boxSizing: "border-box",
                                        outline: "none",
                                        fontFamily: "Arial, Roboto",
                                    }}
                                    inputProps={{"aria-label": "Without label"}}
                                >
                                    <MenuItem key={"5"} value="5">5</MenuItem>
                                    <MenuItem key={"10"} value="10" selected={true}>10</MenuItem>
                                    <MenuItem key={"20"} value="20">20</MenuItem>
                                </Select>
                            </SoftBox>
                            <SoftBox>
                                <Pagination
                                    size="sm"
                                    color="primary"
                                    count={Math.ceil(count / size)}
                                    page={page}
                                    onChange={handlePaginationPageChange}
                                    showFirstButton
                                    showLastButton
                                    style={{marginLeft: "auto"}}
                                />
                            </SoftBox>
                        </SoftBox>
                    </SoftBox>
                </SoftBox>
            ) : loadingData ? (
                <p style={{textAlign: "center", padding: "50px"}}> Loading... </p>
            ) : (
                <p style={{textAlign: "center", padding: "50px"}}> No results found!</p>
            )}

            <DownloadDigitizedArticleDialog
                isModalOpen={isModalOpen}
                handleCloseModal={handleCloseModal}
                docData={selectedDigitization}
                selectedFields={selectedFields}
                handleFieldChange={handleFieldChange}
                handleGenerateDocument={handleGenerateDocument}
            />

            <PreviewCard
                isPreviewOpen={isPreviewOpen}
                handleClosePreview={handleClosePreview}
                previewData={previewData}
                expandedSections={expandedSections}
                toggleSection={toggleSection}
            />

            <DocumentDownloadSnackbar
                documents={documents} handleDownload={handleDownload} deleteFile={deleteDocumentFile} userId={userId}
                onClose={deleteDocumentFromLocalStorage}
            />

        </React.Fragment>
    );
};

CardComponent.propTypes = {
    data: PropTypes.arrayOf(object),
    count: PropTypes.number,
    size: PropTypes.number,
    page: PropTypes.number,
    loadingData: PropTypes.bool,
    searchText: PropTypes.string,
    handlePageChange: PropTypes.func,
    handleSizeChange: PropTypes.func,
    handleClickOpen: PropTypes.func,
    handleRowsPerPageChange: PropTypes.func,
    getArchivesBySelectedDocument: PropTypes.func,
    setNotification: PropTypes.func,
    refreshArticles: PropTypes.func,
    refreshList: PropTypes.func,
};

export default CardComponent;
