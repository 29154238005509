import React from 'react';
import SuiButton from "../../../components/SoftButton";
import {Autocomplete, TextField, InputAdornment} from '@mui/material';
import PropTypes from "prop-types";

function SubjectTerms({
                          subjects,
                          setSearchSubject,
                          handleAddNewSubject,
                          isAddingSubject,
                          searchSubject,
                          formData,
                          setFormData
                      }) {
    return (
        <>
            <label htmlFor="subjectTerms" className="label-style">Subject Terms</label>
            <Autocomplete
                multiple
                freeSolo
                options={subjects.map(subject => subject.name)}
                value={formData.subjectTerms.map(subjectId => {
                    const subject = subjects.find(s => s.id === subjectId);
                    return subject ? subject.name : '';
                })}
                onChange={(event, newValue) => {
                    const selectedSubjectIds = newValue.map((name) => {
                        const subject = subjects.find(subject => subject.name === name);
                        return subject ? subject.id : null;
                    }).filter(Boolean);

                    setFormData(prevState => ({
                        ...prevState,
                        subjectTerms: selectedSubjectIds,
                    }));
                }}
                onInputChange={(event, value) => setSearchSubject(value)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder="Select subjects"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {params.InputProps.endAdornment}
                                    {searchSubject && !subjects.some(subject => subject.name.toLowerCase() === searchSubject.toLowerCase()) && (
                                        <InputAdornment position="end">
                                            <SuiButton
                                                size="small"
                                                onClick={handleAddNewSubject}
                                                disabled={isAddingSubject}
                                            >
                                                {isAddingSubject ? 'Adding...' : 'Add'}
                                            </SuiButton>
                                        </InputAdornment>
                                    )}
                                </>
                            ),
                        }}
                    />
                )}
            />        </>
    );
}

SubjectTerms.propTypes = {
    subjects: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
        })
    ).isRequired,
    setSearchSubject: PropTypes.func,
    handleAddNewSubject: PropTypes.func,
    isAddingSubject: PropTypes.bool,
    searchSubject: PropTypes.string,
    formData: PropTypes.object,
    setFormData: PropTypes.func
}

export default SubjectTerms;
