import React from 'react';
import PropTypes from 'prop-types';
import IconButton from "@mui/material/IconButton";
import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";
import { Box, Chip } from "@mui/material";
import Card from "@mui/material/Card";
import TermsFilter from "./components/TermsFilter";

function FilterList({
                        filterCollapsed,
                        handleFilterCollapseToggle,
                        selectedSubjects,
                        selectedLanguages,
                        handleCheckboxSubjectChange,
                        handleCheckboxLanguageChange,
                        handleClearAll,
                        searchSubjectTerm,
                        setSearchSubjectTerm,
                        searchLanguageTerm,
                        setSearchLanguageTerm,
                        subjects,
                        languages,
                        subjectCollapsed,
                        handleSubjectCollapseToggle,
                        languageCollapsed,
                        handleLanguageCollapseToggle,
                    }) {
    return (
        <>
            {!filterCollapsed && (
                <IconButton
                    onClick={handleFilterCollapseToggle}
                    style={{
                        borderRadius: '50%',
                        border: '1px solid #ccc',
                        padding: '10px',
                        backgroundColor: '#f0f0f0',
                        width: '30px',
                        height: '30px',
                        marginTop: '10px',
                        marginRight: '10px',
                        fontSize: '0.7rem'
                    }}
                >
                    <ArrowForwardIos />
                </IconButton>
            )}

            {filterCollapsed && (
                <Box style={{ width: '20%', marginRight: '10px' }}>
                    <Card sx={{ padding: '20px', height: '100%', color: '#344767', borderRadius: 0 }}>
                        <div style={{
                            position: 'relative',
                            height: '30px',
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}>
                            <IconButton
                                onClick={handleFilterCollapseToggle}
                                style={{
                                    borderRadius: '50%',
                                    border: '1px solid #ccc',
                                    backgroundColor: '#f0f0f0',
                                    fontSize: '0.7rem',
                                }}
                            >
                                <ArrowBackIosNew />
                            </IconButton>

                            <IconButton
                                onClick={handleClearAll}
                                style={{
                                    borderRadius: '10%',
                                    backgroundColor: '#f0f0f0',
                                    fontSize: '0.7rem',
                                }}
                            >
                                Clear All
                            </IconButton>
                        </div>

                        <Box sx={{ marginTop: '10px', display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
                            {selectedSubjects.map((subject, index) => (
                                <Chip
                                    key={index}
                                    label={subject}
                                    onDelete={() => handleCheckboxSubjectChange(subject)}
                                    sx={{ margin: '5px 0' }}
                                />
                            ))}

                            {selectedLanguages.map((language, index) => (
                                <Chip
                                    key={index}
                                    label={language}
                                    onDelete={() => handleCheckboxLanguageChange(language)}
                                    sx={{ margin: '5px 0' }}
                                />
                            ))}
                        </Box>

                        <TermsFilter
                            title="Subject Terms"
                            searchTerm={searchSubjectTerm}
                            setSearchTerm={setSearchSubjectTerm}
                            terms={subjects}
                            selectedTerms={selectedSubjects}
                            handleCheckboxChange={handleCheckboxSubjectChange}
                            termCollapsed={subjectCollapsed}
                            handleTermCollapseToggle={handleSubjectCollapseToggle}
                            itemKey="name"
                            placeholder="Search subjects.."
                        />

                        <TermsFilter
                            title="Languages"
                            searchTerm={searchLanguageTerm}
                            setSearchTerm={setSearchLanguageTerm}
                            terms={languages}
                            selectedTerms={selectedLanguages}
                            handleCheckboxChange={handleCheckboxLanguageChange}
                            termCollapsed={languageCollapsed}
                            handleTermCollapseToggle={handleLanguageCollapseToggle}
                            itemKey="language"
                            placeholder="Search languages.."
                        />
                    </Card>
                </Box>
            )}
        </>
    );
}

FilterList.propTypes = {
    filterCollapsed: PropTypes.bool.isRequired,
    handleFilterCollapseToggle: PropTypes.func.isRequired,
    selectedSubjects: PropTypes.arrayOf(PropTypes.string).isRequired,
    selectedLanguages: PropTypes.arrayOf(PropTypes.string).isRequired,
    handleCheckboxSubjectChange: PropTypes.func.isRequired,
    handleCheckboxLanguageChange: PropTypes.func.isRequired,
    handleClearAll: PropTypes.func.isRequired,
    searchSubjectTerm: PropTypes.string.isRequired,
    setSearchSubjectTerm: PropTypes.func.isRequired,
    searchLanguageTerm: PropTypes.string.isRequired,
    setSearchLanguageTerm: PropTypes.func.isRequired,
    subjects: PropTypes.arrayOf(PropTypes.object).isRequired,
    languages: PropTypes.arrayOf(PropTypes.object).isRequired,
    subjectCollapsed: PropTypes.bool.isRequired,
    handleSubjectCollapseToggle: PropTypes.func.isRequired,
    languageCollapsed: PropTypes.bool.isRequired,
    handleLanguageCollapseToggle: PropTypes.func.isRequired,
};

export default FilterList;
