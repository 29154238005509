import React from 'react';
import SoftTypography from "../../components/SoftTypography";
import SoftBox from "../../components/SoftBox";
import defaultMiniature from "../../assets/images/curved-images/digitization.png";
import SuiButton from "../../components/SoftButton";
import {Checkbox, FormControlLabel, DialogActions, Dialog, DialogTitle, DialogContent, Divider} from "@mui/material";
import {IoClose} from "react-icons/io5";
import CardMedia from "@mui/material/CardMedia";
import PropTypes from 'prop-types';

function DownloadDigitizedArticleDialog({
                                            isModalOpen,
                                            handleCloseModal,
                                            docData,
                                            selectedFields,
                                            handleFieldChange,
                                            handleGenerateDocument
                                        }) {
    const imageSrc = docData?.picture_path || defaultMiniature;

    return (
        <>
            <Dialog open={isModalOpen} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth
                    PaperProps={{style: {borderRadius: 5}}}>
                <DialogTitle
                    id="form-dialog-title"
                    sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}
                >
                    Select fields to include in the document
                    <IoClose
                        aria-label="close"
                        onClick={handleCloseModal}
                        style={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            cursor: 'pointer'
                        }}
                    />
                </DialogTitle>
                <Divider/>
                <DialogContent sx={{padding: "0px 24px"}}>

                    <SoftTypography component="div" variant="h6" style={{marginBottom: "20px"}}>
                        <SoftBox>
                            {docData?.title}
                        </SoftBox>
                    </SoftTypography>

                    <div style={{display: "flex"}}>
                        <div>
                            <CardMedia
                                component="img"
                                sx={{width: 150, borderRadius: 0, margin: "0 50px 0 0"}}
                                image={imageSrc}
                                alt="digitized document"
                            />
                        </div>
                        <div>
                            <FormControlLabel
                                control={<Checkbox checked={selectedFields.ocr_text}
                                                   onChange={() => handleFieldChange("ocr_text")}/>}
                                label="Extracted Text"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={selectedFields.translated_text}
                                                   onChange={() => handleFieldChange("translated_text")}/>}
                                label="Translated Text"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={selectedFields.summary}
                                                   onChange={() => handleFieldChange("summary")}/>}
                                label="Summary"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={selectedFields.bullet_points_summary}
                                                   onChange={() => handleFieldChange("bullet_points_summary")}/>}
                                label="Bullet Points Summary"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={selectedFields.description}
                                                   onChange={() => handleFieldChange("description")}/>}
                                label="Description"
                            />
                        </div>
                    </div>


                </DialogContent>
                <Divider/>
                <DialogActions>
                    <SuiButton variant="gradient" color="secondary" size="medium"
                               onClick={handleCloseModal}>Close</SuiButton>
                    <SuiButton variant="gradient" color="primary" size="medium" onClick={handleGenerateDocument}  disabled={Object.values(selectedFields).every(value => value === false)}>
                        Download
                    </SuiButton>
                </DialogActions>
            </Dialog>        </>
    );
}

DownloadDigitizedArticleDialog.propTypes = {
    isModalOpen: PropTypes.bool,
    handleCloseModal: PropTypes.func,
    selectedFields: PropTypes.shape({
        ocr_text: PropTypes.bool,
        translated_text: PropTypes.bool,
        summary: PropTypes.bool,
        bullet_points_summary: PropTypes.bool,
        description: PropTypes.bool
    }).isRequired,
    docData: PropTypes.objectOf(PropTypes.string),
    handleFieldChange: PropTypes.func,
    handleGenerateDocument: PropTypes.func
};
export default DownloadDigitizedArticleDialog;