import React from "react";
import SoftBox from "../../../components/SoftBox";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Info from "@mui/icons-material/Info";
import PropTypes from "prop-types";

const OutputFormat = ({
    label,
    handleCheckboxFormatChange,
    selectedFormat,
    outputFormats,
    errors,
    formData
}) => {

    const handleCheckboxChange = (e, format) => {
        handleCheckboxFormatChange(e, format);
    };

    return (
        <React.Fragment>
            <label htmlFor="documentFormat" className="label-style">
                {label}
            </label>

            <React.Fragment>
                <SoftBox style={{ display: "grid", gridTemplateColumns: "50% 50%", gap:"0.6rem" }}>
                    {outputFormats.map((format, index) => (
                        <div key={index} style={{ display: "flex", alignItems: "center" }}>
                            <FormControlLabel
                                style={{ margin: "0px", fontWeight: "400" }}
                                control={
                                    <Checkbox
                                        checked={selectedFormat.includes(format.id)}
                                        onChange={(e) => handleCheckboxChange(e, format)}
                                        color="primary"
                                    />
                                }
                                label={format.name}
                            />
                            {format.description && (
                                <IconButton size="small">
                                    <Tooltip title={format.description} arrow>
                                        <Info fontSize="small" />
                                    </Tooltip>
                                </IconButton>
                            )}
                        </div>
                    ))}
                </SoftBox>
                {errors.outputFormats && <p className="error-text">{errors.outputFormats}</p>}
            </React.Fragment>
        </React.Fragment>
    );
};

OutputFormat.propTypes = {
    label: PropTypes.string,
    handleCheckboxFormatChange: PropTypes.func,
    selectedFormat: PropTypes.array,
    outputFormats: PropTypes.array,
    errors: PropTypes.object,
    formData: PropTypes.object
};

export default OutputFormat;