import React from "react";
import PropTypes from "prop-types";
import { IconButton } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

const VoiceOptionsSelection = ({
                                   voices,
                                   selectedVoice,
                                   setSelectedVoice,
                                   playVoice,
                                   handlePlayVoice,
                                   label,
                                   updateVoiceName,
                                   style
                               }) => (
    <div style={{ marginTop: "10px", ...style }}>
        <label className="label-style">{label}</label>
        {voices.map((voice, idx) => (
            <div key={idx} style={{ display: "flex", marginBottom: '10px', alignItems: 'center' }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={selectedVoice.url === voice.url}
                            onChange={() => {
                                setSelectedVoice({
                                    url: voice.url,
                                    voice_name: voice.voice_name
                                });
                                updateVoiceName(voice.voice_name);
                            }}
                            color="primary"
                        />
                    }
                    label={voice.name}
                />
                <IconButton
                    onClick={() => handlePlayVoice(voice.url)}
                    style={{
                        marginLeft: "10px",
                        background: '#ddd9d9',
                        padding: '5px',
                        width: '25px',
                        height: '25px'
                    }}>
                    {playVoice === voice.url ? (
                        <PauseIcon style={{ fontSize: '10px' }} />
                    ) : (
                        <PlayArrowIcon style={{ fontSize: '10px' }} />
                    )}
                </IconButton>
            </div>
        ))}
    </div>
);

VoiceOptionsSelection.propTypes = {
    voices: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string.isRequired,
            voice_name: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired
        })
    ).isRequired,
    selectedVoice: PropTypes.shape({
        url: PropTypes.string.isRequired,
        voice_name: PropTypes.string.isRequired
    }).isRequired,
    setSelectedVoice: PropTypes.func.isRequired,
    playVoice: PropTypes.string.isRequired,
    handlePlayVoice: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    updateVoiceName: PropTypes.func.isRequired,
    style: PropTypes.object
};

export default VoiceOptionsSelection;
