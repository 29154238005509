import React, {useState} from 'react';
import {PayPalScriptProvider, PayPalButtons, FUNDING } from "@paypal/react-paypal-js";
import PropTypes from 'prop-types';
import {captureOrder, createOrder} from "../../axios-client";
import {Dialog, DialogContent, DialogTitle, Divider} from "@mui/material";
import {IoClose} from "react-icons/io5";
import PaymentButton from "./PaymentButton";

const PayPalPaymentButton = ({articleId, refreshList, setNotification, cost}) => {

    const [isModalOpen, setModalOpen] = useState(false);
    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handleClick = () => {
        setModalOpen(true);
        return false;
    };

    return (
        <>
            <PaymentButton handleClick={handleClick}/>
            <Dialog open={isModalOpen} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth
                    PaperProps={{style: {borderRadius: 15, height: '90vh'}}}>
                <DialogTitle
                    id="form-dialog-title"
                    sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}
                >
                    Pay {cost} USD using
                    <IoClose
                        aria-label="close"
                        onClick={handleModalClose}
                        style={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            cursor: 'pointer'
                        }}
                    />
                </DialogTitle>
                <Divider/>
                <DialogContent sx={{padding: "0px 24px"}}>
                    <PayPalScriptProvider
                        options={{"client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID}}
                    >
                        <PayPalButtons
                            style={{layout: "vertical", label: 'pay'}}
                            fundingSource={FUNDING.PAYPAL}
                            createOrder={(data, actions) => {
                                return createOrder([articleId], 'paypal')
                                    .then(response => {
                                        return response.data.id;
                                    })
                                    .catch(error => {
                                        setNotification({
                                            show: true,
                                            snackbarMessage: "Order creation failed. Please try again.",
                                            severity: "error",
                                        });
                                    });
                            }}

                            onApprove={(data, actions) => {
                                return captureOrder(data.orderID)
                                    .then(response => {
                                        if (response.data.status === 'COMPLETED') {
                                            setNotification({
                                                show: true,
                                                snackbarMessage: "Payment successful!",
                                                severity: "success",
                                            });
                                            refreshList();
                                            handleModalClose();
                                        }
                                    })
                                    .catch(error => {
                                        setNotification({
                                            show: true,
                                            snackbarMessage: "Payment capture error. Please try again.",
                                            severity: "error",
                                        });
                                    });
                            }}

                            onError={(err) => {
                                console.error("An error occurred during the payment process. Please try again.");
                            }}
                        />

                        <PayPalButtons
                            style={{layout: "vertical", label: 'pay'}}
                            fundingSource={FUNDING.CARD}
                            createOrder={(data, actions) => {
                                return createOrder([articleId], 'credit-card')
                                    .then(response => {
                                        return response.data.id;
                                    })
                                    .catch(error => {
                                        setNotification({
                                            show: true,
                                            snackbarMessage: "Order creation failed. Please try again.",
                                            severity: "error",
                                        });
                                    });
                            }}

                            onApprove={(data, actions) => {
                                return captureOrder(data.orderID)
                                    .then(response => {
                                        if (response.data.status === 'COMPLETED') {
                                            setNotification({
                                                show: true,
                                                snackbarMessage: "Payment successful!",
                                                severity: "success",
                                            });
                                            refreshList();
                                            handleModalClose();
                                        }
                                    })
                                    .catch(error => {
                                        setNotification({
                                            show: true,
                                            snackbarMessage: "Payment capture error. Please try again.",
                                            severity: "error",
                                        });
                                    });
                            }}

                            onError={(err) => {
                                console.error("An error occurred during the payment process. Please try again.");
                            }}

                        />
                    </PayPalScriptProvider>
                </DialogContent>
            </Dialog>


        </>

    );
};

PayPalPaymentButton.propTypes = {
    articleId: PropTypes.number.isRequired,
    setNotification: PropTypes.func.isRequired,
    refreshList: PropTypes.func,
    cost: PropTypes.string.isRequired
};

export default PayPalPaymentButton;