export const RTL_LANGUAGES = [
    'Arabic',
    'arabic',
    'Divehi',
    'Persian',
    'Hausa',
    'Hebrew',
    'Kashmiri',
    'Kurdish',
    'Pashto',
    'Urdu',
    'Yiddish'
];

export const LIBRARIAN_ROLE = "librarian";
export const LIBRARY_ADMIN_ROLE = "library-admin";
export const SUPER_ADMIN_ROLE = 'super-admin';
export const RESEARCHER_ROLE = 'researcher';
export const FREE_AGENT = 'free-agent';