// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard React examples
import DashboardLayout from "reusableComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "reusableComponents/Navbars/DashboardNavbar";
import Footer from "reusableComponents/Footer";

// Soft UI Dashboard React base styles
import typography from "assets/theme/base/typography";

// Dashboard layout components
import BuildByDevelopers from "layouts/dashboard/components/BuildByDevelopers";
import {
    getStatistics,
    getArchivesByPeriod,
    getDigitizationsReport,
    getDigitizationCount,
} from "../../axios-client";
import {useEffect, useState} from "react";
import MiniStatisticsCard from "../../reusableComponents/Cards/StatisticsCards/MiniStatisticsCard";

import {PiBooksFill} from "react-icons/pi";
import {HiUsers} from "react-icons/hi2";
import {FaUniversity} from "react-icons/fa";
import DonutChart from "../../reusableComponents/Cards/StatisticsCards/DonutChart";
import BarChart from "../../reusableComponents/Cards/StatisticsCards/BarChart";
import GradientLineChart from "../../reusableComponents/Cards/StatisticsCards/GradientLineChart";
import Card from "@mui/material/Card";
import GradientLineBarCharts from "../../reusableComponents/Cards/StatisticsCards/GradientLineBarChats";
import {useSoftUIController} from "../../context";
import {formatDataToUrlQueryParams, formatDate, formatFloat, formatTimeToMinutes} from "../../utlis/generalUtils";
import PerformanceDataTableComponent from "./components/PerformanceDataTableComponent";
import { DateRange } from '@iroomit/react-date-range';
import { format } from 'date-fns';
import SoftInput from "../../components/SoftInput";
import SoftButton from "../../components/SoftButton";
import {Divider} from "@mui/material";
import { LuCalendarRange } from "react-icons/lu";
import {SUPER_ADMIN_ROLE, LIBRARY_ADMIN_ROLE} from "../../utlis/constant";
import { BiCalendar } from "react-icons/bi";

function Dashboard() {

    const [controller] = useSoftUIController();
    const {role} = controller;
    const userId=controller.profileData.id
    const [departmentsCount, setDepartmentCount] = useState(0);
    const [digitizationsCount, setDigitizationsCount] = useState(0);
    const [librariansCount, setLibrariansCount] = useState(0);
    const [librarianAdminsCount, setLibrarianAdminsCount] = useState(0);
    const [freeAgentsCount, setFreeAgentsCount] = useState(0);
    const [totalUsersCount, setTotalUsersCount] = useState(0);
    const [researchersCount, setResearchersCount] = useState(0);
    const [todayArticlesCount, setTodayArticlesCount] = useState(0);
    const [universitiesCount, setUniversitiesCount] = useState(0);
    const [monthlyDigitizations, setMonthlyDigitizations] = useState({ labels: [], values: [] });
    const [lineChartData, setLineChartData] = useState({ labels: [], datasets: [] });
    const [staticDigitizations, setStaticDigitizations] = useState([]);
    const [digitizationsReportData, setDigitizationsReportData] = useState([]);
    const [digitizationsReportDataCount, setDigitizationsReportDataCount] = useState(0);
    const [miniCardTitle, setMiniCardTitle] = useState("");
    const { size } = typography;

    const [showDatePicker, setShowDatePicker] = useState(false);
    const [datePlaceholder, setDatePlaceholder] = useState('Select a date');
    const [dataValues, setDataValues] = useState([]);
    const [labels, setLabels] = useState([]);
    const [chartTitle, setChartTitle] = useState("Users overview");
    const [filterData, setFilterData] = useState({
        page: 1,
        size: 5,
        start_date: null,
        end_date: null,
    });

    const [tempRange, setTempRange] = useState({
        startDate: filterData.start_date ? new Date(filterData.start_date) : null,
        endDate: filterData.end_date ? new Date(filterData.end_date) : null
    });

    const rolesViewCards = ['super-admin', 'library-admin', 'librarian']

    const handleClear = () => {

        setTempRange({
            startDate: null,
            endDate: null,
        });

        setFilterData((prevFilterData) => ({
            ...prevFilterData,
            start_date: null,
            end_date: null,
        }));
        setDatePlaceholder('Select a date');
        setShowDatePicker(false);
    };

    const handleSelect = (ranges) => {
        const { startDate, endDate } = ranges.selection;
        setTempRange({
            startDate: startDate,
            endDate: endDate,
        });
    };

    const handleApply = () => {
        if (tempRange.startDate && tempRange.endDate) {
            setFilterData((prevFilterData) => ({
                ...prevFilterData,
                start_date: format(tempRange.startDate, 'yyyy-MM-dd'),
                end_date: format(tempRange.endDate, 'yyyy-MM-dd'),
            }));


            setDatePlaceholder(`${format(tempRange.startDate, 'yyyy-MM-dd')} - ${format(tempRange.endDate, 'yyyy-MM-dd')}`);
        } else {
            setDatePlaceholder('Select a date');
        }

        setShowDatePicker(false);
    };

    const handlePageChange = (newPage) => {
        setFilterData((prev) => ({ ...prev, page: newPage }));
    };

    const handleRowsPerPageChange = (newSize) => {
        setFilterData((prev) => ({ ...prev, size: newSize, page: 1 }));
    };

    const fetchData = () => {
        if (role) {
            getDigitizationCount()
                .then(res => {
                    setDigitizationsCount(res.data.digitizations_count);
                    if (role && role === SUPER_ADMIN_ROLE) {
                        setTodayArticlesCount(res.data.today_articles_count);
                        setTotalUsersCount(res.data.total_users_count);
                        setLabels(["Free-Agents" ]);
                        setDataValues([res.data.free_agents_count]);
                    }
                })
                .catch(error => {
                    console.error('Error fetching statistics:', error);
                });

            if (role && role === SUPER_ADMIN_ROLE) {
                getArchivesByPeriod('?period=daily&period_value=5')
                    .then(res => {
                        setStaticDigitizations(res.data.data);
                    })
                    .catch(error => {
                        console.error('Error fetching data:', error);
                    });
            }
            getStatistics()
                .then(response => {
                    const { digitizations_per_day, digitizations_per_month } = response.data;


                    const dayLabels = Object.keys(digitizations_per_day);
                    const dayCounts = Object.values(digitizations_per_day);
                    const monthLabels = Object.keys(digitizations_per_month);
                    const monthCounts = Object.values(digitizations_per_month);

                    setMonthlyDigitizations({ labels: monthLabels, values: monthCounts });

                    setLineChartData({
                        labels: dayLabels,
                        datasets: [
                            {
                                label: "Daily Digitizations",
                                data: dayCounts,
                                color: "info"
                            }
                        ]
                    });
                }).catch(error => {
                console.error('Error fetching digitizations count:', error);
            });


        }
    }


    useEffect(() => {
        fetchData();
    }, [ role]);
    const chartData = {
        labels:  monthlyDigitizations?.labels || [],
        datasets: {
            label: 'Monthly digitizations overview',
            data:  monthlyDigitizations?.values || [],
        },
    };

    const getDigitizationOrigin = (origin) => {
        if (origin === "External") {
            return <span className="badge badge-sm badge-refunded">API</span>;
        } else {
            return <span className="badge badge-sm badge-pending">Platform</span>;
        }
    };

    useEffect(() => {
        if (role === SUPER_ADMIN_ROLE) {
            const queryParams = formatDataToUrlQueryParams(filterData);
            getDigitizationsReport(`?${queryParams}`)
                .then(res => {
                    const formattedRows = res.data.data.map(digitization => ({
                        "User": digitization.user_name,
                        "Article title": digitization.title && digitization.title.length > 25 ? `${digitization.title.slice(0, 25)}...` : digitization.title,
                        "Upload date": formatDate(digitization.created_at),
                        "Size": digitization.size,
                        "Pages": digitization.page_count,
                        "Time (mn)": formatFloat(formatTimeToMinutes(digitization.digitization_time),3),
                        "CPU(m - cores)":`${formatFloat(digitization.cpu_usage * 1000, 2)} - ${formatFloat(digitization.cpu_usage, 4)} `,
                        "Memory (MB)": formatFloat(digitization.memory_usage,2),
                        "Origin": getDigitizationOrigin(digitization.origin)
                    }));
                    setDigitizationsReportDataCount(res.data.count)
                    setDigitizationsReportData(formattedRows)
                })
                .catch(error => {
                    console.error('Error digitizations report:', error);
                });
        }

    }, [filterData, role]);


    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <SoftBox py={3}>
                <SoftBox mb={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} xl={!rolesViewCards.includes(role) ? 12 : 3}>
                            <MiniStatisticsCard
                                title={{text: "Digitizations"}}
                                count={digitizationsCount}
                                icon={{color: "primary", component: <PiBooksFill/>}}
                            />
                        </Grid>
                        {rolesViewCards.includes(role) && <>
                            <Grid item xs={12} sm={6} xl={3}>
                                {role === SUPER_ADMIN_ROLE && (
                                    <MiniStatisticsCard
                                        title={{ text: "Daily articles" }}
                                        count={todayArticlesCount}
                                        icon={{ color: "primary", component: <BiCalendar /> }}                                    />
                                )}
                            </Grid>
                            <Grid item xs={12} sm={6} xl={3}>
                                {role === SUPER_ADMIN_ROLE && (
                                    <MiniStatisticsCard
                                        title={{ text: "Total users" }}
                                        count={totalUsersCount}
                                        icon={{color: "primary", component: <HiUsers/>}}
                                    />
                                )}
                            </Grid>
                        </>
                        }
                    </Grid>
                </SoftBox>

                <SoftBox pt={1} pb={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={!rolesViewCards.includes(role) ? 12 : 7}>
                            <Card>
                                <SoftBox p={2}>
                                    <BuildByDevelopers />
                                </SoftBox>
                            </Card>
                        </Grid>

                        {rolesViewCards.includes(role) && (
                            <Grid item xs={12} lg={5} >
                                <Card sx={{ overflow: 'hidden' }}>
                                    <SoftBox p={2} >
                                        <DonutChart
                                            dataValues={dataValues}
                                            labels={labels}
                                            title={chartTitle}
                                        />
                                    </SoftBox>
                                </Card>
                            </Grid>
                        )}
                    </Grid>
                </SoftBox>

                <SoftBox mb={3}>
                    <Grid container spacing={3}>
                        {
                            chartData.labels.length > 0 && <Grid item xs={12} lg={5}>
                                <BarChart chart={chartData} color={"#FFF"}
                                          title={"Monthly digitization overview"}/>
                            </Grid>
                        }

                        {lineChartData.labels.length > 0 &&  <Grid item xs={12} lg={7}>

                            <GradientLineChart
                                title="Daily digitizations overview"
                                height="18.25rem"
                                chart={lineChartData}
                            />
                        </Grid>}



                    </Grid>
                </SoftBox>

                {role === SUPER_ADMIN_ROLE &&

                    <SoftBox mb={3}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Card style={{ overflow: 'visible', position: 'relative'}}>
                                    <SoftBox p={2}>

                                        <div
                                            style={{display: 'flex', flexDirection: 'column', paddingBottom: "1.5rem"}}>
                                            <h5 style={{margin: 0}}>{"Digitizations performance overview"} <span
                                                style={{fontSize: '12px', fontWeight: '400'}}>
                                                </span>
                                            </h5>
                                        </div>

                                        <div style={{position: 'relative', width: '12.813rem', float: "left"}}>
                                            <div style={{position: 'relative'}}>
                                                <SoftInput
                                                    type="text"
                                                    placeholder={datePlaceholder}
                                                    value={datePlaceholder}
                                                    onClick={() => setShowDatePicker(!showDatePicker)}
                                                    readOnly
                                                    style={{
                                                        padding: '8px 12px',
                                                        paddingRight: '35px',
                                                        border: '1px solid #ccc',
                                                        borderRadius: '4px',
                                                        cursor: 'pointer',
                                                        width: '100%',
                                                        backgroundColor: 'white',
                                                        textAlign: 'left',
                                                        marginBottom: '10px',
                                                    }}
                                                />


                                                <LuCalendarRange style={{
                                                    position: 'absolute',
                                                    right: '10px',
                                                    top: '50%',
                                                    transform: 'translateY(-50%)',
                                                    cursor: 'pointer',
                                                    color: '#6c757d',
                                                }}/>
                                            </div>

                                            {showDatePicker && (
                                                <div style={{
                                                    position: 'absolute',
                                                    top: '100%',
                                                    left: '0',
                                                    zIndex: '1000',
                                                    backgroundColor: 'white',
                                                    border: '1px solid #ddd',
                                                    borderRadius: '4px',
                                                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                                                }}>
                                                    <DateRange
                                                        onChange={handleSelect}
                                                        ranges={[{
                                                            startDate: tempRange.startDate ? tempRange.startDate : new Date(),
                                                            endDate: tempRange.endDate ? tempRange.endDate : new Date(),
                                                            key: 'selection'
                                                        }]}
                                                        moveRangeOnFirstSelection={false}
                                                        inputRanges={[]}
                                                        editableDateInputs={true}
                                                        rangeColors={['#cb0c9f']}
                                                    />
                                                    <Divider/>
                                                    <SoftBox style={{
                                                        display: 'flex',
                                                        justifyContent: 'end',
                                                        margin: '8px',
                                                        gap: "0.5rem"
                                                    }}>
                                                        <SoftButton
                                                            variant="outlined" color="secondary"
                                                            style={{position: 'relative', bottom: "0.5rem"}}
                                                            onClick={handleClear}
                                                        >
                                                            Clear
                                                        </SoftButton>
                                                        <SoftButton
                                                            variant="gradient" color="light"
                                                            style={{position: 'relative', bottom: "0.5rem"}}
                                                            onClick={handleApply}
                                                        >
                                                            Apply
                                                        </SoftButton>
                                                    </SoftBox>
                                                </div>
                                            )}

                                        </div>

                                        <PerformanceDataTableComponent rows={digitizationsReportData}
                                                                       pageSize={filterData.size}
                                                                       page={filterData.page}
                                                                       startDate={filterData.start_date}
                                                                       endDate={filterData.end_date}
                                                                       handlePageChange={handlePageChange}
                                                                       handleRowsPerPageChange={handleRowsPerPageChange}
                                                                       count={digitizationsReportDataCount}
                                        />

                                    </SoftBox>
                                </Card>
                            </Grid>


                        </Grid>


                    </SoftBox>
                }
                {role === SUPER_ADMIN_ROLE && <SoftBox mb={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Card>
                                <SoftBox p={2}>
                                    <GradientLineBarCharts elements={staticDigitizations}/>
                                </SoftBox>
                            </Card>
                        </Grid>
                    </Grid>
                </SoftBox>
                }
            </SoftBox>
            <Footer/>
        </DashboardLayout>
    );
}

export default Dashboard;
