import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
    createOrderWithCredits,
    updateCredits
} from "../../axios-client";

const CreditsPaymentButton = ({
                                  articleId,
                                  refreshList,
                                  setNotification
                              }) => {


    const handleClick = async () => {
        try {
            await createOrderWithCredits([articleId], 'yaiglobal_credit');
            setNotification({
                show: true,
                snackbarMessage: 'Payment successful!',
                severity: 'success',
            });
            refreshList();
        } catch (error) {
            console.error('Payment process error:', error);
            setNotification({
                show: true,
                snackbarMessage: 'Payment process error. Please try again.',
                severity: 'error',
            });
        }
    };

    return (
        <button
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "7px",
                margin: "7px 0",
                border: "1px solid #ccc",
                borderRadius: "5px",
                cursor: "pointer",
                width: "180px",
                background: "#0070ba"
            }}
            onClick={handleClick}
        >
            <span style={{fontSize: "13px", fontWeight: "bold", color: "white"}}>Use YaiGlobal Credits</span>
        </button>
    );
}
CreditsPaymentButton.propTypes = {
    articleId: PropTypes.number.isRequired,
    setNotification: PropTypes.func.isRequired,
    refreshList: PropTypes.func
};

export default CreditsPaymentButton;