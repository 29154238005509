import React, {useRef, useEffect} from "react";
import {Box, Typography, IconButton, Avatar, Container, Paper} from "@mui/material";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import colors from "../../assets/theme/base/colors";
import {formatDateTime} from "../../utlis/generalUtils";
import StarsComponent from "./StarsComponent";
import PropTypes from "prop-types";


const CommentsSection = ({rows, commentsCount, loading, handlePageChange, currentPage, pageSize}) => {
    const scrollContainerRef = useRef(null);
    const totalPages = Math.ceil(commentsCount / pageSize);


    useEffect(() => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTo({ left: 0, behavior: 'smooth' });
        }
    }, [rows]);

    const handlePrevious = () => {
        if (currentPage > 1) {
            handlePageChange(currentPage - 1);
        }
    };

    const handleNext = () => {
        if (currentPage < totalPages) {
            handlePageChange(currentPage + 1);
        }
    };


    return (
        <Box sx={{ flexGrow: 1, p: 1 }}>
            <Container maxWidth={false} sx={{ width: "95% !important" }}>
                <Paper
                    elevation={0}
                    sx={{p: 4, borderRadius: 3, border: 1, borderColor: "divider", mx: "auto", maxWidth: "100%", width: "100% !important"}}
                >

                    <Box sx={{mt: 1, mb: 3}}>
                        <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", mb: 3,}}>
                            <Typography variant="h5" sx={{fontWeight: "bold"}}>
                                Comments {commentsCount > 0 ? `(${commentsCount})` : ''}
                            </Typography>
                            {(rows?.length > 0 && totalPages > 1) && (
                                <Box>
                                    <IconButton
                                        onClick={handlePrevious}
                                        disabled={currentPage === 1}
                                        sx={{mr: 1, border: "1px solid", borderColor: "divider", borderRadius: "50%", "&:hover": { bgcolor: "background.paper" },
                                            ...(currentPage === 1 && { opacity: 0.5, pointerEvents: 'none' })
                                        }}
                                    >
                                        <ChevronLeft />
                                    </IconButton>
                                    <IconButton
                                        onClick={handleNext}
                                        disabled={currentPage === totalPages}
                                        sx={{border: "1px solid", borderColor: "divider", borderRadius: "50%", bgcolor:  "divider", color: "white",
                                            "&:hover": { bgcolor:  `${colors.primary.main}` },
                                            ...(currentPage === totalPages && { opacity: 0.5, pointerEvents: 'none' })
                                        }}
                                    >
                                        <ChevronRight />
                                    </IconButton>
                                </Box>
                            )}
                        </Box>

                        <Box ref={scrollContainerRef} sx={{display: "flex", flexWrap: "wrap", gap: 3, overflowX: "hidden",
                            scrollSnapType: "x mandatory", "-webkit-overflow-scrolling": "touch",}}
                        >
                            {rows && rows.length > 0 ? (
                                rows.map((testimonial, index) => {
                                    const isFirstInView = index % pageSize === 0;
                                    const rating = testimonial.rating ?
                                        (typeof testimonial.rating === 'number' ? Math.min(Math.max(1, testimonial.rating), 5) : 5) : 5;
                                    return (
                                        <Box
                                            key={index}
                                            sx={{p: 4, border: "1px solid", borderColor: isFirstInView ?  `${colors.primary.focus}` : "divider", borderRadius: 2, display: "flex", flexDirection: "column",
                                                flex: "0 0 calc(33.333% - 16px)",
                                                minWidth: 300,
                                                scrollSnapAlign: "start",
                                                ...(isFirstInView && {
                                                    borderWidth: 2,
                                                    borderColor:  `${colors.primary.focus}`,
                                                }),
                                            }}
                                        >
                                            <Box sx={{display: "flex", mb: 2}}>
                                                <StarsComponent
                                                    rating={rating}
                                                    isFirstInView={isFirstInView}
                                                />
                                            </Box>

                                            <Typography variant="caption" sx={{color: "text.secondary", ml: 2, minWidth: "85px", textAlign: "right", fontSize: "0.7rem"}}>
                                                {formatDateTime(testimonial.created_at)}
                                            </Typography>

                                            {loading ?
                                                <Box sx={{mt: 1, mb: 3}}>
                                                    <Typography variant="h4" sx={{fontWeight: "bold", mb: 4}}>
                                                        Comments
                                                    </Typography>
                                                    <Typography>Loading comments...</Typography>
                                                </Box> :
                                            <Typography sx={{color: "text.secondary", flexGrow: 1, fontSize: "14px", padding:1}}>
                                                {testimonial.comment}
                                            </Typography>
                                            }

                                            <Box sx={{display: "flex", alignItems: "center", gap: 2}}>
                                                    <Avatar
                                                        src={testimonial.profile_picture? testimonial.profile_picture : "/placeholder.svg?height=64&width=64"}
                                                        alt={testimonial.name}
                                                    />
                                                <Box>
                                                    <Typography variant="subtitle2" sx={{fontWeight: "bold", color: isFirstInView ? "inherit" :  "text.secondary" , fontSize:"14px"}}>
                                                        {testimonial.name}
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary" sx={{fontSize:"14px"}}>
                                                        {testimonial.email}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    );
                                })
                            ) : (
                                <Typography variant="body2">There are no comments available.</Typography>
                            )}
                        </Box>
                    </Box>
                </Paper>
            </Container>
        </Box>
    );
};

CommentsSection.propTypes = {
    rows: PropTypes.arrayOf(PropTypes.object),
    commentsCount: PropTypes.number,
    loading: PropTypes.bool,
    currentPage: PropTypes.number,
    handlePageChange: PropTypes.func,
    pageSize: PropTypes.number,
};



export default CommentsSection;