import React from 'react';
import PropTypes from 'prop-types';


const SaveButton = ({handleSave, updatedText}) => {
    const buttonStyle = {
        backgroundColor: '#28a745',
        border: 'none',
        color: 'white',
        padding: '10px 20px',
        fontSize: '14px',
        borderRadius: '5px',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
        height: '40px',
        opacity: updatedText.length === 0 ? 0.5 : 1,
    };

    const hoverStyle = {
        backgroundColor: '#218838',
    };

    return (
        <button
            style={buttonStyle}
            onMouseOver={e => (e.target.style.backgroundColor = hoverStyle.backgroundColor)}
            onMouseOut={e => (e.target.style.backgroundColor = buttonStyle.backgroundColor)}
            onClick={handleSave}
            disabled={updatedText.length === 0}
        >
            Save
        </button>
    );
};

SaveButton.propTypes = {
    handleSave: PropTypes.func.isRequired,
    updatedText: PropTypes.array.isRequired
};
export default SaveButton;
