import {useSoftUIController} from "../../context";
import { useEffect, useState, useRef } from "react";
import {
    closeForcePageNumber,
    setForcedMinified,
    setMiniSidenav,
    setPageNumber,
    updateConfigWidth,
    updateForcePageNumber
} from "../../store/actions/app-actions";
import DashboardNavbar from "../../reusableComponents/Navbars/DashboardNavbar";
import DashboardLayout from "../../reusableComponents/LayoutContainers/DashboardLayout";
import {getArchivesResultById} from "../../axios-client";
import { useLocation } from "react-router-dom";
import EditorBodyComponent from "./editorBody/EditorBodyComponent";
import SnackbarNotification from "../../reusableComponents/Snackbar/SnackbarNotification";

const DigitizationEditor =  () => {
    const [controller, dispatch] = useSoftUIController();
    const { miniSidenav, pageNumber, forcePageNumber, containerWidth, configWidth, forceMinified } = controller;
    const [mediaPath, setMediaPath] = useState();
    const containerRef = useRef();
    const [scale, setScale] = useState(1);
    const [numPages, setNumPages] = useState(null);
    const [articleId, setArticleId] = useState(0);
    const [pages, setPages] = useState([]);
    const [sourceLanguage, setSourceLanguage] = useState('');
    const [targetLanguage, setTargetLanguage] = useState('');

    const location = useLocation();
    const id = new URLSearchParams(location.search).get("id");

    const elementRef = useRef(null);
    const [isFullScreen, setIsFullScreen] = useState(false);

    const [notification, setNotification] = useState({show: false, snackbarMessage: '', severity: ""});

    const handleFullScreen = () => {
        const element = elementRef.current;
        if (!document.fullscreenElement) {
            element.requestFullscreen().then(() => setIsFullScreen(true)).catch((err) => {
                alert(`Error attempting to enable full-screen mode: ${err.message}`);
            });
        } else {
            document.exitFullscreen().then(() => setIsFullScreen(false));
        }
    };

    const updatePageNumber = (pageNumber) => {
        setPageNumber(dispatch, pageNumber);
    }

    const setForcePageNumber = (forcePageNumber) => {
        updateForcePageNumber(dispatch, forcePageNumber);
    }
 const setCloseForcePageNumber = (closedForcePageNumber) => {
     closeForcePageNumber(dispatch, closedForcePageNumber);
    }

    const updateWidth = (newWidth) => {
        updateConfigWidth(dispatch, newWidth);
    }


    useEffect(() => {
        fetchData();
        setMiniSidenav(dispatch, true);
        setForcedMinified(dispatch, true);
    }, []);

    const fetchData = () => {
        getArchivesResultById(id).then(res => {
            const path = res.data.data.article.path;
            setMediaPath(path)
            setPages(res.data.data.article.pages);
            setArticleId(res.data.data.article.id);
            setSourceLanguage(res.data.data.article.source_language);
            setTargetLanguage(res.data.data.target_language);


        }).catch(error => {
            console.error("Error fetching data:", error);
        });
    }


    return (
        <DashboardLayout>
            <DashboardNavbar/>

            <div className="yai4eduEditorLayout" ref={elementRef} style={{background: '#fff'}}>

                <EditorBodyComponent
                    mediaPath={mediaPath} numPages={numPages}
                    setNumPages={setNumPages} forcePageNumber={forcePageNumber} scale={scale}
                    setScale={setScale}
                    pageNumber={pageNumber}
                    setPageNumber={updatePageNumber}
                    setCloseForcePageNumber={setCloseForcePageNumber}
                    setForcePageNumber={setForcePageNumber}
                    containerWidth={containerWidth}
                    configWidth={configWidth}
                    updateConfigWidth={updateWidth}
                    pages={pages}
                    sourceLanguage={sourceLanguage}
                    targetLanguage={targetLanguage}
                    handleFullScreen={handleFullScreen}
                    isFullScreen={isFullScreen}
                    articleId={articleId}
                    setNotification={setNotification}
                    setPages={setPages}
                />

            </div>
            <SnackbarNotification
                notification={notification}
                setNotification={setNotification}
            />

        </DashboardLayout>

    );

}
export default DigitizationEditor;