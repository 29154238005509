import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Divider } from '@mui/material';
import SuiButton from "../../components/SoftButton";
import { IoClose } from "react-icons/io5";
import {getSubjects, addSubject, checkDuplicatedArticles, deleteArticle, getServices} from "../../axios-client";
import data from '../../constants/languages.json';
import "../../mainComponents/departments/assets/departmentsStyleSheet.css";
import CircularProgress from '@mui/material/CircularProgress';
import MetaData from "./components/MetaData";
import FileUpload from "./components/FileUpload";
import SubjectTerms from "./components/SubjectTerms";
import PropTypes from "prop-types";
import Services from "./components/Services";

const DigitizeArticleDialog = ({open, onClose, handleDigitizeArticle, isDigitizing}) => {
    const [isFocused, setIsFocused] = useState(false);
    const [languages, setLanguages] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [formData, setFormData] = useState({
        title: '',
        author: '',
        publicationDate: '',
        sourceLanguage: '',
        targetLanguage: '',
        subjectTerms: [],
        services: [],
        file: null,
        audioFormat: '',
        voiceName: ''
    });
    const [errors, setErrors] = useState({});
    const [searchSubject, setSearchSubject] = useState('');
    const [isAddingSubject, setIsAddingSubject] = useState(false);
    const [existingArticleId, setExistingArticleId] = useState(null);
    const [services, setServices] = useState([]);

    const inputContainerStyle = {
        display: 'flex',
        alignItems: 'center',
        border: `1px solid ${isFocused ? '#cdcecf' : '#dcdfe3'}`,
        borderRadius: '8px',
        padding: '5px 10px',
        marginBottom: '10px',
        transition: 'border-color 0.3s ease',
    };

    const inputStyle = {
        width: '100%',
        padding: '10px 0px',
        border: 'none',
        fontSize: '14px',
        boxSizing: 'border-box',
        outline: 'none',
        fontFamily: 'Arial, Roboto',
    };

    useEffect(() => {
        setLanguages(data);
        getSubjects()
            .then(res => {
                setSubjects(res.data);
            })
            .catch(error => {
                console.error('Error fetching subjects:', error);
            });

        getServices()
            .then(res => {
                setServices(res.data);

                const defaultSelectedServices = res.data.filter(service => (service.mandatory == true || service.pre_selected == true)).map(key => key.id);

                setFormData(prevState => ({
                    ...prevState,
                    services: defaultSelectedServices
                }));
            })
            .catch(error => {
                console.error('Error fetching services:');
            });
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSelectChange = (e) => {
        const { name, value } = e.target;
        setErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleBlur = async () => {
        if (formData.title && formData.author) {
            try {
                const response = await checkDuplicatedArticles({ params: { title: formData.title, author: formData.author } });
                if (response.data.exists) {
                    setErrors(prevErrors => ({
                        ...prevErrors,
                        title: 'An article with this title and author already exists!'
                    }));
                    setExistingArticleId(response.data.data.id);
                } else
                {
                    setExistingArticleId(null);
                }
            } catch (error) {
                console.error("Error checking duplicated articles:", error);
            }
        }
    };

    const handleService = (e, serviceId) => {

        if (formData.services.includes(serviceId)) {
            setFormData(prevState => ({
                ...prevState,
                services: formData.services.filter(key => key != serviceId)
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                services: [...prevState.services, serviceId]
            }));
        }
    }

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file && file.type !== 'application/pdf') {
            setErrors(prevErrors => ({ ...prevErrors, file: 'Only PDF files are allowed' }));
        } else {
            setErrors(prevErrors => ({ ...prevErrors, file: '' }));
            setFormData(prevState => ({
                ...prevState,
                file: file
            }));
        }
    };

    const validateForm = () => {
        let newErrors = {};
        if (!formData.title) newErrors.title = 'Title is required';
        if (!formData.author) newErrors.author = 'Author is required';
        if (!formData.publicationDate) newErrors.publicationDate = 'Publication date is required';
        if (!formData.sourceLanguage) newErrors.sourceLanguage = 'Source language is required';
        if (!formData.targetLanguage) newErrors.targetLanguage = 'Target language is required';
        if (!formData.file) newErrors.file = 'File upload is required';

        const audioBookService = services.find(service => service.name === 'Text to Speech');
        if(formData.services?.includes(audioBookService?.id)){
            if (!formData.audioFormat) newErrors.audioFormat = 'Audio format is required';
            if (!formData.voiceName) newErrors.voiceName = 'Voice Name is required';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleDigitization =  () => {
        if (validateForm() && !isDigitizing && !existingArticleId) {

            const data = new FormData();

            data.append('title', formData.title);
            data.append('author', formData.author);
            data.append('publication_date', formData.publicationDate);
            data.append('source_language', formData.sourceLanguage);
            data.append('target_language', formData.targetLanguage);
            data.append('file', formData.file);
            data.append('audio_format', formData.audioFormat);
            data.append('voice_name', formData.voiceName);

            formData.services.forEach(service => {
                data.append('services', service);
            });

            formData.subjectTerms.forEach(subjectId => {
                data.append('subjects', subjectId);
            });

            handleDigitizeArticle(data);
        }
    };

    const handleAddNewSubject = () => {
        if (!searchSubject.trim()) return;

        setIsAddingSubject(true);
        addSubject({ name: searchSubject })
            .then(res => {
                return getSubjects();
            })
            .then(res => {
                setSubjects(res.data);
                const newSubjectId = res.data.find(subject => subject.name === searchSubject)?.id;
                if (newSubjectId) {
                    setFormData(prevState => ({
                        ...prevState,
                        subjectTerms: [...prevState.subjectTerms, newSubjectId]
                    }));
                }
                setSearchSubject('');
            })
            .catch(error => {
                console.error('Error adding subject:', error);
            })
            .finally(() => {
                setIsAddingSubject(false);
            });
    };

    return (
        <Dialog open={open} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth PaperProps={{ style: { borderRadius: 15 } }}>
            <DialogTitle
                id="form-dialog-title"
                sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
                Article Metadata
                <IoClose
                    aria-label="close"
                    onClick={onClose}
                    style={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        cursor: 'pointer'
                    }}
                />
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ padding: "0px 24px" }}>

                <MetaData
                    inputContainerStyle={inputContainerStyle}
                    inputStyle={inputStyle}
                    formData={formData}
                    handleInputChange={handleInputChange}
                    setIsFocused={setIsFocused}
                    errors={errors}
                    handleSelectChange={handleSelectChange}
                    languages={languages}
                    handleBlur={handleBlur}
                    isEditable={true}
                />

                <SubjectTerms subjects={subjects} setSearchSubject={setSearchSubject} handleAddNewSubject={handleAddNewSubject}
                              isAddingSubject={isAddingSubject} searchSubject={searchSubject} formData={formData} setFormData={setFormData}
                />

                <Services label={"Additional Services"}
                          setFormData={setFormData}
                          selectedLanguage={formData['sourceLanguage'].toLowerCase()}
                          services={services} selectedServices={formData.services}
                          handleCheckboxServiceChange={handleService}
                          errors={errors}
                          setErrors={setErrors}
                />

                <FileUpload setFormData={setFormData} setErrors={setErrors}
                            handleFileUpload={handleFileUpload} formData={formData} errors={errors}
                />

            </DialogContent>
            <Divider />
            <DialogActions>
                <SuiButton variant="gradient" color="secondary" size="medium" onClick={onClose}>Close</SuiButton>
                <SuiButton variant="gradient" color="primary" size="medium" onClick={handleDigitization} disabled={existingArticleId}>
                    {isDigitizing ? 'Digitizing...' : 'Digitize'}
                    {isDigitizing && <CircularProgress size={20} style={{ marginLeft: 10, color: "white" }} />}
                </SuiButton>
            </DialogActions>
        </Dialog>
    );
};

DigitizeArticleDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    handleDigitizeArticle: PropTypes.func,
    isDigitizing: PropTypes.bool
}
export default DigitizeArticleDialog;