export const SET_USERINFO = "SET_USERINFO";
export const SET_TOKEN = "SET_TOKEN";
export const SET_EULA_ACCEPTED = "SET_EULA_ACCEPTED";
export const SET_PICTURE = "SET_PICTURE";
export const SET_PROFILE_DATA = "SET_PROFILE_DATA";
export const SET_PAGE_NUMBER = 'SET_PAGE_NUMBER';
export const SET_FORCE_PAGE_NUMBER = 'SET_FORCE_PAGE_NUMBER';
export const UPDATE_EDITOR_CONTAINER_WIDTH = 'UPDATE_EDITOR_CONTAINER_WIDTH';
export const UPDATE_CONFIG_WIDTH = 'UPDATE_CONFIG_WIDTH';
export const FORCED_MINIFIED = 'FORCED_MINIFIED';
export const CLOSED_FORCE_PAGE_NUMBER = 'CLOSED_FORCE_PAGE_NUMBER';

export const setMiniSidenav = (dispatch, value) => dispatch({ type: "MINI_SIDENAV", value });
export const setForcedMinified = (dispatch, value) => dispatch({ type: FORCED_MINIFIED, value });
export const setTransparentSidenav = (dispatch, value) => dispatch({ type: "TRANSPARENT_SIDENAV", value });
export const setSidenavColor = (dispatch, value) => dispatch({ type: "SIDENAV_COLOR", value });
export const setTransparentNavbar = (dispatch, value) => dispatch({ type: "TRANSPARENT_NAVBAR", value });
export const setFixedNavbar = (dispatch, value) => dispatch({ type: "FIXED_NAVBAR", value });
export const setOpenConfigurator = (dispatch, value) => dispatch({ type: "OPEN_CONFIGURATOR", value });
export const setDirection = (dispatch, value) => dispatch({ type: "DIRECTION", value });
export const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });
export const setUserInfo = (dispatch, value) => dispatch({ type: SET_USERINFO, value });
export const setPicture = (dispatch, value) => dispatch({ type: SET_PICTURE, value });
export const setProfileData = (dispatch, value) => dispatch({ type: SET_PROFILE_DATA, value });
export const setToken = (dispatch, value) => dispatch({ type: SET_TOKEN, value });
export const setEulaAccepted = (dispatch, value) => dispatch({ type: SET_EULA_ACCEPTED, value });
export const setPageNumber = (dispatch, value) => dispatch({ type: SET_PAGE_NUMBER, value});
export const updateForcePageNumber = (dispatch, value) => dispatch({type: SET_FORCE_PAGE_NUMBER, value});
export const updateContainerWidth = (dispatch, newWidth) => dispatch({ type: UPDATE_EDITOR_CONTAINER_WIDTH, payload: newWidth });
export const updateConfigWidth = (dispatch, newWidth) => dispatch({type: UPDATE_CONFIG_WIDTH, payload: newWidth});
export const closeForcePageNumber = (dispatch, value) => dispatch({type: CLOSED_FORCE_PAGE_NUMBER, value});