import { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Card, Grid } from "@mui/material"
import { Edit } from "@mui/icons-material"
import SoftBox from "components/SoftBox"
import SoftTypography from "components/SoftTypography"
import SoftButton from "components/SoftButton"
import SoftInput from "components/SoftInput"
import DashboardLayout from "reusableComponents/LayoutContainers/DashboardLayout"
import DashboardNavbar from "../../reusableComponents/Navbars/DashboardNavbar";
import Table from 'reusableComponents/Tables/Table/index';
import {getAllServices, updateService} from "../../axios-client";
import SnackbarNotification from "../../reusableComponents/Snackbar/SnackbarNotification";
import Tooltip from "@mui/material/Tooltip";
import StarRating from "../../reusableComponents/Feedback/StarRating";
import {formatDataToUrlQueryParams, GradientSwitchStyle} from "../../utlis/generalUtils";
import { FaCheckCircle } from "react-icons/fa";
import { FaMinusCircle } from "react-icons/fa";
import CircularProgress from '@mui/material/CircularProgress';

const ServicesList = () => {
    const [rows, setRows] = useState([]);
    const [selectedService, setSelectedService] = useState(null)
    const [editPrice, setEditPrice] = useState("")
    const [isEditing, setIsEditing] = useState(false)
    const [filterData, setFilterData] = useState({ page: 1, size: 10 });
    const [totalCount, setTotalCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [notification, setNotification] = useState({ show: false, snackbarMessage: '', severity: "" });
    const [isActivating, setIsActivating] = useState(false);


    const fetchData = () => {
        setLoading(true);
        const queryParams = formatDataToUrlQueryParams(filterData);
        getAllServices(`?${queryParams}`)
            .then((res) => {
                const formattedRows = res.data.services.map((service) => ({
                    "Service name": (<SoftBox display="flex" flexDirection="column">
                        <SoftTypography variant="button" color="secondary">
                          {service.name}
                        </SoftTypography>
                        <SoftTypography variant="caption" color="secondary" style={{ textTransform: 'none' }}>
                          {service.description}
                      </SoftTypography>
                      </SoftBox>
                    ),
                    "Price": `$${service.price}`,
                    "Review": <StarRating rating={service.review || 0} />,
                    "Action": (
                        <Tooltip title={"Edit"}>
                            <SoftButton variant="text" color="dark" iconOnly onClick={() => handleEdit(service)}>
                                <Edit />
                            </SoftButton>
                        </Tooltip>
                    ),
                    "Activate": (
                        <>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <GradientSwitchStyle
                                    checked={service.is_active}
                                    onChange={() => handleServiceStatus(service.id, !service.is_active)}
                                    icon={<FaMinusCircle />}
                                    checkedIcon={<FaCheckCircle />}
                                    disabled={isActivating}
                                />
                                {isActivating && (<CircularProgress size={20} style={{ marginLeft: 10 }} />)}
                            </div>
                        </>
                    ),
                }));

                setRows(formattedRows);
                setTotalCount(res.data.total_count);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching services:", error);
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchData();
    }, [filterData]);


    const handlePageChange = (newPage) => {
        setFilterData((prev) => ({ ...prev, page: newPage }));
    };

    const handleRowsPerPageChange = (newSize) => {
        setFilterData((prev) => ({ ...prev, size: newSize, page: 1 }));
    };

    const handleEdit = (service) => {
        setSelectedService(service)
        setEditPrice(service.price?.toString())
        setIsEditing(true)
    }

    const handleServiceStatus = (serviceId, isActive) => {
        setIsActivating(true)
        const payload = { is_active: isActive };
        updateService(serviceId, payload)
            .then(() => {
                setIsActivating(false)
                const snackbarMessage = payload.is_active ? 'Service activated successfully!' : 'Service deactivated successfully!';
                setNotification({
                    show: true,
                    snackbarMessage: snackbarMessage,
                    severity: "success"
                });
                fetchData();
            })
            .catch(err => {
                setIsActivating(false)
                console.error('Error activating service:', err);
                setNotification({
                    show: true,
                    snackbarMessage: 'Failed to activate/deactivate service. Please try again!',
                    severity: "error"
                })
            });
    }

    const handleSave = () => {
        if (selectedService && editPrice) {
            const newPrice = parseFloat(editPrice);
            if (newPrice < 0) {
                setNotification({
                    show: true,
                    snackbarMessage: "Price must be non-negative!",
                    severity: "error",
                });
                return;
            }
            updateService(selectedService.id, {price: newPrice})
                .then((res) => {
                    setSelectedService({ ...selectedService, price: newPrice });
                    fetchData();
                    setIsEditing(false);
                    setNotification({ show: true, snackbarMessage: 'Service price updated successfully!', severity: "success" });
                })
                .catch((error) => {
                    console.error("Error updating service price:", error);
                    setNotification({ show: true, snackbarMessage: 'Failed to update price. Please try again!', severity: "error" });
                });
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox py={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={7}>
                            <SoftBox px={3}>
                                <Table
                                    rows={rows}
                                    loading={loading}
                                    pageSize={filterData.size}
                                    page={filterData.page}
                                    handlePageChange={handlePageChange}
                                    handleRowsPerPageChange={handleRowsPerPageChange}
                                    count={totalCount}
                                />
                            </SoftBox>

                    </Grid>
                    <Grid item xs={12} lg={5}>
                        <Card>
                            <SoftBox p={3}>
                                <SoftTypography variant="h6" fontWeight="medium">
                                    Service Details
                                </SoftTypography>
                            </SoftBox>
                            <SoftBox px={3} pb={3}>
                                {selectedService ? (
                                    <div>
                                        <SoftTypography variant="h5" fontWeight="bold" mb={1}>
                                            {selectedService.name}
                                        </SoftTypography>
                                        <SoftBox display="flex" alignItems="center" mb={2}>
                                            <SoftTypography variant="h4" fontWeight="medium" mr={2}>
                                                ${selectedService.price}
                                            </SoftTypography>
                                            <StarRating rating={selectedService.review || 0} />
                                        </SoftBox>
                                        <SoftTypography variant="body2" mb={2}>
                                            {selectedService.description}
                                        </SoftTypography>
                                        {isEditing && (
                                            <SoftBox display="flex" mt={2}>
                                                <SoftInput
                                                    type="number"
                                                    value={editPrice}
                                                    onChange={(e) => setEditPrice(e.target.value)}
                                                    placeholder="Enter new price"
                                                    style={{marginRight:"1.5rem"}}
                                                    inputProps={{ min: 0, step:0.000001 }}

                                                />
                                                <SoftButton color="primary" onClick={handleSave} ml={2}>
                                                    Save
                                                </SoftButton>
                                            </SoftBox>
                                        )}
                                    </div>
                                ) : (
                                    <SoftTypography variant="body2" color="text">
                                        Select a service to view details
                                    </SoftTypography>
                                )}
                            </SoftBox>
                        </Card>
                    </Grid>
                </Grid>
            </SoftBox>
            <SnackbarNotification
                notification={notification}
                setNotification={setNotification}
            />
        </DashboardLayout>
    )
}

export default ServicesList;