import React from 'react';
import PropTypes from 'prop-types';
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import { Box, Chip } from "@mui/material";
import Card from "@mui/material/Card";
import TermsFilter from "./components/TermsFilter";
import Tooltip from "@mui/material/Tooltip";

function FilterList({
                        filterCollapsed,
                        handleFilterCollapseToggle,
                        selectedSubjects,
                        selectedLanguages,
                        handleCheckboxSubjectChange,
                        handleCheckboxLanguageChange,
                        handleClearAll,
                        searchSubjectTerm,
                        setSearchSubjectTerm,
                        searchLanguageTerm,
                        setSearchLanguageTerm,
                        subjects,
                        languages,
                        subjectCollapsed,
                        handleSubjectCollapseToggle,
                        languageCollapsed,
                        handleLanguageCollapseToggle,
                        isDrawerMode = false,
                    }) {

    const shouldRender = isDrawerMode || !filterCollapsed;

    if (!shouldRender) return null;

    return (

        <Box
            sx={{
                width: isDrawerMode ? '100%' : '20%',
                marginRight: isDrawerMode ? 0 : '10px',
                height: '100%',
            }}
        >
            <Card sx={{
                padding: '20px',
                height: '100%',
                color: '#344767',
                backgroundColor: '#f8f9fa',
                boxShadow: 'none !important',
                border: isDrawerMode ? 'none' : '1px solid rgba(0,0,0,0.12)',
                overflow: 'hidden'
            }}>
                <Box sx={{
                    flexShrink: 0,
                    mb: 1
                }}>
                    <div style={{
                        position: 'relative',
                        height: '30px',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}>
                        <IconButton
                            onClick={handleClearAll}
                            style={{
                                borderRadius: '10%',
                                backgroundColor: '#f0f0f0',
                                fontSize: '0.7rem',
                            }}
                        >
                            Clear All
                        </IconButton>

                        {!isDrawerMode && (
                            <Tooltip title="Close">
                                <IconButton
                                onClick={handleFilterCollapseToggle}
                                style={{
                                    backgroundColor: '#f0f0f0',
                                    fontSize: '0.7rem',
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                            </Tooltip>
                        )}

                        </div>

                    <Box sx={{ marginTop: '10px', display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
                        {selectedSubjects.map((subject, index) => (
                            <Chip
                                key={index}
                                label={subject}
                                onDelete={() => handleCheckboxSubjectChange(subject)}
                                sx={{ margin: '5px 0' }}
                            />
                        ))}

                            {selectedSubjects.map((subject, index) => (
                                <Chip
                                    key={index}
                                    label={subject}
                                    onDelete={() => handleCheckboxSubjectChange(subject)}
                                    sx={{ margin: '5px 0' }}
                                />
                            ))}

                            {selectedLanguages.map((language, index) => (
                                <Chip
                                    key={index}
                                    label={language}
                                    onDelete={() => handleCheckboxLanguageChange(language)}
                                    sx={{ margin: '5px 0' }}
                                />
                            ))}
                        </Box>
                </Box>

                    <Box sx={{
                        flexGrow: 1,
                        overflowY: 'auto',
                        // Add styling for the scrollbar
                        '&::-webkit-scrollbar': {
                            width: '8px',
                        },
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: '#f1f1f1',
                            borderRadius: '4px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: '#c1c1c1',
                            borderRadius: '4px',
                            '&:hover': {
                                backgroundColor: '#a8a8a8',
                            },
                        },
                        scrollbarWidth: 'thin',
                        scrollbarColor: '#c1c1c1 #f1f1f1',
                    }}>
                        <TermsFilter
                            title="Subject Terms"
                            searchTerm={searchSubjectTerm}
                            setSearchTerm={setSearchSubjectTerm}
                            terms={subjects}
                            selectedTerms={selectedSubjects}
                            handleCheckboxChange={handleCheckboxSubjectChange}
                            termCollapsed={subjectCollapsed}
                            handleTermCollapseToggle={handleSubjectCollapseToggle}
                            itemKey="name"
                            placeholder="Search subjects.."

                        />

                        <TermsFilter
                            title="Languages"
                            searchTerm={searchLanguageTerm}
                            setSearchTerm={setSearchLanguageTerm}
                            terms={languages}
                            selectedTerms={selectedLanguages}
                            handleCheckboxChange={handleCheckboxLanguageChange}
                            termCollapsed={languageCollapsed}
                            handleTermCollapseToggle={handleLanguageCollapseToggle}
                            itemKey="language"
                            placeholder="Search languages.."

                        />
                    </Box>
            </Card>
        </Box>
    );
}

FilterList.propTypes = {
    filterCollapsed: PropTypes.bool.isRequired,
    handleFilterCollapseToggle: PropTypes.func.isRequired,
    selectedSubjects: PropTypes.arrayOf(PropTypes.string).isRequired,
    selectedLanguages: PropTypes.arrayOf(PropTypes.string).isRequired,
    handleCheckboxSubjectChange: PropTypes.func.isRequired,
    handleCheckboxLanguageChange: PropTypes.func.isRequired,
    handleClearAll: PropTypes.func.isRequired,
    searchSubjectTerm: PropTypes.string.isRequired,
    setSearchSubjectTerm: PropTypes.func.isRequired,
    searchLanguageTerm: PropTypes.string.isRequired,
    setSearchLanguageTerm: PropTypes.func.isRequired,
    subjects: PropTypes.arrayOf(PropTypes.object).isRequired,
    languages: PropTypes.arrayOf(PropTypes.object).isRequired,
    subjectCollapsed: PropTypes.bool.isRequired,
    handleSubjectCollapseToggle: PropTypes.func.isRequired,
    languageCollapsed: PropTypes.bool.isRequired,
    handleLanguageCollapseToggle: PropTypes.func.isRequired,
    isDrawerMode: PropTypes.bool,
};

export default FilterList;
