import {useSoftUIController} from "../../context";
import { useEffect, useState, useRef } from "react";
import {
    closeForcePageNumber,
    setForcedMinified, setLayout,
    setMiniSidenav,
    setPageNumber,
    updateConfigWidth,
    updateForcePageNumber
} from "../../store/actions/app-actions";

import {getArchivesResultById} from "../../axios-client";
import { useLocation } from "react-router-dom";
import EditorBodyComponent from "./editorBody/EditorBodyComponent";
import SnackbarNotification from "../../reusableComponents/Snackbar/SnackbarNotification";

const DigitizationEditor =  () => {
    const [controller, dispatch] = useSoftUIController();
    const {miniSidenav, pageNumber, forcePageNumber, containerWidth, configWidth} = controller;
    const [mediaPath, setMediaPath] = useState();
    const containerRef = useRef();
    const [numPages, setNumPages] = useState(null);
    const [articleId, setArticleId] = useState(0);
    const [digitizationId, setDigitizationId] = useState(0);
    const [page, setPage] = useState({});
    const [pages, setPages] = useState([]);
    const [isStructured, setIsStructured] = useState(false);
    const [sourceLanguage, setSourceLanguage] = useState('');
    const [targetLanguage, setTargetLanguage] = useState('');
    const [bookmarkingSelected, setBookmarkingSelected] = useState(false);
    const [updatedPages, setUpdatePages] = useState([])

    const location = useLocation();
    const id = new URLSearchParams(location.search).get("id");

    const elementRef = useRef(null);
    const [isFullScreen, setIsFullScreen] = useState(false);

    const [notification, setNotification] = useState({show: false, snackbarMessage: '', severity: ""});

    useEffect(() => {
        setLayout(dispatch, "page");
    }, []);

    const handleFullScreen = () => {
        const element = elementRef.current;
        if (!document.fullscreenElement) {
            element.requestFullscreen().then(() => setIsFullScreen(true)).catch((err) => {
                alert(`Error attempting to enable full-screen mode: ${err.message}`);
            });
        } else {
            document.exitFullscreen().then(() => setIsFullScreen(false));
        }
    };

    const updatePageNumber = (pageNumber) => {
        setPageNumber(dispatch, pageNumber);
    }

    const setForcePageNumber = (forcePageNumber) => {
        updateForcePageNumber(dispatch, forcePageNumber);
    }

    const updateWidth = (newWidth) => {
        updateConfigWidth(dispatch, newWidth);
    }


    useEffect(() => {
        setMiniSidenav(dispatch, true);
        setForcedMinified(dispatch, true);
    }, []);

    useEffect(() => {
        fetchData();
    }, [pageNumber]);

    const handlePageNumber = (maxPageCount) => {
        const params = new URLSearchParams(location.search);
        const page = params.get("page");
        const pageNumber = Number(page);
        if (pageNumber >= 1 && pageNumber <= maxPageCount) {
            return pageNumber;
        } else {
            params.set("page", 1);
            window.history.replaceState({}, '', `${location.pathname}?${params.toString()}`);
            return 1;
        }
    };


    const fetchData = () => {
        getArchivesResultById(id, pageNumber).then(res => {
            const path = res.data.data.article.path;
            const maxPageCount = res.data.data.article.page_count;
            setMediaPath(path)
            let currentPage = res.data.data.article.page
            const existingPage = updatedPages.find(item => item.num === currentPage.num);
            if (existingPage){
                currentPage.vertices = existingPage.vertices
            }
            setPage(currentPage);
            setPages(res.data.data.article.pages);
            setNumPages(maxPageCount);
            setMediaPath(res.data.data.article.page.image_path)
            setArticleId(res.data.data.article.id);
            setIsStructured(res.data.data.is_structured)
            setDigitizationId(res.data.data.id);
            setSourceLanguage(res.data.data.article.source_language);
            setTargetLanguage(res.data.data.target_language);
            setBookmarkingSelected(res.data.data.bookmarking_selected)
            const validPageNumber = handlePageNumber(maxPageCount);
            setForcePageNumber(validPageNumber);

        }).catch(error => {
            console.error("Error fetching data:", error);
        });
    }


    return (
        <div>

            <div className="yai4eduEditorLayout" ref={elementRef} style={{background: '#f8f9fa'}}>

                <EditorBodyComponent
                    mediaPath={mediaPath} numPages={numPages}
                    forcePageNumber={forcePageNumber}
                    pageNumber={pageNumber}
                    setForcePageNumber={setForcePageNumber}
                    containerWidth={containerWidth}
                    configWidth={configWidth}
                    updateConfigWidth={updateWidth}
                    page={page}
                    pages={pages}
                    sourceLanguage={sourceLanguage}
                    targetLanguage={targetLanguage}
                    handleFullScreen={handleFullScreen}
                    isFullScreen={isFullScreen}
                    articleId={articleId}
                    digitizationId={digitizationId}
                    isStructured={isStructured}
                    setNotification={setNotification}
                    setPage={setPage}
                    bookmarkingSelected={bookmarkingSelected}
                    setUpdatePages={setUpdatePages}
                    updatedPages={updatedPages}
                />

            </div>
            <SnackbarNotification
                notification={notification}
                setNotification={setNotification}
            />

        </div>

    );

}
export default DigitizationEditor;