import React from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    TextField,
    Select,
    MenuItem
} from '@mui/material';
import SuiButton from "../../components/SoftButton";
import CircularProgress from '@mui/material/CircularProgress';
import { IoClose } from 'react-icons/io5';
import PropTypes from 'prop-types';

const RefundDialog = ({
                          open,
                          onClose,
                          refundType,
                          setRefundType,
                          refundAmount,
                          setRefundAmount,
                          selectedOrder,
                          description,
                          setDescription,
                          onSubmit,
                          loading
                      }) => {
    return (
        <Dialog open={open} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth PaperProps={{ style: { borderRadius: 15 } }}>
            <DialogTitle
                id="form-dialog-title"
                sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
                Refund Management
                <IoClose
                    aria-label="close"
                    onClick={onClose}
                    style={{ position: 'absolute', right: 8, top: 8, cursor: 'pointer' }}
                />
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ padding: "0px 24px" }}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                    <div style={{ display: "flex" }}>
                        <label htmlFor="refundType" className="label-style" style={{ width: '40%' }}>Refund Type</label>
                        <Select
                            id="refundType"
                            fullWidth
                            value={refundType}
                            onChange={e => setRefundType(e.target.value)}
                            name="refundType"
                            displayEmpty
                        >
                            <MenuItem value="" disabled>Select a refund type</MenuItem>
                            <MenuItem value="full">Full Refund</MenuItem>
                            <MenuItem value="custom">Custom Refund</MenuItem>
                        </Select>
                    </div>

                    <div style={{ display: "flex" }}>
                        <label htmlFor="refundAmount" className="label-style" style={{ width: '40%' }}>Refund Amount (USD)</label>
                        {refundType === "custom" ? (
                            <TextField
                                id="refundAmount"
                                type="number"
                                value={refundAmount}
                                onChange={(e) => {
                                    const value = parseFloat(e.target.value);
                                    if (selectedOrder && value > parseFloat(selectedOrder?.amount)) {
                                        setRefundAmount(parseFloat(selectedOrder.amount));
                                    } else {
                                        setRefundAmount(value);
                                    }
                                }}
                                fullWidth
                                margin="normal"
                                inputProps={{ min: 0, max: selectedOrder ? parseFloat(selectedOrder?.amount) : 0 }}
                                sx={{ marginTop: 0 }}
                            />
                        ) : (
                            <TextField
                                id="refundAmount"
                                type="number"
                                value={parseFloat(selectedOrder?.amount)}
                                fullWidth
                                margin="normal"
                                sx={{ marginTop: 0 }}
                                disabled
                            />
                        )}
                    </div>

                    <div style={{ display: "flex" }}>
                        <label htmlFor="description" className="label-style" style={{ width: '40%' }}>Description</label>
                        <TextField
                            id="description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            fullWidth
                            margin="normal"
                            multiline
                            rows={4}
                            sx={{ marginTop: 0 }}
                        />
                    </div>
                </div>
            </DialogContent>

            <Divider />
            <DialogActions>
                <SuiButton variant="gradient" color="secondary" size="medium" onClick={onClose}>
                    Close
                </SuiButton>
                <SuiButton variant="gradient" color="primary" size="medium" onClick={onSubmit}>
                    Submit
                    {loading && <CircularProgress size={20} style={{ marginLeft: 10, color: "white" }} />}
                </SuiButton>
            </DialogActions>
        </Dialog>
    );
};

RefundDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    refundType: PropTypes.string,
    setRefundType: PropTypes.func,
    refundAmount: PropTypes.string,
    setRefundAmount: PropTypes.func.isRequired,
    selectedOrder: PropTypes.object,
    description: PropTypes.string,
    setDescription: PropTypes.func,
    onSubmit: PropTypes.func,
    loading: PropTypes.bool
};

export default RefundDialog;
