import DigitizationContentComponent from "./editorDigitizationContent/DigitizationContentComponent";
import "../editorStyleSheet.css"
import BodyComponents from "./BodyComponents";
import PropTypes from "prop-types";
import {rerunService, updatePagesDataByArticleId} from "../../../axios-client";
import {useEffect, useState, useCallback, useRef} from "react";
import DigitizationMarkdownComponent from "./editorDigitizationContent/DigitizationMarkdownComponent";


const EditorBodyComponent = ({
    handleFullScreen,
    isFullScreen,
    mediaPath,
    containerWidth,
    forcePageNumber,
    numPages,
    pageNumber,
    setForcePageNumber,
    configWidth,
    updateConfigWidth,
    page,
    pages,
    sourceLanguage,
    targetLanguage,
    articleId,
    digitizationId,
    setNotification,
    setPage,
    bookmarkingSelected,
    setUpdatePages,
    isStructured,
    updatedPages
}) => {
    const [zoom, setZoom] = useState(0.8);

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (updatedPages?.length > 0) {
                const message = "You have unsaved changes, are you sure you want to leave?";
                e.returnValue = message;
                return message;
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [updatedPages]);

    const addOrUpdatePages = (num, data , field) => {

        setUpdatePages(prevTexts => {
            const existingText = prevTexts.find(item => item.num === num);

            if (existingText) {
                return prevTexts.map(item =>
                    item.num === num ? { ...item, [field]: data } : item
                );
            } else {
                return [...prevTexts, { num, [field]: data }];
            }
        });
    };

    const updateAudioPaths = (audioList) => {
        const matchingAudio = audioList.find(audio => audio.num === page.num);
        setPage({...page, audio_path: matchingAudio.audio_path})
    };

    const regenerateBookmarking = () => {
        rerunService(digitizationId, {service_name: 'Bookmarking'})
            .then(() => {
                setNotification({ show: true, snackbarMessage: 'Regenerating Bookmarking in progress', severity: 'success' });
            })
            .catch(e => {
                setNotification({ show: true, snackbarMessage: 'An unexpected error occurred', severity: 'error' });
            })
    }
      
    const updatePages = () => {
        return updatePagesDataByArticleId(articleId, updatedPages)
            .then((res) => {
                if (res.data && res.data.updated_pages) {
                    updateAudioPaths(res.data.updated_pages)
                }
                setUpdatePages([])
                setNotification({ show: true, snackbarMessage: 'Pages updated successfully.', severity: 'success' });
            })
            .catch((error) => {
                setNotification({ show: true, snackbarMessage: 'An unexpected error occurred', severity: 'error' });
            });
    };
    

    return (
        <div className="digitizationEditorBody">
            <div className="body-component">
                <BodyComponents
                    mediaPath={mediaPath}
                    numPages={numPages}
                    pages={pages}
                    forcePageNumber={forcePageNumber}
                    pageNumber={pageNumber}
                    setForcePageNumber={setForcePageNumber}
                    containerWidth={containerWidth}
                    configWidth={configWidth}
                    handleFullScreen={handleFullScreen}
                    isFullScreen={isFullScreen}
                    setNotification={setNotification}
                    articleId={articleId}
                    zoom={zoom}
                    setZoom={setZoom}
                />

                {isStructured ? <DigitizationMarkdownComponent
                    pageNumber={pageNumber}
                    configWidth={configWidth}
                    containerWidth={containerWidth}
                    updateConfigWidth={updateConfigWidth}
                    page={page}
                    digitizationId={digitizationId}
                    sourceLanguage={sourceLanguage}
                    targetLanguage={targetLanguage}
                    addOrUpdatePages={addOrUpdatePages}
                    updatedPages={updatedPages}
                    handleSave={updatePages}
                    regenerateBookmarking={regenerateBookmarking}
                    bookmarkingSelected={bookmarkingSelected}
                /> : <DigitizationContentComponent
                    pageNumber={pageNumber}
                    configWidth={configWidth}
                    containerWidth={containerWidth}
                    updateConfigWidth={updateConfigWidth}
                    page={page}
                    digitizationId={digitizationId}
                    sourceLanguage={sourceLanguage}
                    targetLanguage={targetLanguage}
                    addOrUpdatePages={addOrUpdatePages}
                    updatedPages={updatedPages}
                    handleSave={updatePages}  
                    regenerateBookmarking={regenerateBookmarking}
                    bookmarkingSelected={bookmarkingSelected}
                />

                }
            </div>
        </div>
    )

}


EditorBodyComponent.propTypes = {
    mediaPath: PropTypes.string,
    containerWidth: PropTypes.string,
    configWidth: PropTypes.string,
    numPages: PropTypes.number,
    pageNumber: PropTypes.number,
    articleId: PropTypes.number,
    digitizationId: PropTypes.number,
    forcePageNumber: PropTypes.number,
    setForcePageNumber: PropTypes.func,
    updateConfigWidth: PropTypes.func,
    sourceLanguage: PropTypes.string,
    targetLanguage: PropTypes.string,
    page: PropTypes.object,
    pages: PropTypes.array,
    updatedPages: PropTypes.array,
    setPage: PropTypes.func,
    handleFullScreen: PropTypes.func,
    setNotification: PropTypes.func,
    setUpdatePages: PropTypes.func,
    isFullScreen: PropTypes.bool,
    isStructured: PropTypes.bool,
    bookmarkingSelected: PropTypes.bool
};
export default EditorBodyComponent;