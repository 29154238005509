export const RTL_LANGUAGES = [
    'Arabic',
    'arabic',
    'Divehi',
    'Persian',
    'Hausa',
    'Hebrew',
    'Kashmiri',
    'Kurdish',
    'Pashto',
    'Urdu',
    'Yiddish',
    'Ottoman'
];

export const LIBRARIAN_ROLE = "librarian";
export const LIBRARY_ADMIN_ROLE = "library-admin";
export const SUPER_ADMIN_ROLE = 'super-admin';
export const RESEARCHER_ROLE = 'researcher';
export const FREE_AGENT = 'free-agent';
export const PLATFORM_NAME = 'Yai4edu';

export const MIME_TYPE_TO_EXTENSION = {
    "application/msword": "doc",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "docx",
    "application/octet-stream": "docx",
    "application/pdf": "pdf",
    "image/jpeg": "jpg",
    "image/png": "png",
    "audio/mpeg": "mp3",
    "audio/wav": "wav",
    "text/plain": "txt",
    "application/zip": "zip",
};