import React, {useEffect, useState} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from "@mui/material/CardMedia";
import SoftBox from "../../../components/SoftBox";
import SoftTypography from "../../../components/SoftTypography";
import { CiBookmark } from "react-icons/ci";
import { TbDeviceTabletSearch } from "react-icons/tb";
import { PiMagnifyingGlassPlusFill } from "react-icons/pi";
import { TbTextScan2 } from "react-icons/tb";
import { BsCash } from "react-icons/bs";
import defaultMiniature from "assets/images/curved-images/digitization.png";
import PropTypes from "prop-types";
import SoftButton from "../../../components/SoftButton";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ReactReadMoreReadLess from "react-read-more-read-less";
import { MdDelete, MdEdit, MdRefresh } from "react-icons/md"
import DeleteArticleDialog from "../../../layouts/publications/DeleteArticleDialog";
import {useSoftUIController} from "../../../context";

import Highlighter from "react-highlight-words";
import {io} from 'socket.io-client';
import {LIBRARY_ADMIN_ROLE, SUPER_ADMIN_ROLE, FREE_AGENT} from "../../../utlis/constant";
import { format } from 'date-fns';
import PayPalPaymentButton from "../../payment/PayPalPaymentButton";
import {rerunDigitizer, rerunService} from "../../../axios-client";
import {getStatusIcon} from "../../../utlis/generalUtils";
import StepperStatusComponent from "../../../components/Steper/StepperStatusComponent";
import EditDigitizedArticleDialog from "../../../layouts/publications/EditDigitizedArticleDialog";

const CardItem = ({
                      id,
                      title,
                      image,
                      author,
                      sourceLanguage,
                      targetLanguage,
                      publishedAt,
                      summary,
                      services_data,
                      createdAt,
                      status,
                      origin,
                      handleClickDownloadDigitizedDocument,
                      handleOpenPreview,
                      downloadBookmarkingFile,
                      bookmarkingPath,
                      subjects,
                      setNotification,
                      articleId,
                      cost,
                      searchText,
                      refreshList,
                      paymentStatus,
                      freeAgentDocuments = false,
                      digitizerId
                  }) => {
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [controller, dispatch] = useSoftUIController();

    const updated_services_data = services_data?.map((service) => {
        if (service.name === "upload" && service.status === "pending") {
            return { ...service, status: "in_progress" };
        }
        return service;
    });

    const [stepsData, setStepsData] = useState(updated_services_data);
    const [digitizationFailed, setDigitizationFailed] = useState(status === "ERROR" ? true : false);
    const [rerunDigitization, setRerunDigitization] = useState(false);
    const [isMetadataDialogOpen, setMetadataDialogOpen] = useState(false);

    useEffect(() => {

        if((status == "COMPLETE" && stepsData?.some(service => service.service_status === 'ERROR' || service.service_status === 'IN_PROGRESS')) ||
                (status != "COMPLETE" && status != "ERROR" && status != "PENDING") || (rerunDigitization === true )){
            const baseURL = process.env.REACT_APP_SOCKET_SERVER
            const socket = io(baseURL);
            const eventName = `digitization_status_update-${id}`;
            socket.on(eventName, (res) => {
                setStepsData(res.data)
                if (res.status === 'COMPLETE') {
                    socket.off(`digitization_status_update-${id}`);
                    setRerunDigitization(false)
                    refreshList()
                } else if (res.status === 'ERROR') {
                    setDigitizationFailed(true)
                    setRerunDigitization(false)
                    refreshList()
                }
            });

            return () => {
                socket.off(`digitization_status_update-${id}`);
            };
        }
    }, [rerunDigitization]);

    const handleOpenMetadataDialog = () => {
        setMetadataDialogOpen(true);
    };

    const handleCloseMetadataDialog = () => {
        setMetadataDialogOpen(false);
    };

    const handleRerunDigitization = () => {
        setDigitizationFailed(false)
        setRerunDigitization(true)
        rerunDigitizer(id).then((res) => {
            setNotification({ show: true, snackbarMessage: 'Digitization rerun successfully!', severity: "success" });
        }).catch((error) => {
            setNotification({ show: true, snackbarMessage: 'An error occurred. Please try again!', severity: "error" });
        })
    };

    const handleOpenDeleteDialog = () => {
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    const formattedCreatedAt = createdAt ? format(new Date(createdAt), 'MMM dd, yyyy hh:mm a') : '';

    const openTheEditor = (id) => {
        window.open(`/document?id=${id}`, "_blank");
    }

    const handleRerunService = (serviceName) => {
        rerunService(id, serviceName)
            .then(() => {})
            .catch(e => {
        })
    }

    return (
        <Card sx={{ borderRadius: '0rem', border: '1px'}}>
            <SoftBox sx={{ margin: '5px', display: 'flex', justifyContent: 'center', width: '100%', alignItems: 'center' }}>
                {((status == "COMPLETE" && stepsData.some(service => service.service_status === 'ERROR' || service.service_status === 'IN_PROGRESS')) ||
                        (status != "COMPLETE" && status != "ERROR" && status != "PENDING") || (rerunDigitization === true )) && origin == "Internal" &&
                    <StepperStatusComponent stepsData={stepsData} handleRerunService={handleRerunService}/>}
            </SoftBox>

            <Card sx={{flexDirection: 'row', border: '1px'}}>

                <SoftBox>
                    <CardMedia
                        component="img"
                        sx={{width: 80, height: 100, borderRadius: "0px"}}
                        image={image ?? defaultMiniature}
                        alt="digitized document"
                    />
                    <SoftButton
                        size={"small"}
                        variant="text"
                        style={{marginLeft: "0.5rem", textTransform: "none"}}
                        onClick={() => {
                            handleOpenPreview()
                        }}
                        color={"info"}
                        disabled={status != "COMPLETE"}
                    >
                        <PiMagnifyingGlassPlusFill/>
                        More info
                    </SoftButton>
                </SoftBox>
                <SoftBox sx={{display: 'flex', flexDirection: 'column', minWidth: '40%'}}>
                    <CardContent sx={{flex: '1 0 auto'}}>
                        <SoftTypography component="div" variant="h6">

                            <SoftBox style={{display:"flex", flexDirection:"row", alignItems:"center", gap:"1.5rem"}}>

                                <SoftBox style={{display:"flex", flexDirection:"row", alignItems:"center", gap:"0.4rem", cursor:"pointer"}}>
                                    <Tooltip title="Open editor in a new tab">
                                        <span>
                                              <a
                                                  style={{display: "flex"}}
                                                  target="_blank"
                                                  onClick={() => openTheEditor(id)}
                                              >
                                                  <Highlighter
                                                      searchWords={[searchText]}
                                                      textToHighlight={title}
                                                  />
                                              </a>
                                        </span>
                                    </Tooltip>
                                </SoftBox>

                                {status === "ERROR" && !rerunDigitization &&
                                    getStatusIcon('FAILED')
                                }
                            </SoftBox>
                        </SoftTypography>
                        <SoftTypography component="div" sx={{paddingTop: "10px", paddingLeft: "10px", width: '85%'}}>

                                <SoftTypography component="div" variant="body2" sx={{fontSize: "0.9rem"}}>
                                    <span> <strong>Author: </strong></span>
                                    {
                                        author && <Highlighter
                                            searchWords={[searchText]}
                                            textToHighlight={author}
                                        />
                                    }
                                </SoftTypography>


                                <SoftTypography component="div" variant="body2" sx={{fontSize: "0.9rem"}}>
                                    <span><strong>Published: </strong></span>
                                    {
                                        publishedAt && <Highlighter
                                            searchWords={[searchText]}
                                            textToHighlight={publishedAt}
                                        />
                                    }
                                </SoftTypography>


                                <SoftTypography component="div" variant="body2" sx={{fontSize: "0.9rem"}}>
                                    <span><strong>Summary: </strong></span>
                                    {summary && (
                                        <ReactReadMoreReadLess
                                            charLimit={400}
                                            readMoreText={"Read more ▼"}
                                            readLessText={"Read less ▲"}
                                        >
                                        {summary}
                                    </ReactReadMoreReadLess>
                                    )}
                                </SoftTypography>

                            {subjects && subjects.length > 0 && (
                                <SoftTypography component="div" variant="body2"
                                                sx={{fontSize: "0.7rem", paddingTop: "8px"}}>
                                    {subjects.map((subject, index) => (
                                        <SoftBox
                                            key={index}
                                            bgColor="dark"
                                            color="white"
                                            borderRadius="lg"
                                            sx={{
                                                display: "inline-block",
                                                padding: "5px 10px",
                                                margin: "5px",
                                                fontWeight: "lighter"
                                            }}
                                        >
                                            {subject}
                                        </SoftBox>
                                    ))}
                                </SoftTypography>
                            )}
                        </SoftTypography>
                    </CardContent>
                </SoftBox>
                <SoftBox sx={{position: "absolute", right: 0, marginRight: "10px"}}>
                    {(!freeAgentDocuments && controller.role === SUPER_ADMIN_ROLE ||
                            controller.role === LIBRARY_ADMIN_ROLE) ||
                                controller.profileData.id === digitizerId ? (
                        <Tooltip title="Edit">
                            <IconButton onClick={handleOpenMetadataDialog} color="info">
                                <MdEdit />
                            </IconButton>
                        </Tooltip>
                    ) : null}
                    {(controller.role === SUPER_ADMIN_ROLE || controller.role === LIBRARY_ADMIN_ROLE) && (
                        <Tooltip title="Delete article">
                            <IconButton aria-label="Delete article" color="info" onClick={handleOpenDeleteDialog}>
                                <MdDelete/>
                            </IconButton>
                        </Tooltip>
                    )}
                    { status == "ERROR" && !rerunDigitization && (
                        <Tooltip title="Rerun digitization">
                            <IconButton aria-label="Rerun digitization" color="info" onClick={handleRerunDigitization}>
                                <MdRefresh/>
                            </IconButton>
                        </Tooltip>
                    )}
                    {bookmarkingPath && <Tooltip title="Download Bookmarking" arrow>
                        <IconButton aria-label="Download Bookmarking" disabled={status != "COMPLETE"} color="info"
                                    onClick={downloadBookmarkingFile}>
                            <CiBookmark/>
                        </IconButton>
                    </Tooltip>}
                    <Tooltip title="Download digitized article">
                        <IconButton aria-label="Download digitized article" disabled={status != "COMPLETE"} color="info"
                                    onClick={() => handleClickDownloadDigitizedDocument()}>
                            <TbTextScan2/>
                        </IconButton>
                    </Tooltip>
                </SoftBox>
                {openDeleteDialog && (
                    <DeleteArticleDialog isModalOpen={openDeleteDialog} handleCloseModal={handleCloseDeleteDialog}
                                         articleId={articleId} articleTitle={title} refreshArticles={refreshList}
                                         setNotification={setNotification}/>
                )}
                <SoftBox sx={{
                    position: "absolute",
                    right: 0,
                    marginRight: (controller.role === SUPER_ADMIN_ROLE && status == "ERROR" && !rerunDigitization) ? "220px" : "180px",
                    marginTop: "10px"}}
                >
                    <SoftTypography fontWeight={"light"} style={{fontSize: "0.8rem"}}>{formattedCreatedAt}</SoftTypography>
                </SoftBox>

                {controller.role === FREE_AGENT && <SoftBox sx={{position: "absolute", right: 0, marginRight: "10px", marginTop: "45px"}}>
                    <div>
                        <SoftBox sx={{
                            float: 'right',
                            marginRight: '10px',
                            fontSize: '1rem',
                            fontWeight: 500
                        }}>${parseFloat(cost).toFixed(2)}</SoftBox>
                        <div>
                            {(status == "PENDING" || status == 'FAILED') && <PayPalPaymentButton articleId={articleId} refreshList={refreshList}
                                                                          setNotification={setNotification}
                                                                          cost={parseFloat(cost).toFixed(2)}
                            />}
                        </div>
                    </div>
                </SoftBox>}

                {freeAgentDocuments && controller.role === SUPER_ADMIN_ROLE && <SoftBox sx={{position: "absolute", right: 0, marginRight: "10px", marginTop: "45px"}}>
                    <SoftBox>
                        <SoftBox sx={{
                            float: 'right',
                            marginRight: '10px',
                            fontSize: '1rem',
                            fontWeight: 500
                        }}>${parseFloat(cost).toFixed(2)}

                            {paymentStatus == "COMPLETED" &&
                                <Tooltip title="Payment successful">
                                    <IconButton aria-label="harmful_content" color="success">
                                        <BsCash />
                                    </IconButton>
                                </Tooltip>
                            }
                            {paymentStatus == "FAILED" &&
                                <Tooltip title="Payment error">
                                    <IconButton aria-label="harmful_content" color="error">
                                        <BsCash />
                                    </IconButton>
                                </Tooltip>
                            }
                            {(paymentStatus == null || paymentStatus == "pending") &&
                                <Tooltip title="Payment pending">
                                    <IconButton aria-label="harmful_content" color="default">
                                        <BsCash />
                                    </IconButton>
                                </Tooltip>
                            }
                        </SoftBox>
                    </SoftBox>
                </SoftBox>}
                <EditDigitizedArticleDialog
                    open={isMetadataDialogOpen}
                    onClose={handleCloseMetadataDialog}
                    author={author}
                    title={title}
                    subjects={subjects}
                    publishedAt={publishedAt}
                    sourceLanguage={sourceLanguage}
                    targetLanguage={targetLanguage}
                    digitizationId={id}
                    setNotification={setNotification}
                    refreshList={refreshList}
                />
            </Card>
        </Card>
    );
};

// Typechecking props for the BasicLayout
CardItem.propTypes = {
    id: PropTypes.number,
    title: PropTypes.string,
    summary: PropTypes.string,
    author: PropTypes.string,
    origin: PropTypes.string,
    bookmarkingPath: PropTypes.string,
    sourceLanguage: PropTypes.string,
    targetLanguage: PropTypes.string,
    image: PropTypes.string,
    status: PropTypes.string,
    paymentStatus: PropTypes.string,
    freeAgentDocuments: PropTypes.bool,
    cost: PropTypes.string,
    publishedAt: PropTypes.node,
    handleOpenPreview: PropTypes.func,
    createdAt: PropTypes.string,
    searchText: PropTypes.string,
    handleClickDownloadDigitizedDocument: PropTypes.func,
    downloadBookmarkingFile: PropTypes.func,
    downloadSearchableFile: PropTypes.func,
    subjects: PropTypes.array,
    services_data: PropTypes.array,
    setNotification: PropTypes.func,
    refreshList: PropTypes.func,
    articleId: PropTypes.number,
    digitizerId: PropTypes.string
};
export default CardItem;
