import EditorCanvasComponent from "./editorCanvasComponents/EditorCanvasComponent";
import EditorToolbarComponent from "./editorToolbarComponents/EditorToolbarComponent";
import "../editorStyleSheet.css";
import PropTypes from "prop-types";

const BodyComponents = ({
                            handleFullScreen,
                            isFullScreen,
                            mediaPath,
                            containerWidth,
                            scale,
                            setPageNumber,
                            setNumPages,
                            forcePageNumber,
                            setScale,
                            numPages,
                            pageNumber,
                            setForcePageNumber,
                            configWidth,
                            setCloseForcePageNumber,
                            setNotification,
                            articleId,
                            updatedText,
                            setUpdateText,
                        }) => {

    const bodyWidth = `calc(100% - ${configWidth}px)`;

    return(
        <div className="body-elements" style={{ width: bodyWidth }}>
            <EditorToolbarComponent
                scale={scale}
                setScale={setScale}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                numPages={numPages}
                setNumPages={setNumPages}
                setForcePageNumber={setForcePageNumber}
                setCloseForcePageNumber={setCloseForcePageNumber}
                handleFullScreen={handleFullScreen}
                isFullScreen={isFullScreen}
                setNotification={setNotification}
                articleId={articleId}
                updatedText={updatedText}
                setUpdateText={setUpdateText}
            />
            <EditorCanvasComponent
                mediaPath={mediaPath}   numPages={numPages}
                setNumPages={setNumPages}  forcePageNumber={forcePageNumber}  scale={scale}
                setScale={setScale}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                setForcePageNumber={setForcePageNumber}
                containerWidth={containerWidth}

            />

        </div>

    );


}

BodyComponents.propTypes = {
    mediaPath: PropTypes.string,
    containerWidth: PropTypes.string,
    numPages: PropTypes.number,
    pageNumber: PropTypes.number,
    scale: PropTypes.number,
    articleId: PropTypes.number,
    setPageNumber: PropTypes.func,
    forcePageNumber: PropTypes.number,
    setNumPages: PropTypes.func,
    setScale: PropTypes.func,
    setForcePageNumber: PropTypes.func,
    setCloseForcePageNumber: PropTypes.func,
    configWidth: PropTypes.string,
    handleFullScreen: PropTypes.func,
    setNotification: PropTypes.func,
    isFullScreen: PropTypes.bool,
    updatedText: PropTypes.array,
    setUpdateText: PropTypes.func,
};

export default BodyComponents;