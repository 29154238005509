import { useState, useEffect } from "react"
import { Box, StepLabel, Typography, styled, keyframes, StepConnector, stepConnectorClasses } from "@mui/material"
import { Scanner as ScannerIcon, SettingsEthernet as ProcessingIcon, CheckCircle as CompleteIcon,} from "@mui/icons-material"
import { GrDocumentConfig } from "react-icons/gr";
import { GrDocumentVerified } from "react-icons/gr";
import { BiCloudUpload } from "react-icons/bi";

import PropTypes from "prop-types";

// Define keyframes for animations
const scanAnimation = keyframes`
  0% { transform: translateY(-3px); opacity: 0.7; }
  50% { transform: translateY(3px); opacity: 1; }
  100% { transform: translateY(-3px); opacity: 0.7; }
`

const processAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

const completeAnimation = keyframes`
  0% { transform: scale(0.8); opacity: 0; }
  50% { transform: scale(1.2); opacity: 1; }
  100% { transform: scale(1); opacity: 1; }
`

const pulseAnimation = keyframes`
  0% { box-shadow: 0 0 0 0 rgba(94, 53, 177, 0.4); }
  70% { box-shadow: 0 0 0 10px rgba(94, 53, 177, 0); }
  100% { box-shadow: 0 0 0 0 rgba(94, 53, 177, 0); }
`

// Styled components for animated icons
const AnimatedScannerIcon = styled(BiCloudUpload)(({ theme }) => ({
    color: theme.palette.primary.main,
    animation: `${scanAnimation} 2s infinite ease-in-out`,
}))

const AnimatedProcessingIcon = styled(GrDocumentVerified)(({ theme }) => ({
    color: theme.palette.primary.main,
    animation: `${processAnimation} 1.5s infinite linear`,
}))

const AnimatedCompleteIcon = styled(GrDocumentConfig)(({ theme }) => ({
    color: "inherit",
    animation: `${completeAnimation} 0.5s ease-out forwards`,
}))

// Custom connector for the stepper
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage: `linear-gradient(95deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 50%, ${theme.palette.secondary.light} 100%)`,
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage: `linear-gradient(95deg, ${theme.palette.primary.light} 0%, ${theme.palette.secondary.main} 100%)`,
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor: theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
        borderRadius: 1,
        transition: "all 0.5s ease",
    },
}))

// Custom StepIcon component with elegant styling
const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#f5f5f5",
    zIndex: 1,
    color: theme.palette.text.disabled,
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    transition: "all 0.3s ease",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.1)",
    ...(ownerState.active && {
        background: `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
        boxShadow: "0 6px 12px 0 rgba(0,0,0,.15)",
        color: "#fff",
        animation: `${pulseAnimation} 2s infinite`,
    }),
    ...(ownerState.completed && {
        background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
        color: "#fff",
    }),
}))

// Custom StepIcon component
const ColorlibStepIcon = (props) => {
    const { active, completed, className, icon } = props

    const icons = {
        1: active ? <AnimatedScannerIcon /> : <BiCloudUpload />,
        2: active ? <AnimatedProcessingIcon /> : <GrDocumentVerified />,
        3: active ? <AnimatedCompleteIcon /> : <GrDocumentConfig />,
    }

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(icon)]}
        </ColorlibStepIconRoot>
    )
}

// Step label styling
const StyledStepLabel = styled(StepLabel)(({ theme }) => ({
    "& .MuiStepLabel-label": {
        marginTop: "8px",
        fontWeight: 500,
        fontSize: "0.875rem",
        transition: "all 0.3s ease",
    },
    "& .MuiStepLabel-label.Mui-active": {
        fontWeight: 700,
        color: theme.palette.primary.main,
    },
    "& .MuiStepLabel-label.Mui-completed": {
        fontWeight: 600,
        color: theme.palette.secondary.main,
    },
}))

// Step description component with animation
const StepDescription = styled(Typography)(({ theme, active }) => ({
    color: active ? theme.palette.text.primary : theme.palette.text.secondary,
    fontSize: "0.875rem",
    textAlign: "center",
    maxWidth: "180px",
    margin: "0 auto",
    marginTop: "8px",
    opacity: active ? 1 : 0.7,
    transition: "all 0.3s ease",
    height: active ? "40px" : "0",
    overflow: "hidden",
}))


const WelcomeCardStepper = ({ autoProgress = true, initialStep = 0 }) => {
    const [activeStep, setActiveStep] = useState(initialStep)


    useEffect(() => {
        if (!autoProgress) return

        const timer1 = setTimeout(() => {
            setActiveStep(1)
        }, 2000)

        const timer2 = setTimeout(() => {
            setActiveStep(2)
        }, 4000)

        return () => {
            clearTimeout(timer1)
            clearTimeout(timer2)
        }
    }, [autoProgress])

    const steps = [
        {
            label: "Upload",
            description: "Uploading document",
        },
        {
            label: "Processing",
            description: "Analyzing and digitizing document data",
        },
        {
            label: "Complete",
            description: "Document successfully digitized",
        },
    ]

    return (
        <Box sx={{ width: "100%", maxWidth: "600px", mt: 4, mb: 2, px: { xs: 1, sm: 2, md: 4 }, position: "relative",}}>
            {/* Background gradient effect */}
            <Box sx={{ position: "absolute", top: "-20px", left: "0", right: "0", height: "100px",
                background: "radial-gradient(circle, rgba(94,53,177,0.05) 0%, rgba(255,255,255,0) 70%)", zIndex: 0, borderRadius: "50%",}}/>

            {/* Stepper */}
            <Box sx={{display: "flex", flexDirection: "column", position: "relative", zIndex: 1,}}>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start", position: "relative",}}>
                    {steps.map((step, index) => (
                        <Box key={index}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                flex: 1,
                                position: "relative",
                            }}
                        >
                            <ColorlibStepIconRoot ownerState={{active: activeStep === index, completed: activeStep > index}}>
                                {index === 0 && (activeStep === 0 ? <AnimatedScannerIcon /> : <BiCloudUpload />)}
                                {index === 1 && (activeStep === 1 ? <AnimatedProcessingIcon /> : <GrDocumentVerified />)}
                                {index === 2 && (activeStep === 2 ? <AnimatedCompleteIcon /> : <GrDocumentConfig />)}
                            </ColorlibStepIconRoot>

                            <Typography
                                sx={{mt: 1, fontWeight: activeStep === index ? 700 : activeStep > index ? 600 : 500,
                                    color:
                                        activeStep === index ? "primary.main" : activeStep > index ? "secondary.main" : "text.secondary",
                                    fontSize: "0.875rem",
                                    transition: "all 0.3s ease",
                                }}
                            >
                                {step.label}
                            </Typography>

                            <StepDescription active={activeStep === index}>{step.description}</StepDescription>
                        </Box>
                    ))}

                    {/* Custom connectors */}
                    <Box
                        sx={{
                            position: "absolute",
                            top: 25,
                            left: "25%",
                            width: "50%",
                            zIndex: 0,
                            display: "flex",
                        }}
                    >
                        <Box
                            sx={{
                                height: 3,
                                flex: 1,
                                backgroundColor: activeStep >= 1 ? "linear-gradient(95deg, #7e57c2 0%, #5e35b1 100%)" : "#eaeaf0",
                                borderRadius: 1,
                                transition: "all 0.5s ease",
                                background: activeStep >= 1 ? "linear-gradient(95deg, #7e57c2 0%, #5e35b1 100%)" : "#eaeaf0",
                            }}
                        />
                        <Box
                            sx={{
                                height: 3,
                                flex: 1,
                                backgroundColor: activeStep >= 2 ? "linear-gradient(95deg, #5e35b1 0%, #ec407a 100%)" : "#eaeaf0",
                                borderRadius: 1,
                                transition: "all 0.5s ease",
                                background: activeStep >= 2 ? "linear-gradient(95deg, #5e35b1 0%, #ec407a 100%)" : "#eaeaf0",
                            }}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
WelcomeCardStepper.propTypes = {
    autoProgress: PropTypes.bool,
    initialStep: PropTypes.number,
};

ColorlibStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
    className: PropTypes.string,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default WelcomeCardStepper