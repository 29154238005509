import React, {useEffect, useState} from "react";
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import PropTypes from "prop-types";


const StripePaymentButton = ({setNotification, cost}) => {

    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const baseUrl = window?.appConfig?.REACT_APP_API_GATEWAY || process.env.REACT_APP_API_GATEWAY;
        const returnUrl = baseUrl + `/api/complete-order`

        const result = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: returnUrl
            },
        });

        if (result.error) {
            setNotification({
                show: true,
                snackbarMessage: result.error.message,
                severity: "error",
            });
        } else {
            setNotification({
                show: true,
                snackbarMessage: "Payment successful!",
                severity: "success",
            });
        }
    };


    return (
        <React.Fragment>
        {
                <form onSubmit={handleSubmit}>
                    <PaymentElement />
                    <button style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "12px",
                        margin: "30px 0px 40px",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        cursor: "pointer",
                        width: "100%",
                        background: "blue"
                    }}
                            disabled={!stripe}
                    >
                        <span style={{fontSize: "13px", fontWeight: "bold", color: "white"}}>
                            Pay ${cost}
                        </span>
                    </button>
                </form>
        }
        </React.Fragment>
    )
}

StripePaymentButton.propTypes = {
    setNotification: PropTypes.func.isRequired,
    cost: PropTypes.string.isRequired
};


export default StripePaymentButton;